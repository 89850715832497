import { Link } from 'react-router-dom';
import Parallax from 'react-rellax';

import ButtonGetInvolved from 'components/Button/GetInvolved';
import ButtonSeePacks from 'components/Button/SeePacks';
import Countdown from 'components/Countdown/Countdown';
import Image from 'components/Image';
import Metadata from 'components/Metadata';
import Newsletter from 'components/Newsletter/Newsletter';
import PageContainer from 'components/Page/Container';
import Telegram from 'components/Telegram/Telegram';
import TitleLines from 'components/TitleLines/TitleLines';
import Video from 'components/Video/Video';

import hero1 from './assets/home-hero-bf-1.png';
import hero2 from './assets/home-hero-bf-2.png';
import hero3 from './assets/home-hero-bf-3.png';

import embassador from './assets/bruno-fernandes-embassador.jpg';

import ctaPacks from './assets/cta-packs.jpg';
import ctaCollection from './assets/cta-collection.jpg';

import './home.scss';

const Home = () => (
  <>
    <Metadata
      description="Collect NFTs with the most iconic video moments of your favourite football players."
      title="RealFevr - Everlasting NFT Moments"
    />

    <section className="section--home-hero">
      <PageContainer>
        <div className="row">
          <div className="col-md-6 home-hero--col-1">
            <h1 className="home-hero--title">
              <span className="hero-title--line hero-title--line-1">Explore the world of</span>
              <span className="hero-title--line hero-title--line-2">FOOTBALL</span>
              <span className="hero-title--line hero-title--line-3">EVERLASTING</span>
              <span className="hero-title--line hero-title--line-4">NFT MOMENTS</span>
            </h1>
            <p className="home-hero--desc">
              <strong>Collect all the moments of your favourite players.</strong>
              <br />
              Cristiano Ronaldo, Iker Casillas, Bruno Fernandes and many more.
              All backed by official intellectual property.
            </p>

            <Countdown />
            <ButtonSeePacks />
            <ButtonGetInvolved size="l" />
          </div>
          <div className="col-md-6 home-hero--col-2">
            <div className="home-hero--img-wrapper">
              <Parallax
                alt=""
                as="img"
                className="home-hero--img home-hero--img-1 rellax"
                speed={ -0.75 }
                src={ hero1 }
              />
              <Parallax
                alt=""
                as="img"
                className="home-hero--img home-hero--img-2 rellax"
                speed={ 0.5 }
                src={ hero2 }
              />
              <Parallax
                alt=""
                as="img"
                className="home-hero--img home-hero--img-4 rellax"
                speed={ 1.8 }
                src={ hero3 }
              />
            </div>
          </div>
        </div>
      </PageContainer>
    </section>

    <section className="section--video-how-to">
      <PageContainer>
        <div className="row">
          <div className="col col-md-10 offset-md-1">
            <div className="video--how-to">
              <Video
                height="540"
                src="https://www.youtube.com/embed/cd4tb4tXG5Q"
                width="1020"
              />
            </div>
          </div>
        </div>
      </PageContainer>
    </section>

    <section className="section--embassador">
      <div className="container">
        <div className="row">
          <div className="col-md-5 embassador--col1">
            <div className="embassador--text">
              <h2 className="embassador--title">
                Our Ambassador
                <br />
                <span className="green">Bruno Fernandes</span>
              </h2>
              <p>We’re so excited to announce our new Ambassador Bruno Fernandes!</p>
              <p>
                Bruno is one of the best midfielders in the world, currently playing at the Premier
                League in Manchester United FC.
              </p>
              <p>
                The &quot;Unique&quot; of our 1st NFT drop will be one of Bruno&apos;s best goals,
                an incredible &quot;rocket&quot; that he scored for Sporting CP.
              </p>
            </div>
          </div>
          <div className="col-md-7 embassador--col2">
            <Image
              alt="Bruno Fernandes"
              className="embassador--img"
              src={ embassador }
            />
          </div>
        </div>
      </div>
    </section>

    <section className="section--marketplace-cta">
      <PageContainer>
        <div className="row">
          <div className="col-md-6 marketplace-cta--col marketplace-cta--col-packs">
            <Link to="/packs" className="marketplace-cta--item">
              <div className="marketplace-cta--item-img">
                <Image
                  alt="Open Packs"
                  src={ ctaPacks }
                />
              </div>
              <TitleLines>
                <small>Open</small>
                { ' ' }
                Packs
              </TitleLines>
            </Link>
          </div>
          <div className="col-md-6 marketplace-cta--col marketplace-cta--col-packs">
            <Link to="/marketplace" className="marketplace-cta--item">
              <div className="marketplace-cta--item-img">
                <Image
                  alt="Start Your Collection"
                  src={ ctaCollection }
                />
              </div>
              <TitleLines as="h4">
                <small>Start Your</small>
                { ' ' }
                Collection
              </TitleLines>
            </Link>
          </div>
        </div>
      </PageContainer>
    </section>

    <Telegram />
    <Newsletter />
  </>
);

export default Home;
