import Modal from 'components/Modal/Modal';
import Newsletter from 'components/Newsletter/Newsletter';
import Telegram from 'components/Telegram/Telegram';

const ModalGetInvolved = () => (
  <Modal
    className="modal-window"
    name="get-involved"
  >
    <div className="popup--title">Join the community</div>

    <div className="row">
      <div className="col-md-6">
        <Newsletter />
      </div>
      <div className="col-md-6">
        <Telegram />
      </div>
    </div>
  </Modal>
);

export default ModalGetInvolved;
