import {
  bool, number, shape, string,
} from 'prop-types';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import CollectibleAction from 'components/Collectible/Action';
import CollectibleFromPack from 'components/Collectible/FromPack/FromPack';
import Image from 'components/Image';
import Price from 'components/Price';
import Video from 'components/Video/Video';

import badges from 'components/Collectible/assets/badges';
import images from 'components/Collectible/assets/images';
import newBadge from 'components/Collectible/assets/badge-new.svg';

import localizedDate from 'utils/localizedDate';
import { v1Error } from 'config';

import './ListItem.scss';

const CollectibleListItem = ({
  attributes: {
    gif,
    image,
    listed,
    listed_at: listedAt,
    locked_on_v1: locked,
    marketplace_version: version,
    minted,
    opened_at: openedAt,
    pack_id: packId,
    price,
    rarity,
    uid,
  },
  owned,
  traits,
}) => {
  const imageSrc = image || images[rarity.toLowerCase()];
  const gifSrc = gif || images[rarity.toLowerCase()];
  const isV1Locked = locked && (version === 'v1');

  // New for 1 day
  const now = new Date();
  const listedDate = listedAt && new Date(listedAt);
  const openedDate = openedAt && new Date(openedAt);
  const newDate = (listedDate && listedDate < openedDate) ? listedDate : openedDate;
  const isNew = openedDate && Math.floor((now - newDate) / (1000 * 60 * 60 * 24)) < 1;

  const { pathname } = useLocation();

  // Split name in first and last name
  const [ firstName, ...lastName ] = traits.Name.split(' ');

  return (
    <div className={ classnames('collectible', { 'status--new': isNew }) }>
      <Link
        className="collectible--anchor"
        label="Open collectible"
        to={ {
          pathname: `/collectibles/${uid}`,
          state: { prevPath: pathname },
        } }
      />
      { isNew && (
        <img
          alt="New"
          className="badge--new"
          src={ newBadge }
        />
      ) }
      <div className="collectible--img">
        <Image
          alt="collectible-img"
          className="collectible--img-static"
          src={ imageSrc }
        />
        <Video
          autoPlay
          containerClassName="collectible--video-hover"
          loop
          noAudio
          src={ gifSrc }
        />
      </div>
      <ul className="collectible--details">
        <li className="cd-badge">
          <Image
            alt={ rarity }
            src={ badges[rarity.toLowerCase()] }
          />
        </li>
        <li className="cd-h1">
          { firstName }
          <br />
          { lastName.join(' ') }
        </li>
        <li className="cd-h2">
          <strong>{ traits.Action }</strong>
          { ` · ${localizedDate(traits.Date)}` }
        </li>
        <li className="cd-h3">{ traits['Serial number'] }</li>
        <li className="cd-h4">
          <CollectibleFromPack packId={ packId } />
        </li>

        { listed && (
          <>
            <li className="cd-price--fevr">
              <Price bnb={ price } output="bnb" />
            </li>
            <li className="cd-price--exchange">
              <Price bnb={ price } output="dollar" />
            </li>
          </>
        ) }
        <li className="cd-cta">
          <CollectibleAction
            gif={ gif }
            image={ image }
            isV1={ (version === 'v1') }
            isV1Locked={ isV1Locked }
            listed={ listed }
            minted={ minted }
            openModal
            owned={ owned }
            price={ price }
            title={ traits.Name }
            uid={ uid }
          />
        </li>
        { isV1Locked && owned
        && (
        <li className="cd-warning">
          <small>
            { v1Error }
          </small>
        </li>
        ) }
      </ul>
    </div>
  );
};

CollectibleListItem.propTypes = {
  attributes: shape({
    gif: string,
    image: string,
    listed: bool,
    listed_at: string,
    locked_on_v1: bool,
    marketplace_version: string,
    opened_at: string,
    pack_id: number,
    price: number,
    rarity: string,
    uid: number,
  }).isRequired,
  owned: bool,
  traits: shape({
    Action: string,
    Date: string,
    Name: string,
    'Serial number': string,
  }).isRequired,
};

CollectibleListItem.defaultProps = {
  owned: false,
};

export default CollectibleListItem;
