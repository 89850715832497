import { arrayOf, shape, string } from 'prop-types';

import { isInTransaction } from 'utils/waitFor';

import logo from './assets/logo-white.svg';

import './InTransaction.scss';

const InTransaction = ({ actions, filter, label }) => {
  const inTransaction = (!actions && !filter) || isInTransaction(actions, filter);

  return !!inTransaction && (
    <div className="in-transaction">
      <img
        alt={ label }
        className="in-transaction--image"
        src={ logo }
      />

      { label }
    </div>
  );
};

InTransaction.propTypes = {
  actions: arrayOf(string),
  filter: shape({}),
  label: string,
};

InTransaction.defaultProps = {
  actions: null,
  filter: null,
  label: 'In Progress...',
};

export default InTransaction;
