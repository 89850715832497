import React from 'react';
import { oneOf, string } from 'prop-types';
import classnames from 'classnames';

import Image from 'components/Image';

import horizontal from './assets/horizontal.svg';
import vertical from './assets/vertical.svg';
import symbol from './assets/symbol.svg';
import white from './assets/white.svg';

const logoTypes = {
  horizontal,
  vertical,
  symbol,
  white,
};

const Logo = ({ className, type }) => (
  <Image
    alt="logo"
    className={ classnames('logo', className, {
      [`logo-${type}`]: type,
    }) }
    src={ logoTypes[type] }
  />
);

Logo.propTypes = {
  className: string,
  type: oneOf([ 'horizontal', 'vertical', 'symbol', 'white' ]),
};

Logo.defaultProps = {
  className: '',
  type: 'horizontal',
};

export default Logo;
