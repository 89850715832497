/* Configuration file */

export const maintenance = false;

export const maintenanceToken = '5ca5930cf489cac1741661174566d0c692e5e0eb51f5ae';

export const maintenanceMessage = '';

export const metamaskLink = 'https://metamask.io/download.html';

export const menuLinks = [
  {
    label: 'Packs',
    link: '/packs',
  },
  {
    label: 'Marketplace',
    link: '/marketplace',
  },
  {
    label: 'My Collection',
    link: '/my-collection',
  },
  {
    label: '$Fevr',
    link: '/fevr',
  },
  {
    label: 'Learn',
    submenu: [
      {
        label: 'About Us',
        link: '/about-us',
      },
      {
        label: 'What is the Marketplace?',
        link: '/what-is-the-marketplace',
      },
      {
        label: 'How to collect?',
        link: '/how-to-collect',
      },
      {
        label: 'FAQ',
        link: '/faq',
      },
      {
        label: 'Contacts',
        link: '/contacts',
      },
    ],
  },
];

export const socialLinks = [
  {
    label: 'Telegram',
    link: 'https://t.me/realfevrofficial',
    logo: 'telegram',
  },
  {
    label: 'Twitter',
    link: 'https://twitter.com/realfevr',
    logo: 'twitter',
  },
  {
    label: 'Youtube',
    link: 'https://www.youtube.com/c/realfevrtv',
    logo: 'youtube',
  },
  {
    label: 'Facebook',
    link: 'https://www.facebook.com/RealFevr',
    logo: 'facebook',
  },
  {
    label: 'Instagram',
    link: 'https://www.instagram.com/realfevr',
    logo: 'instagram',
  },
  {
    label: 'Linkedin',
    link: 'https://www.linkedin.com/company/realfevr',
    logo: 'linkedin',
  },
];

export const team = [
  {
    avatar: '/media/team-fa.jpg',
    name: 'Fred Antunes',
    position: 'Dreamshaper (CEO)',
    profile: 'https://www.linkedin.com/in/fredantunes',
  },
  {
    avatar: '/media/team-av.jpg',
    name: 'André Viana',
    position: 'Executive Chairman',
    profile: 'https://www.linkedin.com/in/andreviana1',
  },
  {
    avatar: '/media/team-tb.jpg',
    name: 'Tiago Bem',
    position: 'Chief Technology Officer',
    profile: 'https://www.linkedin.com/in/tiagobem',
  },
  {
    avatar: '/media/team-pf.jpg',
    name: 'Pedro Febrero',
    position: 'Highlord of Blockchain',
    profile: 'https://www.linkedin.com/in/pedrofebrero',
  },
  {
    avatar: '/media/team-jm.jpg',
    name: 'José Miranda',
    position: 'The Architect',
    profile: 'https://www.linkedin.com/in/josecacpmiranda',
  },
  {
    avatar: '/media/team-mc.jpg',
    name: 'Miguel Carmo',
    position: 'Master of Community',
    profile: 'https://www.linkedin.com/in/miguel-carmo-26680129',
  },
  {
    avatar: '/media/team-an.jpg',
    name: 'André Neto',
    position: 'Field Marshal',
    profile: 'https://www.linkedin.com/in/andre-n-macedo',
  },
  {
    avatar: '/media/team-da.jpg',
    name: 'David Annen',
    position: 'Highlord of Connections',
    profile: 'https://www.linkedin.com/in/davidannendacosta',
  },
  {
    avatar: '/media/team-gm.jpg',
    name: 'Guilherme Maia',
    position: 'Master of Law',
    profile: 'https://www.linkedin.com/in/guilhermecamposmaia',
  },
  {
    avatar: '/media/team-avieira.jpg',
    name: 'Ana Vieira',
    position: 'Master of People',
    profile: 'https://www.linkedin.com/in/anacarolinagon%C3%A7alvesvieira',
  },
  {
    avatar: '/media/team-fc.jpg',
    name: 'Fábio Costa',
    position: 'Art Alchemist',
    profile: 'https://www.linkedin.com/in/fabiocostaart',
  },
  {
    avatar: '/media/team-dc.jpg',
    name: 'Delfim Cascais',
    position: 'Marketing Alchemist',
    profile: 'https://www.linkedin.com/in/delfim-cascais',
  },
];

export const advisors = [
  {
    avatar: '/media/advisors-nc.jpg',
    name: 'Nuno Correia',
    position: 'Founder of Utrust',
    profile: 'https://www.linkedin.com/in/nuno-m-correia',
  },
  {
    avatar: '/media/advisors-rt.jpg',
    name: 'Rui Teixeira',
    position: 'CEO at Bepro',
    profile: 'https://www.linkedin.com/in/rui-dos-santos-teixeira',
  },
  {
    avatar: '/media/advisors-sv.jpg',
    name: 'Sree Varma',
    position: 'CEO at iSportconnect',
    profile: 'https://www.linkedin.com/in/sreevarma',
  },
  {
    avatar: '/media/advisors-nl.jpg',
    name: 'Nuno Luz',
    position: 'Expert Cybersecurity Lawyer',
    profile: 'https://www.linkedin.com/in/nunolimadaluz',
  },
  {
    avatar: '/media/advisors-mn.jpg',
    name: 'Mario Nawfal',
    position: 'CEO of NFT Technologies',
    profile: 'https://www.linkedin.com/in/mario-nawfal',
  },
  {
    avatar: '/media/advisors-ka.jpg',
    name: 'Kevin Abdulrahman',
    position: 'Keynote Speaker, Author, Communication Expert and Coach',
    profile: 'https://www.linkedin.com/in/kevinabdulrahman',
  },
  {
    avatar: '/media/advisors-dc.png',
    name: 'Diogo Caldas',
    position: 'CEO at Sportmultimédia',
    profile: 'https://www.linkedin.com/in/diogocaldas',
  },
];

export const faqs = [
  {
    title: 'What is RealFevr?',
    description: '<p>RealFevr is a company based in Lisbon and created in 2015 with the goal of revolutionizing the Fantasy Leagues market. With the Fantasy Leagues concept proven in national and international territory, RealFevr\'s next major goals are now to enter the global market of sports NFTs and the international consolidation.</p><p>Our mission is to provide users with the best fantasy game adventure and to accomplish that goal we have built a state-of-the-art application that runs on top of blockchain technology. RealFevr is releasing a football video NFTs marketplace backed by official intellectual property.</p>',
  },
  {
    title: 'What is blockchain?',
    description: '<p>The blockchain is a decentralized database and infrastructure, run by the users, for the users that requires a cryptocurrency to truly operate without a central party and/or intermediaries. $FEVR is deployed on the Binance Smart Chain (BSC).</p>',
  },
  {
    title: 'What is a token?',
    description: '<p>A token is a cryptocurrency that allows users to use a certain network or product. Tokens can have multiple use-cases; while some work like currencies, others perform the function of utility tokens </p>',
  },
  {
    title: 'What is an NFT?',
    description: '<p>NFT stands for Non-Fungible Token and is a unique digital item. NFTs are managed on a blockchain, which traces the ownership and transaction history of each NFT, providing a unique code ID and metadata that no other token or user can duplicate. They are also impossible to counterfeit and immediately authenticated.</p>',
  },
  {
    title: 'Why does RealFevr use the blockchain and NFTs technology?',
    description: '<p>RealFevr aims at completely disrupting the NFTs ecosystem by combining breakthrough tech, blockchain’s decentralization, with the uniqueness of video collectibles that empower gamers, providing utility to each moment and gamifying it through fantasy token-leagues always backed by official intellectual property.</p>',
  },
  {
    title: 'What is the purpose of $FEVR?',
    description: '<p>Just like Ether is the fuel of the Ethereum network, $FEVR is the fuel of RealFevr’s marketplace. You can use the FEVR tokens to buy packs, trade, borrow and lend video collectibles to other users.</p>',
  },
  {
    title: 'What is a wallet and why do I need one?',
    description: '<p>To fund your RealFevr wallet, you will need to possess a digital currency wallet first. A digital wallet acts like a bank account for your cryptocurrencies. Make sure you do not forget your recovery key. We recommend you use the <a href="https://metamask.io/" target="_blank" rel="noreferrer">MetaMask</a> wallet interface, the most robust and reliable wallet today. You can install it as a plugin on on top of your browser. We recommend using Chrome, Firefox or Brave browsers to access RealFevr’s marketplace.</p>',
  },
  {
    title: 'What wallets can you use to buy and hold $FEVR?',
    description: '<p>We recommend using MetaMask. You can get <a href="https://metamask.io/" target="_blank" rel="noreferrer">MetaMask</a> here.</p>',
  },
  {
    title: 'What is MetaMask and how do I get it?',
    description: '<p><a href="https://metamask.io/" target="_blank" rel="noreferrer">MetaMask</a> is a software cryptocurrency wallet used to interact with the blockchain. It allows users to access their wallet through a browser extension or mobile app, which can then be used to interact with decentralized applications, like the FEVR token, our packs and NFTs. You can get <a href="https://metamask.io/" target="_blank" rel="noreferrer">MetaMask</a> here.</p>',
  },
  {
    title: 'How and where to buy FEVR tokens?',
    description: '<p>You can buy $FEVR in <a href="https://pancakeswap.finance/" target="_blank" rel="noreferrer">PancakeSwap</a> and in <a href="https://www.gate.io/trade/FEVR_USDT" target="_blank">gate.io</a>.</p>',
  },
  {
    title: 'What is DeFi?',
    description: '<p>DeFi stands for decentralized finance, and it represents all protocols that compose the financial layer of blockchain technology. Some examples are stablecoins, pegged to fiat-currencies such as the U.S. dollar; borrow and lending, such as Compound or Aave; finally, derivatives and indexes are also part of the DeFi ecosystem.</p>',
  },
  {
    title: 'What DeFi features will $FEVR have?',
    description: '<p>With $FEVR, besides buying packs, you’ll be able to lock tokens in order to receive packs of video collectibles, as well as borrow and lend video collectibles to other users.</p>',
  },
  {
    title: 'Is $FEVR audited?',
    description: '<p>FEVR was audited by <a href="https://pessimistic.io/" target="_blank" rel="noreferrer">Pessimistic</a> and is being audited by <a href="https://red4sec.com" target="_blank" rel="noreferrer">red4sec</a>.</p>',
  },
  {
    title: 'What are RealFevr video collectibles?',
    description: '<p>RealFevr is joining the NFTs and cryptocurrency space by building everlasting digital football moments that fans can own, cherish and treasure for all eternity - or at least while the internet is around. These collectibles are limited and show a unique moment in the history of soccer.</p>',
  },
  {
    title: 'How and where to buy RealFevr video collectibles?',
    description: '<p>There are two different ways users can get video collectibles, by buying RealFevr packs that include a certain number of digital collectibles, or by buying directly in the marketplace.</p>',
  },
  {
    title: 'What are the RealFevr packs?',
    description: '<p>RealFevr <a href="/packs">packs</a> contain video collectibles. Each pack contains a certain amount of digital collectibles, and the drop rate may vary depending on the cost of the pack. The more expensive, the higher drop rate for rarer digital collectibles.</p>',
  },
  {
    title: 'What is rarity and how does it work?',
    description: '<p>RealFevr digital collectibles have different degrees of rarity: Common, Special, Epic, Legendary and Unique. The rarer the video collectible, the lower the drop rate. Hence, there will always be more Common than Special; more Special than Epic; more Epic than Legendary; and, finally, more Legendary than Unique. To conclude, Unique digital <a href="/packs">collectibles</a> only have one copy (1/1).</p>',
  },
  {
    title: 'How and where to buy RealFevr Packs?',
    description: '<p>Users can buy RealFevr <a href="/packs">packs</a> by using $FEVR. Packs can be purchased at each pack drop. Please follow the announcement dates in our social channels.</p>',
  },
  {
    title: 'Where does the RealFevr community hang out?',
    description: '<p>If you like what we are building, join our Telegram chat room. You can also follow our news on this <a href="https://t.me/realfevrofficial" target="_blank">Telegram</a> channel, <a href="https://twitter.com/realfevr" target="_blank">Twitter</a>, <a href="https://www.facebook.com/RealFevr" target="_blank">Facebook</a>, <a href="https://www.instagram.com/realfevr/" target="_blank">Instagram</a> and <a href="https://www.linkedin.com/company/realfevr" target="_blank">Linkedin.</a></p>',
  },
  {
    title: 'I love this project and I would like to be a part of it. Where can I apply?',
    description: '<p>You can apply via our careers form here or send an email to <a href="mailto:careers@realfevr.com">careers@realfevr.com</a>.</p>',
  },
];

export const partners = [
  {
    label: 'Bepro',
    logo: '/media/partner-logo-bepro.png',
    link: 'https://www.bepro.network/',
  },
  {
    label: 'Liga Portugal',
    logo: '/media/partner-logo-ligaportugal.png',
    link: 'https://www.ligaportugal.pt',
  },
  {
    label: 'Federação Portuguesa de Futebol',
    logo: '/media/partner-logo-fpf.png',
    link: 'https://www.fpf.pt/',
  },
  {
    label: ' Polkastarter',
    logo: '/media/partner-logo-polkastarter.png',
    link: 'https://www.polkastarter.com',
  },
  {
    label: 'Cuatrecasas',
    logo: '/media/partner-logo-cuatrecasas.png',
    link: 'https://www.cuatrecasas.com/',
  },
  {
    label: 'Soccerex',
    logo: '/media/partner-logo-soccerex.png',
    link: 'https://www.soccerex.com/',
  },
  {
    label: 'Shilling',
    logo: '/media/partner-logo-shilling.png',
    link: 'https://www.shilling.vc',
  },
  {
    label: 'Sport Multimedia',
    logo: '/media/partner-logo-sportmultimedia.png',
    link: 'https://www.sportmultimedia.pt/',
  },
  {
    label: 'growth',
    logo: '/media/partner-logo-growth.png',
    link: 'https://wearegrowthhackers.com/',
  },
  {
    label: 'ibc',
    logo: '/media/partner-logo-ibc.png',
    link: 'https://ibcgroup.io',
  },
  {
    label: 'clever',
    logo: '/media/partner-logo-clever.png',
    link: 'https://cleveradvertising.com',
  },
  {
    label: 'Athena',
    logo: '/media/partner-logo-as.png',
    link: 'https://athenagroupofcompanies.com',
  },
  {
    label: 'iSport Connect',
    logo: '/media/partner-logo-isportconnect.png',
    link: 'https://www.isportconnect.com/',
  },
  {
    label: 'bsc',
    logo: '/media/partner-logo-bsc.png',
    link: 'https://www.bsc.news',
  },
  {
    label: 'ir21',
    logo: '/media/partner-logo-ir21.png',
    link: 'https://ir21.capital',
  },
  {
    label: 'safe-launch',
    logo: '/media/partner-logo-safe-launch.png',
    link: 'https://safelaunch.io',
  },
  {
    label: 'Gate.io',
    logo: '/media/partner-logo-gateio.png',
    link: 'https://www.gate.io/',
  },
  {
    label: 'Empower Sports',
    logo: '/media/partner-logo-empower-sports.png',
    link: 'https://empower-sports.com',
  },
];

export const perPageOptionsMarketplace = [
  { label: '24 items', value: 24 },
  { label: '36 items', value: 36 },
  { label: '48 items', value: 48 },
];

export const perPageOptionsMyCollection = [
  { label: '12 items', value: 12 },
  { label: '24 items', value: 24 },
  { label: '36 items', value: 36 },
  { label: '48 items', value: 48 },
];

export const availablePacksThreshold = 0.25;

export const cookiesBanner = {
  body: 'Your privacy is important to us. With your permission, by navigating this site data will be stored in your browser, in order to enhance your browsing experience.',
  options: [
    [ 'essential', 'Essential - Remember some of your incomplete actions, for a smooth experience across sessions.' ],
    [ 'analytics', 'Analytics - Site behavior and interaction, to improve and evolve the platform.' ],
    [ 'marketing', 'Marketing - To track engagement in social campaigns.' ],
  ],
};

export const countdownTimer = {
  target: 'August 12 2021, 14:00:00 UTC',
  title: 'First Edition Drop Sale',
};

export const v1Error = 'Due to the marketplace V1 update, your collectible can’t be purchased until you have canceled the sale and put it back in the marketplace V2.';
