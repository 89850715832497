import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'components/Button/Button';
import PageContainer from 'components/Page/Container';
import Video from 'components/Video/Video';

import useErrors from 'hooks/useErrors';
import usePackVideo from 'hooks/usePackVideo';

import './PackVideo.scss';

const PackVideo = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const videoRef = useRef();

  const { close, show } = usePackVideo();
  const { errors } = useErrors();
  const { src } = show || {};

  useEffect(() => {
    if (src) {
      videoRef.current?.scrollIntoView({
        // behavior: 'smooth',
        block: 'center',
      });
    }
  }, [ src ]);

  return !errors?.length && !!show && (
    <PageContainer className="container--pack-video-open">
      <div className="row" ref={ videoRef }>
        <div className="col">
          <Video
            autoPlay
            containerClassName="pack-open--container"
            id="pack-open--video"
            onEnded={ close }
            src={ src }
            unMute
          />
        </div>
      </div>

      <div className="row row-skip-btn">
        <div className="col col-align-center">
          <Button
            className="pack-open--skip-btn"
            onClick={ close }
            size="s"
            sizeMin={ 150 }
            theme="white-gradient"
          >
            { 'Skip video ' }
            <span className="skip-arrow">›</span>
          </Button>

          { pathname !== '/my-collection' && (
            <Button
              onClick={ () => {
                history.push('/my-collection');
              } }
              size="s"
              theme="none"
            >
              Go To My Collection
            </Button>
          ) }
        </div>
      </div>
    </PageContainer>
  );
};

export default PackVideo;
