import { useContext } from 'react';
import { string } from 'prop-types';

import Button from 'components/Button/Button';
import { FormContext } from 'components/Form/Form';

const FormSubmit = ({ label, ...props }) => {
  const { submitting, submit } = useContext(FormContext);

  return (
    <div className="form--submit--wrapper">
      <Button
        { ...props }
        disabled={ submitting }
        onClick={ submit }
      >
        { label }
      </Button>
      { submitting && <div className="loading--spinner"><span /></div> }
    </div>
  );
};

FormSubmit.propTypes = {
  label: string.isRequired,
};

export default FormSubmit;
