import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getGlobalState } from 'hooks/useBepro';
import useModal from 'hooks/useModal';

const RequiresWallet = () => {
  const { close, open } = useModal('connect-wallet');
  const history = useHistory();

  const onClose = useCallback(() => {
    const bepro = getGlobalState('bepro');
    if (!bepro.connected) {
      history.push('/');
    }
  }, [ history ]);

  useEffect(() => {
    open({ onClose });
    return close;
  }, [ close, onClose, open ]);

  return null;
};

export default RequiresWallet;
