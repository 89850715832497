import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  arrayOf, bool, number, oneOf, shape, string,
} from 'prop-types';
import classnames from 'classnames';

import Button from 'components/Button/Button';
import ButtonGetInvolved from 'components/Button/GetInvolved';
import Image from 'components/Image';
import InTransaction from 'components/InTransaction/InTransaction';
import Price from 'components/Price';

import { availablePacksThreshold } from 'config';

import useBepro, { wrapConnectWallet } from 'hooks/useBepro';
import useConfig from 'hooks/useConfig';
import useGTag from 'hooks/useGTag';
import useModal from 'hooks/useModal';

import hyphencase from 'utils/hyphencase';
import waitFor, { isInTransaction } from 'utils/waitFor';

import imagePacks from '../assets';

import './ListItem.scss';

const PackListItem = ({
  available_packs: availablePacks,
  image,
  open_video_url: openVideoUrl,
  owned,
  pack_type: packType,
  price,
  season,
  series,
  title,
  total_packs: totalPacks,
}) => {
  const { url } = useRouteMatch();
  const { openPacks } = useBepro();
  const follow = useModal('follow-metamask');
  const modal = useModal('pack');
  const { features } = useConfig();
  const { track } = useGTag();

  const slug = hyphencase(packType);

  const available = useMemo(() => (availablePacks >= totalPacks * availablePacksThreshold
    ? `${availablePacks} available`
    : `${availablePacks} of ${totalPacks} available`),
  [ availablePacks, totalPacks ]);

  const openCtaRef = useRef();
  const [ openDropdown, setOpenDropdown ] = useState(false);

  useEffect(() => {
    const handler = ({ target }) => {
      const { current } = openCtaRef;
      if (openDropdown && !current?.contains(target)) {
        setOpenDropdown(false);
      }
    };

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  });

  const doOpen = async amount => {
    const packIds = owned
      .slice(0, amount)
      .map(({ attributes: { uid } }) => uid);

    track('open_packs', { quantity: packIds.length, type: packType });

    follow.open({
      title: owned.length > 1 ? 'Open Packs' : 'Open Pack',
    });

    const { transactionHash } = await openPacks(packIds) || {};
    follow.close();
    waitFor({
      action: 'openPacks',
      openVideoUrl,
      packIds,
      packType,
      transactionHash,
    });
  };

  const onOpen = () => {
    wrapConnectWallet(
      () => {
        if (owned.length === 1) {
          doOpen(1);
        }
        else {
          setOpenDropdown(!openDropdown);
        }
      },
    )();
  };

  const inTransaction = isInTransaction([ 'openPacks' ], { packType });

  return (
    <div className={ classnames('pack', {
      'status--sold_out': !owned && availablePacks <= 0,
    }) }
    >
      <Link
        className="pack--anchor"
        disabled={ !!owned }
        title={ title }
        to={ `${url}/${slug}` }
      >
        <div className="pack--img">
          { !owned && availablePacks <= 0 && (
            <Image
              alt="Sold out"
              className="badge--sold-out"
              src={ imagePacks.soldout }
            />
          ) }
          <div className="pack--img-item">
            <Image
              alt={ packType }
              src={ image || imagePacks[slug].img }
            />
          </div>
          <div className="pack--img-shape">
            <Image
              alt={ packType }
              src={ imagePacks[slug].svg }
            />
          </div>
        </div>
      </Link>
      <ul className="pack--details">
        <li className="cd-badge">
          <Image
            alt={ packType }
            src={ imagePacks[slug].badge }
          />
        </li>
        <li className="cd-h1">
          <Link
            disabled={ !!owned }
            title={ title }
            to={ `${url}/${slug}` }
          >
            { title }
          </Link>
        </li>
        <li className="cd-h2">
          <strong>{ season }</strong>
          { ' · ' }
          { series }
        </li>

        { !owned && availablePacks > 0 && (
          <>
            { features?.package_sales_enabled && (
              <>
                <li className="cd-h3">{ available }</li>
                <li className="cd-price--fevr">
                  <Price dollar={ price } options={ { maximumFractionDigits: 2 } } output="fevr" />
                </li>
                <li className="cd-price--exchange">
                  <Price dollar={ price } output="dollar" />
                </li>
                <li className="cd-cta">
                  <Button
                    onClick={ () => modal.open({ packType: slug }) }
                    size="s"
                    theme="green-gradient"
                  >
                    Buy
                  </Button>
                </li>
              </>
            ) }

            { !!features && !features.package_sales_enabled && (
              <ButtonGetInvolved size="s" />
            ) }
          </>
        ) }

        { owned && (
          <li className="cd-h3">{ `${owned.length} pack${owned.length > 1 ? 's' : ''} to open` }</li>
        ) }

        { features?.packs_opened_enabled && owned && (
          inTransaction
            ? (
              <li className="cd-cta">
                <InTransaction label="Opening..." />
              </li>
            )
            : (
              <li
                className={ classnames('cd-cta', {
                  'has-submenu--container': owned.length > 1,
                }) }
                ref={ openCtaRef }
              >
                <Button
                  onClick={ onOpen }
                  size="s"
                  theme="white-gradient"
                >
                  { owned.length > 1 ? 'Open' : 'Open Pack' }
                </Button>

                { openDropdown && (
                  <div className="has-submenu--list-wrapper">
                    <ul className="has-submenu--list">
                      <li>
                        <button
                          onClick={ () => doOpen(1) }
                          type="button"
                        >
                          1 Pack
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={ () => doOpen(5) }
                          type="button"
                        >
                          5 Packs
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={ () => doOpen(20) }
                          type="button"
                        >
                          20 Packs
                        </button>
                      </li>

                      <li>
                        <button
                          onClick={ () => doOpen(100) }
                          type="button"
                        >
                          100 Packs
                        </button>
                      </li>
                    </ul>
                  </div>
                ) }
              </li>
            )
        ) }
      </ul>
    </div>
  );
};

PackListItem.propTypes = {
  available_packs: number,
  image: string,
  open_video_url: string,
  owned: arrayOf(shape({
    attributes: shape({
      opened: bool,
    }),
  })),
  pack_type: oneOf([ 'basic', 'rare', 'super_rare' ]).isRequired,
  price: number,
  season: string,
  series: string,
  title: string,
  total_packs: number,
};

PackListItem.defaultProps = {
  available_packs: 0,
  image: '',
  open_video_url: '',
  owned: null,
  price: 0,
  season: '',
  series: '',
  title: '',
  total_packs: 0,
};

export default PackListItem;
