import { useEffect, useMemo } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

import useQueryParams, { getQueryParams } from 'hooks/useQueryParams';

import api from 'lib/api';

const initialState = { topSales: null };
const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState(initialState);

export const updateTopSales = async params => {
  const state = getGlobalState('topSales');

  setGlobalState('topSales', {
    loading: true,
    meta: state?.meta || null,
  });

  const query = params || getQueryParams(window.location);
  const topSales = await api.getTopSales(query);
  setGlobalState('topSales', topSales);
};

const useTopSales = () => {
  const [ topSales ] = useGlobalState('topSales');
  const { queryParams } = useQueryParams();

  useEffect(() => {
    updateTopSales(queryParams);
  }, [ queryParams ]);

  return useMemo(() => topSales || {
    meta: {
      'current-page': 1,
      'next-page': 0,
      'prev-page': 1,
      'total-pages': 0,
      'total-count': 0,
    },
  }, [ topSales ]);
};

export default useTopSales;
