import { bool, number, string } from 'prop-types';

import api from 'lib/api';

import Button from 'components/Button/Button';
import IconInfo from 'components/Icon/Info/Info';
import InTransaction from 'components/InTransaction/InTransaction';

import useBepro, { wrapConnectWallet, wrapConfirmPurchase } from 'hooks/useBepro';
import useConfig from 'hooks/useConfig';
import useGTag from 'hooks/useGTag';
import useModal from 'hooks/useModal';

import waitFor, { isInTransaction } from 'utils/waitFor';

const CollectibleAction = ({
  gif,
  openModal,
  image,
  isV1Locked,
  isV1,
  listed,
  minted,
  owned,
  price,
  title,
  uid,
}) => {
  const {
    buy, cancelSale, mint, sell,
  } = useBepro();
  const { features } = useConfig();
  const { track } = useGTag();

  const collectibleModal = useModal('collectible');
  const cancelModal = useModal('cancel-sell-collectible');
  const mintModal = useModal('mint-collectible');
  const sellModal = useModal('sell-collectible');

  const inTransaction = isInTransaction([ 'buy', 'cancel-sale', 'mint', 'sell' ], { uid });
  switch (inTransaction) {
    case 'buy':
      return <InTransaction label="Buying..." />;
    case 'cancel-sale':
      return <InTransaction label="Unlisting..." />;
    case 'mint':
      return <InTransaction label="Minting..." />;
    case 'sell':
      return <InTransaction label="Listing..." />;
    default:
      break;
  }

  if (owned) {
    if (listed) {
      return features?.marketplace_enabled && (
        <Button
          onClick={ () => {
            track('nft_cancel_sale_click', { price, tokenId: uid });

            cancelModal.open({
              gif,
              image,
              onConfirm: async () => {
                track('nft_cancel_sale_confirm', { price, tokenId: uid });

                const { transactionHash } = await cancelSale({ tokenId: uid, isV1 }) || {};
                waitFor({
                  action: 'cancel-sale',
                  gif,
                  image,
                  imageClassName: 'collectible--img collectible--img-smaller',
                  message: 'Your collectible is no longer up for sale in the marketplace.',
                  title: 'Successfully Cancelled Sale',
                  transactionHash,
                  uid,
                });
              },
              price,
              title,
            });
          } }
          size="s"
          sizeMin={ 110 }
          theme={ isV1Locked ? 'outline-red' : 'outline-white' }
        >
          Cancel Sale
        </Button>
      );
    }

    if (minted) {
      return features?.marketplace_enabled && (
        <Button
          onClick={ () => {
            track('nft_sell_click', { tokenId: uid });

            sellModal.open({
              gif,
              image,
              onConfirm: async salePrice => {
                track('nft_sell_confirm', { price: salePrice, tokenId: uid });

                const { transactionHash } = await sell({
                  tokenId: uid,
                  price: salePrice,
                }) || {};
                waitFor({
                  action: 'sell',
                  gif,
                  image,
                  imageClassName: 'collectible--img collectible--img-smaller',
                  message: 'Your collectible is now up for sale in the marketplace.',
                  title: 'Successfully Put Up for Sale',
                  transactionHash,
                  uid,
                });
              },
              title,
            });
          } }
          size="s"
          sizeMin={ 110 }
          theme="green-gradient"
        >
          Sell
        </Button>
      );
    }

    return (
      <>
        <Button
          onClick={ () => {
            track('nft_mint_click', { tokenId: uid });

            mintModal.open({
              onConfirm: async () => {
                track('nft_mint_confirm', { tokenId: uid });

                const { transactionHash } = await mint(uid) || {};
                waitFor({
                  action: 'mint',
                  gif,
                  image,
                  imageClassName: 'collectible--img collectible--img-smaller',
                  message: 'Your NFT is now ready to be put on sale.',
                  title: 'NFT Minted',
                  transactionHash,
                  uid,
                });
              },
            });
          } }
          size="s"
          sizeMin={ 110 }
          theme="purple-gradient"
        >
          Mint
        </Button>
        <IconInfo text="Minting an NFT is how your collectible becomes a part of the blockchain: a public ledger that is unchangeable and tamper-proof." />
      </>
    );
  }

  if (openModal) {
    return (
      <Button
        onClick={
          async () => {
            const result = await api.getCollectible(uid);
            collectibleModal.open({ collectibleId: uid, data: result });
          }
        }
        size="s"
        theme="green-gradient"
      >
        Buy
      </Button>
    );
  }

  return features?.marketplace_enabled && listed && (
    <Button
      className="btn-cta"
      onClick={ () => {
        track('nft_buy_click', { price, tokenId: uid });

        wrapConnectWallet(
          wrapConfirmPurchase(
            async () => {
              track('nft_buy_confirm', { price, tokenId: uid });

              const { transactionHash } = await buy({ price, tokenId: uid, isV1 }) || {};
              if (transactionHash) {
                waitFor({
                  action: 'buy',
                  gif,
                  image,
                  imageClassName: 'collectible--img',
                  message: 'Congrats on your latest acquisition.',
                  title: 'NFT purchased',
                  transactionHash,
                  uid,
                });
              }
            },
            {
              purchase: 'this NFT',
              title: 'NFT',
            },
          ),
        )();
      } }
      size="l"
      theme="green-gradient"
    >
      Buy now
    </Button>
  );
};

CollectibleAction.propTypes = {
  gif: string,
  image: string.isRequired,
  isV1: bool,
  isV1Locked: bool,
  listed: bool,
  minted: bool,
  openModal: bool,
  owned: bool,
  price: number,
  title: string.isRequired,
  uid: number.isRequired,
};

CollectibleAction.defaultProps = {
  gif: '',
  isV1: false,
  isV1Locked: false,
  listed: false,
  minted: false,
  openModal: false,
  owned: false,
  price: null,
};

export default CollectibleAction;
