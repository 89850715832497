import { arrayOf, func, string } from 'prop-types';
import classnames from 'classnames';

const FilterCheckbox = ({
  active, className, onChange, name, value,
}) => {
  const onToggle = option => {
    onChange(
      option.target.checked
        ? Array.from(new Set([ ...active, value ]))
        : active.filter(r => r !== value),
    );
  };

  return (
    <div className={ classnames('form-element-container form-element-container--checkbox', className, {
      'checkbox-is-checked': active.includes(value),
    }) }
    >
      <div className={ classnames('checkbox', {
        checked: active.includes(value),
      }) }
      >
        <input
          type="checkbox"
          id={ name }
          name={ name }
          checked={ active.includes(value) }
          onChange={ onToggle }
        />
      </div>
      <label
        className="lbl-inline"
        htmlFor={ name }
      >
        { value }
      </label>
    </div>
  );
};

FilterCheckbox.propTypes = {
  active: arrayOf(string),
  className: string,
  onChange: func.isRequired,
  name: string.isRequired,
  value: string,
};

FilterCheckbox.defaultProps = {
  active: [],
  className: '',
  value: '',
};

export default FilterCheckbox;
