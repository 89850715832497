import { getCollectible } from 'hooks/useCollectible';
import { updateMarketplace } from 'hooks/useMarketplace';
import { getModal, openModal } from 'hooks/useModal';
import { open as openPackVideo } from 'hooks/usePackVideo';
import { getStorage } from 'hooks/useStorage';
import { updateUserCollectibles } from 'hooks/useUserCollectibles';
import { updateUserPacks } from 'hooks/useUserPacks';

import bepro from 'lib/bepro';

import cookieConsent from 'utils/cookieConsent';

const firstDelay = 2500;
const maxDelay = 60000;

const transactions = new Map();

export const isInTransaction = (actions, filter) => {
  const inTransaction = [ ...transactions.values() ]
    .filter(transaction => (
      (!actions || actions.includes(transaction.data?.action))
      && (!filter || Object.entries(filter).every(([ key, val ]) => transaction.data?.[key] === val))
    ));

  // In case we need to know what's the action, assume there will only be one kind of transaction per item at any time
  return inTransaction?.[0]?.data?.action;
};

const save = () => {
  const consent = cookieConsent.read('essential');
  const storage = getStorage('awaiting', consent);

  storage.set('transactions', [ ...transactions.values() ].map(item => item.data));
};

const stop = transactionHash => {
  if (transactions.has(transactionHash)) {
    const { timeoutId } = transactions.get(transactionHash);
    clearTimeout(timeoutId);
    transactions.delete(transactionHash);
    save();
  }
};

const successful = data => {
  const { transactionHash } = data;
  const { show } = getModal('transaction-successful');
  openModal('transaction-successful', [ ...(show || []), data ]);
  stop(transactionHash);
};

const updateCollectiblesList = () => {
  const { pathname } = window.location;
  if (pathname === '/marketplace') {
    updateMarketplace();
  }
  else if (pathname.startsWith('/my-collection')) {
    updateUserCollectibles();
  }
};

const waitFor = (data, setup) => {
  const { action, transactionHash } = data;

  if (!transactionHash || transactions.has(transactionHash)) {
    return;
  }

  if (!setup) {
    openModal('awaiting', { transactionHash });
  }

  switch (action) {
    case 'buyPacks':
    case 'openPacks':
      updateUserPacks();
      break;
    default:
      getCollectible(data.uid);
      updateCollectiblesList();
      break;
  }

  const check = () => {
    const delay = Math.min(transactions.get(transactionHash)?.delay * 2, maxDelay) || firstDelay;

    transactions.set(transactionHash, {
      data,
      delay,
      timeoutId: setTimeout(async () => {
        switch (action) {
          case 'buyPacks': {
            const { packIds } = data;
            const packs = await updateUserPacks();

            if (packIds.every(
              packId => packs?.find(
                pack => pack?.owned?.find(
                  owned => owned.attributes.uid === Number(packId),
                ),
              ),
            )
            ) {
              successful(data);
              return;
            }
            break;
          }

          case 'openPacks': {
            const { openVideoUrl, packIds } = data;
            const packs = await updateUserPacks();

            if (!packIds.some(
              packId => packs?.find(
                pack => pack?.owned?.find(
                  owned => owned.attributes.uid === Number(packId),
                ),
              ),
            )
            ) {
              openPackVideo({
                src: openVideoUrl,
                transactionHash,
              });
              stop(transactionHash);

              const { pathname } = window.location;
              if (pathname === '/my-collection') {
                await updateUserCollectibles();
              }
              return;
            }
            break;
          }

          case 'mint': {
            const { uid } = data;
            const collectible = await getCollectible(uid);

            if (collectible?.minted) {
              successful(data);
              updateCollectiblesList();
              return;
            }
            break;
          }

          case 'sell': {
            const { uid } = data;
            const collectible = await getCollectible(uid);

            if (collectible?.listed) {
              successful(data);
              updateCollectiblesList();
              return;
            }
            break;
          }

          case 'cancel-sale': {
            const { uid } = data;
            const collectible = await getCollectible(uid);

            if (!collectible?.listed) {
              successful(data);
              updateCollectiblesList();
              return;
            }
            break;
          }

          case 'buy': {
            const { uid } = data;
            const collectible = await getCollectible(uid);
            const address = await bepro.getAddress();

            if (collectible?.wallet_addr === address) {
              successful(data);
              updateCollectiblesList();
              return;
            }
            break;
          }

          default:
            stop(transactionHash);
            return;
        }

        check();
      }, delay),
    });
  };

  check();
  save();
};

const update = consent => {
  // Fetch the saved info first, to ensure stop() doesn't overwrite it
  const storage = getStorage('awaiting', consent);
  const saved = storage.get('transactions');

  // Now stop all checks for the previous session
  [ ...transactions.keys() ].forEach(stop);

  // After that, we can start new checks for the saved session
  saved?.forEach(transaction => waitFor(transaction, true));
};

const consent = cookieConsent.read('essential');
update(consent);
cookieConsent.listen(update);

export default waitFor;
