import { useState } from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

import Button from 'components/Button/Button';
import Image from 'components/Image';

import useConfig from 'hooks/useConfig';
import useGTag from 'hooks/useGTag';

const ButtonBuyTokens = ({ className, ...props }) => {
  const { exchanges } = useConfig();
  const { conversion } = useGTag();

  const [ hovered, setHovered ] = useState(false);

  const asButton = [ 'size', 'theme' ].some(prop => prop in props);

  return exchanges?.length > 0 && (
    <div
      className="has-submenu--container"
      onMouseEnter={ () => setHovered(true) }
      onMouseLeave={ () => setHovered(false) }
    >
      { asButton && (
        <Button
          className={ className }
          title="Buy tokens"
          { ...props }
        >
          Buy $FEVR
        </Button>
      ) }

      { !asButton && (
        <button
          className={ classnames('fevr-token-top-cta', className) }
          title="Buy tokens"
          type="button"
          { ...props }
        >
          Buy $FEVR
        </button>
      ) }

      <div
        className="has-submenu--list-wrapper"
        style={ { display: hovered ? '' : 'none' } }
      >
        <ul className="has-submenu--list">
          { exchanges.map(buy => (
            <li key={ buy.link }>
              <a
                href={ buy.link }
                onClick={ () => conversion('buy_fevr') }
                rel="noopener noreferrer"
                target="_blank"
              >
                { !!buy.logo && (
                <Image
                  alt={ buy.name }
                  animationDuration={ 0.1 }
                  src={ buy.logo }
                />
                ) }
                { buy.name }
              </a>
            </li>
          )) }
        </ul>
      </div>
    </div>
  );
};

ButtonBuyTokens.propTypes = {
  className: string,
};

ButtonBuyTokens.defaultProps = {
  className: '',
};

export default ButtonBuyTokens;
