import {
  arrayOf, number, shape, string,
} from 'prop-types';

import FilterSelect from 'components/Filter/Select';
import PaginationControls from 'components/Pagination/Controls/Controls';

import useQueryParams from 'hooks/useQueryParams';

const Pagination = ({ defaultOption, meta, options }) => {
  const { queryParams: { page, perPage }, set } = useQueryParams();

  return (
    <div className="row">
      <div className="col col-3">
        <div className="form-element-container per_page">
          <label htmlFor="selectbox_per_parge">
            Items per page
          </label>
          <FilterSelect
            id="selectbox_per_page"
            name="per_page"
            onChange={ option => set({
              page: 1,
              per_page: option.value,
            }) }
            options={ options }
            value={
              options?.find(option => option.value === (perPage ? Number(perPage) : defaultOption))
            }
          />
        </div>
      </div>
      <div className="col col-6">
        <PaginationControls
          current={ page ? Number(page) : 1 }
          onClick={ nextPage => set({ page: nextPage }) }
          total={ meta?.['total-pages'] || meta?.total_pages || 1 }
        />
      </div>
    </div>
  );
};

Pagination.propTypes = {
  defaultOption: number,
  meta: shape({
    'total-pages': number,
  }),
  options: arrayOf(shape({
    label: string,
    value: number,
  })).isRequired,
};

Pagination.defaultProps = {
  defaultOption: 24,
  meta: null,
};

export default Pagination;
