import { cloneElement } from 'react';
import {
  node, func, shape, string,
} from 'prop-types';
import { components } from 'react-select';

const FilterTextValueContainer = ({
  children, getValue, ...props
}) => {
  const { countVal } = props.selectProps || {};
  const { length } = getValue();
  const elseValue = length > 0 ? getValue()[0].label : children[0];

  const { ValueContainer } = components;
  const countString = countVal ? `${length} ${countVal}` : `${length} player${length !== 1 ? 's' : ''}`;

  return (
    <ValueContainer { ...props }>
      { length > 1
        ? (
          <div className="count">
            { countString }
          </div>
        )
        : elseValue }
      { cloneElement(children[1]) }
    </ValueContainer>
  );
};

FilterTextValueContainer.propTypes = {
  children: node,
  getValue: func.isRequired,
  selectProps: shape({
    countVal: string,
  }),
};

FilterTextValueContainer.defaultProps = {
  children: null,
  selectProps: null,
};

export default FilterTextValueContainer;
