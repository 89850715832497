import { string } from 'prop-types';

import Button from 'components/Button/Button';

import useGTag from 'hooks/useGTag';
import useModal from 'hooks/useModal';

const ButtonGetInvolved = ({ size }) => {
  const { open } = useModal('get-involved');
  const { conversion } = useGTag();

  return (
    <Button
      className="btn--shadow btn-get-involved"
      onClick={ () => {
        conversion('register_interest');
        open();
      } }
      size={ size }
      theme="blue-white-gradient"
    >
      Join the community
    </Button>
  );
};

ButtonGetInvolved.propTypes = {
  size: string,
};

ButtonGetInvolved.defaultProps = {
  size: '',
};

export default ButtonGetInvolved;
