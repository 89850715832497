import { Link } from 'react-router-dom';

import Logo from 'components/Logo/Logo';
import PageContainer from 'components/Page/Container';
import Wallet from 'components/Wallet/Wallet';

import NavigationItems from './Items';

import './Navigation.scss';

const Navigation = () => (
  <header id="main-header">
    <PageContainer>
      <div className="row">
        <div className="col">
          <h1 className="main-logo">
            <Link key="logo" to="/" title="RealFevr">
              <Logo />
            </Link>
            <span className="beta-badge">BETA</span>
          </h1>
          <nav id="main-nav">
            <ul>
              <NavigationItems />
              <li className="nav-item-li">
                <Wallet />
              </li>
            </ul>
          </nav>
          <button
            className="mobile-menu-btn"
            onClick={ () => document.body.classList.toggle('mobile-nav-is-active') }
            type="button"
          >
            <i className="line" />
          </button>
        </div>
      </div>
    </PageContainer>
  </header>
);

export default Navigation;
