import ButtonBuyTokens from 'components/Button/BuyTokens';
import ButtonFantasy from 'components/Button/Fantasy';
import ButtonLeagues from 'components/Button/Leagues';
import NavigationSocialMedia from 'components/Navigation/SocialMedia';
import PageContainer from 'components/Page/Container';
import Wallet from 'components/Wallet/Wallet';

import NavigationItems from './Items';

import './Navigation.scss';

const NavigationMobile = () => (
  <div id="mobile-nav">
    <PageContainer>
      <div className="row">
        <div className="col">
          <nav>
            <ul>
              <NavigationItems expanded />
              <li>
                <Wallet />
              </li>
              <li>
                <ButtonFantasy />
                <ButtonBuyTokens />
                <ButtonLeagues />
                <NavigationSocialMedia />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </PageContainer>
  </div>
);

export default NavigationMobile;
