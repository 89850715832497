import { useEffect, useMemo } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

import useQueryParams, { getQueryParams } from 'hooks/useQueryParams';

import api from 'lib/api';
import bepro from 'lib/bepro';

const initialState = { marketplace: null };
const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState(initialState);

export const updateMarketplace = async params => {
  const state = getGlobalState('marketplace');

  setGlobalState('marketplace', {
    loading: true,
    meta: state?.meta || null,
  });

  const query = params || getQueryParams(window.location);
  const marketplace = await api.getMarketplace(query);
  const address = await bepro?.getAddress();

  setGlobalState('marketplace', {
    ...marketplace,

    data: marketplace?.data?.map(collectible => ({
      ...collectible,
      owned: address && address === collectible?.attributes?.wallet_addr,
    })),
  });
};

const useMarketplace = () => {
  const [ marketplace ] = useGlobalState('marketplace');
  const { queryParams } = useQueryParams();

  useEffect(() => {
    updateMarketplace(queryParams);
  }, [ queryParams ]);

  return useMemo(() => marketplace || {
    meta: {
      'current-page': 1,
      'next-page': 0,
      'prev-page': 1,
      'total-pages': 0,
      'total-count': 0,
    },
  }, [ marketplace ]);
};

export default useMarketplace;
