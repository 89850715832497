import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({ packVideo: null });

export const open = params => setGlobalState('packVideo', params);

const close = () => setGlobalState('packVideo', null);

const usePackVideo = () => {
  const [ show ] = useGlobalState('packVideo');
  const modalShown = !!show;

  useEffect(() => {
    document.body.classList[modalShown ? 'add' : 'remove']('pg-mycollection--pack-open');
  }, [ modalShown ]);

  return { close, open, show };
};

export default usePackVideo;
