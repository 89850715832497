import { useEffect, useMemo, useState } from 'react';
import {
  arrayOf, number, oneOf, string,
} from 'prop-types';
import parser from 'html-react-parser';
import { Link } from 'react-router-dom';

import Button from 'components/Button/Button';
import ButtonGetInvolved from 'components/Button/GetInvolved';
import FilterSelect from 'components/Filter/Select';
import Image from 'components/Image';
import InTransaction from 'components/InTransaction/InTransaction';
import PageContainer from 'components/Page/Container';
import Price from 'components/Price';
import ShareButton from 'components/ShareButton/ShareButton';

import useBepro, {
  wrapConfirmPurchase,
  wrapConnectWallet,
} from 'hooks/useBepro';
import useConfig, { updateConfig } from 'hooks/useConfig';
import useGTag from 'hooks/useGTag';

// import date from 'utils/date';
import hyphencase from 'utils/hyphencase';
import waitFor from 'utils/waitFor';

import imagePacks from '../assets';
import './Details.scss';

const PackDetails = ({
  available_packs: availablePacks,
  buy_packs_options: buyPacksOptions,
  description,
  // drop_date: dropDate,
  image,
  included,
  pack_type: type,
  packs_collector: packsCollector,
  price,
  season,
  series,
  title,
}) => {
  const slug = hyphencase(type);
  const { buyPacks } = useBepro();
  const { features } = useConfig();
  const { track } = useGTag();

  const [ quantity, setQuantity ] = useState(null);
  const [ displayQuantityError, setDisplayQuantityError ] = useState(false);

  const onBuyNow = quantity
    ? () => {
      track('buy_packs_click', { quantity, type });

      wrapConnectWallet(
        wrapConfirmPurchase(
          async () => {
            track('buy_packs_confirm', { quantity, type });

            const { packIds, transactionHash } = await buyPacks({ quantity, type }) || {};
            waitFor({
              action: 'buyPacks',
              image: image || imagePacks[slug].img,
              imageClassName: 'pack--img',
              message: 'Congrats on your latest acquisition.',
              packIds,
              title: quantity === 1 ? 'Pack purchased' : `${quantity} packs purchased`,
              type,
              transactionHash,
            });

            // Trigger a re-rendering to show InTransaction
            updateConfig();
          },
          {
            purchase: quantity === 1 ? `a ${title} pack` : `${quantity} ${title} packs`,
            title: 'Pack',
          },
        ),
      )();
    }
    : () => setDisplayQuantityError(true);

  // Update packs info every time we open one, to update availability, etc
  useEffect(() => {
    updateConfig();
  }, []);

  const purchaseOptions = useMemo(() => buyPacksOptions.map(value => ({
    label: value === 1 ? '1 pack' : `${value} packs`,
    value,
  }) || []), [ buyPacksOptions ]);

  return (
    <PageContainer className="pack-holder">
      <div className="row">
        <div className="col col-md-5 collectible-holder--img">
          <div className="pack--img">
            <div className="pack--img-item">
              <Image
                alt={ type }
                src={ image || imagePacks[slug].img }
              />
            </div>
            <div className="pack--img-shape">
              <Image
                alt={ type }
                src={ imagePacks[slug].svg }
              />
            </div>
          </div>
        </div>
        <div className="col col-md-7 collectible-holder--details">
          <ul className="pack--details">
            <li className="cd-badge">
              <Image
                alt={ type }
                src={ imagePacks[slug].badge }
              />
            </li>
            <li className="cd-h1">{ title }</li>
            <li className="cd-h2">
              <strong>{ season }</strong>
              { ' · ' }
              { series }
            </li>

            { features?.package_sales_enabled && (
              <li className="cd-h3">
                { availablePacks }
                { ' available' }
              </li>
            ) }

            { /*
            <li className="cd-h3">
              { availablePacks }
              { ' available / Drops ' }
              { date.short(dropDate) }
            </li>
              */ }
            <li className="cd-description">
              { parser(description) }
            </li>
            <li className="cd-return-link">
              <Link
                className="item-detail-link"
                to={ `/packs/${slug}` }
              >
                More Info on the Pack Page ›
              </Link>
            </li>

            { features?.package_sales_enabled && availablePacks > 0 && (
              <>
                <li className="cd-price--lbl">Pack price</li>
                <li className="cd-price--fevr">
                  <Price dollar={ price } options={ { maximumFractionDigits: 2 } } output="fevr" />
                </li>
                <li className="cd-price--exchange">
                  <Price dollar={ price } output="dollar" />
                </li>
                <li className="cd-royalty"><small>Rights Holder Royalty: 2%</small></li>

                <li className="cd-quantity">
                  <div className="form-element-container">
                    <label htmlFor="selectbox_quantity">Quantity</label>
                    <FilterSelect
                      id="selectbox_quantity"
                      name="quantity"
                      onChange={ option => {
                        setQuantity(option.value);
                        setDisplayQuantityError(false);
                      } }
                      options={ purchaseOptions }
                      placeholder="Select quantity"
                      value={ purchaseOptions.find(el => el.value === quantity) ?? { label: 'Select quantity', value: '' } }
                    />
                    { displayQuantityError && <small className="cd-cta-error">Please select the quantity.</small> }
                  </div>
                </li>
              </>
            ) }

            <li className="cd-cta">
              { features?.package_sales_enabled && availablePacks > 0 && (
                <Button
                  className="btn-cta"
                  onClick={ onBuyNow }
                  size="l"
                  theme="green-gradient"
                >
                  Buy now
                </Button>
              ) }

              { !!features && !features.package_sales_enabled && (
                <ButtonGetInvolved size="l" />
              ) }

              <ShareButton />
            </li>

            { !!features?.package_sales_enabled && availablePacks <= 0 && (
              <Image
                alt="Sold out"
                className="badge--sold-out"
                src={ imagePacks.soldout }
              />
            ) }

            <InTransaction
              actions={ [ 'buyPacks' ] }
              filter={ { type } }
              label="Buying..."
            />

            <li className="cd-bottom-specs">
              <ol>
                <li>
                  <strong>Included in the pack:</strong>
                  { ' ' }
                  { included }
                </li>
                { /*
                <li>
                  <strong>Drop date:</strong>
                  { ' ' }
                  { date.short(dropDate) }
                </li>
                  */ }
                <li>
                  <strong>Packs per collector:</strong>
                  { ' ' }
                  { packsCollector }
                </li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
    </PageContainer>
  );
};

PackDetails.propTypes = {
  available_packs: number,
  buy_packs_options: arrayOf(number),
  description: string,
  // drop_date: string,
  image: string,
  included: string,
  pack_type: oneOf([ 'basic', 'rare', 'super_rare' ]).isRequired,
  packs_collector: string,
  price: number,
  season: string,
  series: string,
  title: string,
};

PackDetails.defaultProps = {
  available_packs: 0,
  buy_packs_options: [],
  description: '',
  // drop_date: '',
  image: '',
  included: '',
  packs_collector: '',
  price: 0,
  season: '',
  series: '',
  title: '',
};

export default PackDetails;
