import { number, oneOf, shape } from 'prop-types';

import useConfig from 'hooks/useConfig';

import formatNumber from 'utils/formatNumber';

const Price = ({
  bnb, dollar, fevr, options, output,
}) => {
  const { bnb_in_dollar: bnbRatio } = useConfig();
  const { fevr_in_dollar: fevrRatio } = useConfig();

  const vBnb = Number(bnb || 0);
  const vDollar = Number(dollar || 0);
  const vFevr = Number(fevr || 0);

  if (output === 'bnb') {
    return (
      <>
        { formatNumber(vBnb || (vDollar || vFevr * fevrRatio) / bnbRatio, options) }
        <small>BNB</small>
      </>
    );
  }

  if (output === 'fevr') {
    return (
      <>
        { formatNumber(vFevr || (vDollar || vBnb * bnbRatio) / fevrRatio, options) }
        <small>FEVR</small>
      </>
    );
  }

  const value = formatNumber(vDollar || vBnb * bnbRatio || vFevr * fevrRatio, options || {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return (
    <>
      { `$${value}` }
      { ' ' }
      <small>USD</small>
    </>
  );
};

Price.propTypes = {
  bnb: number,
  dollar: number,
  fevr: number,
  options: shape({}),
  output: oneOf([ 'bnb', 'dollar', 'fevr' ]),
};

Price.defaultProps = {
  bnb: 0,
  dollar: 0,
  fevr: 0,
  options: null,
  output: 'dollar',
};

export default Price;
