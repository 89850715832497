import { Helmet } from 'react-helmet-async';

import useConsent from 'hooks/useConsent';
import useGTag from 'hooks/useGTag';

const GTag = () => {
  const [ consent ] = useConsent('analytics');
  const { awId, tagId } = useGTag();

  const src = `https://www.googletagmanager.com/gtag/js${tagId ? `?id=${tagId.split(',')[0]}` : ''}`;

  return (!!awId || !!tagId) && consent && (
    <Helmet>
      <script async src={ src } />
      <script>
        { 'window.dataLayer = window.dataLayer || [];\
        function gtag(){dataLayer.push(arguments);}\
        gtag(\'js\', new Date());' }
      </script>

      { !!tagId && tagId.split(',').map(id => (
        <script>
          { `gtag('config', '${id}');` }
        </script>
      )) }

      { !!awId && (
        <script>
          { `gtag('config', '${awId}');` }
        </script>
      ) }
    </Helmet>
  );
};

export default GTag;
