const resolveProperty = (obj, property) => new Promise(resolve => {
  if (obj[property]) {
    resolve(obj[property]);
    return;
  }

  Object.defineProperty(obj, property, {
    configurable: true,
    get: () => undefined,
    set: app => {
      delete obj[property];
      obj[property] = app;
      resolve(app);
    },
  });
});

export default resolveProperty;
