import { useMemo } from 'react';
import { func, number } from 'prop-types';
import Pag from 'react-bootstrap/Pagination';

import PaginationItem from 'components/Pagination/Item';

import './Controls.scss';

const PaginationControls = ({ current, onClick, total }) => {
  const items = useMemo(() => {
    const newItems = [];

    if (current > 1) {
      newItems.push(
        <Pag.Prev
          key="prev"
          className="pagination--arrow pagination--arrow-prev"
          onClick={ () => onClick(current - 1) }
        />,
      );
    }

    if (current - 2 >= 1) {
      newItems.push(
        <PaginationItem
          key={ 1 }
          onClick={ onClick }
          page={ 1 }
        />,
      );

      if ((current - 3 > 1 && total < 1000) || (current - 2 > 1 && total >= 1000)) {
        newItems.push(
          <Pag.Ellipsis key="first_ellipsis" className="pagination--gap" />,
        );
      }
    }

    if (current > 3 && total < 1000) {
      newItems.push(
        <PaginationItem
          key={ current - 2 }
          onClick={ onClick }
          page={ current - 2 }
        />,
      );
    }

    if (current > 1) {
      newItems.push(
        <PaginationItem
          key={ current - 1 }
          onClick={ onClick }
          page={ current - 1 }
        />,
      );
    }

    newItems.push(
      <PaginationItem
        key={ current }
        active
        onClick={ onClick }
        page={ current }
      />,
    );

    if (current + 1 < total) {
      newItems.push(
        <PaginationItem
          key={ current + 1 }
          onClick={ onClick }
          page={ current + 1 }
        />,
      );
    }

    if (current + 2 < total && total < 1000) {
      newItems.push(
        <PaginationItem
          key={ current + 2 }
          onClick={ onClick }
          page={ current + 2 }
        />,
      );
    }

    if (current < total) {
      if ((current + 3 < total && total < 1000) || (current + 2 < total && total >= 1000)) {
        newItems.push(
          <Pag.Ellipsis key="last_ellipsis" className="pagination--gap" />,
        );
      }

      newItems.push(
        <PaginationItem
          key={ total }
          onClick={ onClick }
          page={ total }
        />,
      );
    }

    if (current < total) {
      newItems.push(
        <Pag.Next
          key="next"
          className="pagination--arrow pagination--arrow-next"
          onClick={ () => onClick(current + 1) }
        />,
      );
    }

    return newItems;
  }, [ current, onClick, total ]);

  return (
    <div className="pagination-nav">
      <Pag>
        { items }
      </Pag>
    </div>
  );
};

PaginationControls.propTypes = {
  current: number.isRequired,
  onClick: func.isRequired,
  total: number.isRequired,
};

export default PaginationControls;
