import Button from 'components/Button/Button';
import IconMetamask from 'components/Icon/Metamask/Metamask';

import useBepro from 'hooks/useBepro';
import useGTag from 'hooks/useGTag';
import useModal from 'hooks/useModal';

const Wallet = () => {
  const { conversion } = useGTag();
  const { open } = useModal('connect-wallet');
  const {
    connected, login, networkActive, networkWanted,
  } = useBepro();

  const isConnected = connected && networkActive === networkWanted;

  const onClick = () => {
    conversion('connect_wallet');

    if (!isConnected) {
      open();
    }
    else {
      login();
    }
  };

  return (
    <Button
      className="btn-connect-wallet"
      disabled={ isConnected }
      onClick={ onClick }
      size="s"
      theme={ !isConnected ? 'green-gradient' : 'orange-gradient' }
    >
      { !isConnected ? 'Connect Wallet' : 'Connected' }
      { isConnected && <IconMetamask /> }
    </Button>
  );
};

export default Wallet;
