/* eslint-disable max-len, react/no-unescaped-entities */

import Metadata from 'components/Metadata';
import PageContainer from 'components/Page/Container';
import PageTitle from 'components/Page/Title';

import './style.scss';

const Terms = () => (
  <>
    <Metadata
      description="Privacy policy of use."
      title="RealFevr - Privacy policy"
    />
    <PageTitle>Privacy Policy</PageTitle>
    <PageContainer className="container--legal-body">
      <div className="row">
        <div className="col">
          <p>This privacy policy (this “Policy”) outlines how RealFevr collects, maintains, processes, uses, discloses and protects your personal information. Personal information is any information that directly or indirectly identifies you or by which your identity could be deduced. Protecting your privacy is important to us, and our goal is to maintain your trust and confidence when we handle personal information about you.</p>

          <p>
            This Policy will apply to you if you visit our website at
            { ' ' }
            <a href="https://www.realfevr.com/fevr/" target="_blank" rel="noreferrer">https://www.realfevr.com/fevr/</a>
            { ' ' }
            (the “Site”), create an account with us (an “Account”), register for, access or use the RealFevr Marketplace, or when you otherwise do business or make contact with us (the Site, the RealFevr Marketplace, and each of your Accounts are hereinafter collectively referred to as the “Services”.)
          </p>

          <p>The Services are intended for use by persons who are at least the age of majority in the jurisdiction where they reside. By visiting our Site, or registering for, accessing or using any of the Services, you affirm (i) are eighteen (18) years of age or older OR a parent or guardian has provided express consent for you to use the Services, (ii) you have the legal capacity to consent to this Policy, and (iii) you agree to the terms of this Policy. If you do not agree to any part of this Policy, you should not use the Site or any of the Services.</p>

          <p>The RealFevr Marketplace assists users in the storage and control of their NFTs. RealFevr will only initiate transactions on instruction from a user, and only for NFTs rightfully owned by said user. RealFevr retains all liability for transactions not instructed by a rightful user.</p>

          <h3>What information do we collect?</h3>
          <p>RealFevr collects data to enable us to operate the Services effectively and to provide you with the best experience on the Site and with the Services. You provide some of this data to us directly, such as when you create an Account or otherwise register to use the Services, subscribe to a newsletter, respond to a survey, make an enquiry through our Site, contact us for support, or contact us as a prospective user, vendor, supplier, or consultant. We get some of your data by recording how you interact with our Site and the Services by, for example, using technologies like cookies. We also obtain and process data in the context of making the Services available to you.</p>

          <p>You have choices about the data we collect. When you are asked to provide personal data, you may decline. But if you choose not to provide data that is necessary to enable us to make the Services available to you, you may not be able to sign up for an Account or use the Services.</p>

          <p>The data we collect depends on the context of your interactions with RealFevr and the Services and the choices you make (including your privacy settings). The personal information we or our third party partners collect about you may include: email address, name, username, mobile phone, date of birth, proof of identity (e.g., driver’s license, passport, or government issued ID), bank or other payment account information, additional personal information (at the discretion of our compliance team), device information, screen information, browser information, operating system information, IP address, location information, time zone, log information, button clicks, page views, account information, third party wallet information and transaction information, and correspondence.</p>

          <h3>Why do we collect personal information, and what do we do with it?</h3>
          <p>We use the data we collect to operate our business and to make the Services available to you. This includes using the data to improve the Services and to personalize your experiences. We may use this data to comply with applicable law and regulations, such as for example complying with know your customer regulations. We may also use the data to communicate with you to, among other things, inform you about your Account, inform you about your transactions, provide security updates, and give you information about the Services. We may also use the data to manage your email subscriptions, improve the relevance and security of our Site, respond to user enquiries, send you periodic marketing communications about the Services, and improve the relevance of our advertising.</p>

          <h3>Examples of such uses include the following:</h3>
          <ul>
            <li>Establishing your identity through email verification or third-party provider services;</li>
            <li>Facilitating your transactions;</li>
            <li>Facilitating the creation of your Account;</li>
            <li>Protecting you and other users and RealFevr from error and fraud;</li>
            <li>Analyzing your use of the Services to help make the Service better for all users;</li>
            <li>Carrying out RealFevr’s regular business activities related to the Services;</li>
            <li>Communicating with you regarding your Accounts and/or Service(s);</li>
            <li>Complying with legal and regulatory requirements;</li>
            <li>Responding to your inquiries;</li>
            <li>Providing the best possible user experience;</li>
            <li>Improving the Services;</li>
            <li>Providing you with technical support</li>
            <li>Sending periodic emails to provide you with more details about the Services that we think you may want to be aware of;</li>
            <li>Inviting you to participate in contest, promotions, surveys, or other features of the Services;</li>
            <li>Communicating with you, and personalizing your experience with us;</li>
          </ul>

          <h3>How do we collect personal information?</h3>
          <p>We collect personal information directly from you, and we may receive certain personal information about you from third parties and our partners. We collect and update personal information throughout our relationship with you. The relationship begins the first time you visit our Site and continues through termination of your use of the Services. As such, personal information may be collected any time you visit the Site, set up an Account or use the Services, communicate with our customer service team, and any other time you communicate with us.</p>

          <p>Each and every time you provide personal information to us though any means, you consent to the collection, use and disclosure of such information in accordance with this Policy. If you do not agree to the gathering of this information, you should not use Services or visit our Site.</p>

          <h3>Cookies; Pixels; Beacons</h3>
          <p>RealFevr uses cookies - tiny data files placed on your device that contain a unique identifier that identify your browser. Cookies allow us to collect information about you as a user, to improve our platform, store preferences and settings, and help with sign-in. While you can manage cookies in your Account’s preferences setting, if you disable cookies, you may not be able to use or access some or all of the Services.</p>

          <p>Our web pages may contain electronic images known as web beacons (also called single-pixel gifs) that we use to help deliver cookies on our websites and to count users who have visited those websites. We may also include web beacons in our promotional email messages or newsletters to determine whether and when you open and act on them.</p>

          <p>In addition to placing web beacons on our own websites, we sometimes work with other companies to place our web beacons on their websites or in their advertisements. This helps us to develop statistics on how often clicking on an advertisement on a RealFevr website results in a purchase or other action on the advertiser's website.</p>

          <p>Finally, the Services may contain web beacons or similar technologies from third-party analytics providers (like, for example, Google Analytics) that help us compile aggregated statistics about the effectiveness of our promotional campaigns or other operations. These technologies enable the analytics providers to set or read their own cookies or other identifiers on your device, through which they can collect information about your online activities across applications, websites or other products.</p>

          <h3>Consent</h3>
          <p>Consent for the collection, use and disclosure of personal information may be expressly given or implied. Your express consent, if required, may be given in writing, verbally or through electronic means. Implied consent is given by your actions, such as instances when you visit our Site or and as otherwise described herein.</p>

          <p>RealFevr can collect, use or disclose your personal information without your consent for legal, security or certain processing reasons as required by law to deter fraud and money-laundering, or for other legal purposes.</p>

          <p>
            Your consent can be withdrawn by contacting
            { ' ' }
            <a href="mailto:support@realfevr.com">support@realfevr.com</a>
            . If you do not notify us of the withdrawal of your consent, we will consider your consent to be ongoing. Should you withdraw consent, we may not be able to provide all or some of the Services to you and you are not permitted to access our Site.
          </p>

          <h3>How to Access and Control Your Personal Data</h3>
          <p>You can view, access, edit, delete, or request a copy of your personal data for many aspects of the Services via your Account settings. Within your user settings you can also make choices about RealFevr-s collection and use of your data.</p>

          <p>
            You can always choose whether you want to receive marketing communications from us. You can opt out from receiving marketing communications from us by using the opt-out link on the communication, or by visiting your Account settings or sending us an email at
            { ' ' }
            <a href="mailto:support@realfevr.com">support@realfevr.com</a>
            .
          </p>

          <h3>Data Access</h3>
          <h4 style={ { marginTop: -20, marginBottom: 20 } }><span className="green">You can access your personal data in your Account settings.</span></h4>
          <h4>Data Portability</h4>
          <p>
            You can request a copy of your personal data by submitting an email to us at
            { ' ' }
            <a href="mailto:support@realfevr.com">support@realfevr.com</a>
            { ' ' }
            and including “Please send me a copy of my personal data” in the “Subject” line. RealFevr will verify your ability to access that email, then send you a digital export of the data we hold that is associated with your email address. We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request. RealFevr may be limited in its ability to send certain personal data to you.
          </p>

          <h4>Data Erasure</h4>
          <p>
            You can request that portions of your personal data be erased by submitting an email to us at
            { ' ' }
            <a href="mailto:support@realfevr.com">support@realfevr.com</a>
            { ' ' }
            and including “Please delete my personal data” in the “Subject” line. RealFevr will verify your ability to access that email, then use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request. Please be aware that we require certain information about you in order to make the Services available to you, and we are required to maintain certain information as required by applicable law and regulation. This means that if you want to delete any of these critical pieces of personal data, we may not be able to delete the data right away, but only after the minimum retention period mandated by law. As a response to your request for data erasure, you may be required to delete your entire Account and no longer be able to access the Services.
          </p>

          <h4>Data Correction</h4>
          <p>You can modify your personal data in your Account settings. Note that some of the data we collect is specific to you, and you may not be able to modify this data without needing to create a new user profile.</p>

          <h4>Your Communications Preferences</h4>
          <p>
            You can choose whether you wish to receive marketing communications from us. If you receive marketing communications from us and would like to opt out, you can do so by following the directions in that communication. You can also make choices about your receipt of marketing communications by signing into your Account, and viewing and managing your communication permissions in your Account settings, where you can update contact information, manage your contact preferences, opt out of email subscriptions, and choose whether to share your contact information with RealFevr and our partners. Alternatively, you can request that we withdraw your consent to use your personal data by submitting an email to us at
            { ' ' }
            <a href="mailto:support@realfevr.com">support@realfevr.com</a>
            { ' ' }
            and including “Please withdraw my consent for marketing communications” in the “Subject” line. RealFevr will verify your ability to access that email, then update our systems to remove your email address from the system we use to send marketing communications. We will use reasonable efforts to respond to your request within 14 days, but in all events within 30 days of our receipt of the request. Please note that these choices do not apply to mandatory communications that are part of the Services or to surveys or other informational communications that have their own unsubscribe method.
          </p>

          <h3>Data Retention</h3>
          <p>We may retain your personal information as long as you continue to use the Services, have an Account with us, as long as necessary to comply with applicable law or regulation or for as long as is necessary to fulfill the purposes outlined in this Policy. You can ask to close your Account by contacting us as described above, and we will delete your personal information on request and as governed by the data erasure section of this policy.</p>

          <p>We may, however, retain personal information for an additional period as is permitted or required under applicable laws, for legal, tax, or regulatory reasons, or for legitimate and lawful business purposes.</p>

          <p>We will retain your personal data for as long as necessary to make the Services available to you, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for different types of data, actual retention periods can vary significantly.</p>

          <h3>How we share information and third-party servicers</h3>
          <p>We do not sell or trade your personal information to third parties. We share your personal data with your consent, or as necessary to make the Services available to you. We also share your data with vendors working on our behalf; when required by law or to respond to legal process; when responding to audits; to protect our customers; to protect lives; to maintain the security and integrity of the Services; and to protect our rights or our property.</p>

          <p>Companies we have hired to provide cloud hosting services, off-site backups, know your customer verifications, payment processing, fiat and stablecoin hosted wallet provider and customer support may need access to personal data to provide those functions. In such cases, these companies are required to abide by our data privacy and security requirements and are not allowed to use personal data they receive from us for any other purpose. If you would like to consult a list of our current vendors, or have questions or concerns about the vendors, feel free to contact us at support@realfevr.com.</p>

          <p>We may disclose your personal data as part of a corporate transaction such as a corporate sale, merger, reorganization, dissolution, or similar event.</p>
          <br />
          <p>Finally, we will access, transfer, disclose, and/or preserve personal data, when we have a good faith belief that doing so is necessary to:</p>
          <ol>
            <li>Comply with applicable law or respond to valid legal process, judicial orders, or subpoenas;</li>
            <li>Respond to requests from public or governmental authorities, including for national security or law enforcement purposes;</li>
            <li>Protect the vital interests of our users, customers, or other third parties (including, for example, to prevent spam or attempts to defraud users of our products, or to help </li>
            <li>Prevent the loss of life or serious injury of anyone);</li>
            <li>Operate and maintain the security or integrity of the Services, including to prevent or stop an attack on our computer systems or networks;</li>
            <li>Protect the rights, interests or property of RealFevr or third-parties;</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Services; or</li>
            <li>Enforce our Terms of Use.</li>
          </ol>
          <br />
          <p>We may use and share aggregated non-personal information with third parties for marketing, advertising, and analytics purposes.</p>

          <h3>Where we Store and Process Personal Data; International Transfers</h3>
          Personal data collected by RealFevr may be stored and processed in Portugal or in any other country where RealFevr or its affiliates, subsidiaries or service providers maintain facilities. The storage location(s) are chosen in order to operate efficiently, to improve performance, and to create redundancies in order to protect the data in the event of an outage or other problem. We take steps to ensure that the data we collect is processed according to the provisions of this Policy, and the requirements of applicable law wherever the data is located.

          We transfer personal data from the European Economic Area and Switzerland to other countries, some of which have not been determined by the European Commission to have an adequate level of data protection. When we engage in such transfers, we use a variety of legal mechanisms, including contracts, to help ensure your rights and protections travel with your data. To learn more about the European Commission’s decisions on the adequacy of the protection of personal data in the countries where RealFevr processes personal data, please visit:
          { ' ' }
          <a href="https://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm" target="_blank" rel="noreferrer">https://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm</a>

          <h3>Protection and Security of Information</h3>
          <p>We make reasonable efforts to protect your personal information using appropriate physical, technological and organizational safeguards. No security is foolproof, and the Internet is an insecure medium. However, we work hard to protect you from unauthorized access, alteration, disclosure and destruction of your personal information collected and stored by us. As such, we have policies, procedures, guidelines and safeguards in place to ensure your personal information is protected. Only authorized employees of RealFevr have access to your information, and these employees are required to keep the information confidential. Additionally, we periodically review our practices related to collection, storage and use of personal information and how such practices are utilized by our employees, contractors and agents to ensure high levels of protection.</p>

          <p>Be aware that non-fungible tokens, virtual currencies, and digital assets are not necessarily truly anonymous. Generally, anyone can see the balance and transaction history of any public digital asset address. It may be possible to match your public digital asset address to other personal information about you and to identify you from a blockchain transaction. This is because, in some circumstances, personal information published on a blockchain (such as your digital asset address and IP address) can be correlated with personal information that we and others may have. This may be the case even if we, or they, were not involved in the blockchain transaction itself. Furthermore, by using data analysis techniques on a given blockchain, it may be possible to identify other personal information about you. You understand that we have no control over whether third parties utilize such techniques to identify you or obtain your personal information, and you agree that we will not be liable to you for any such activities. As part of our security, anti-fraud and/or identity verification and authentication checks, we may conduct such analysis to collect and process such personal information about you. You agree to allow us to perform such practices and understand that we do so.</p>

          <h3>How do we Ensure that our Processing Systems remain Confidential, Resilient, and Available?</h3>
          <p>We implement a variety of measures to ensure that our processing systems remain confidential, resilient, and available. Specifically, we have implemented processes to help ensure high availability, business continuity, and prompt disaster recovery. We commit to using third party services that maintain strong physical and logical access controls.</p>

          <h4>High Availability</h4>
          <p>Every part of the Services utilizes properly-provisioned, redundant servers (e.g., multiple load balancers, web servers, replica databases) in case of failure. The third parties we use take servers out of operation as part of regular maintenance, without impacting availability.</p>

          <h4>Business Continuity</h4>
          <p>We keep encrypted backups of data daily in multiple regions on Google Cloud Platform. In the rare case of production data loss (i.e., primary data stores loss), we will restore organizational data from these backups.</p>

          <h4>Disaster Recovery</h4>
          <p>In the event of a region-wide outage, we will bring up a duplicate environment in a different Google Cloud Platform region. Our operations team has extensive experience performing full region migrations.</p>

          <h4>Physical Access Controls</h4>
          <p>The Services are hosted on Google Cloud Platform (“GCP”). These data centers feature a layered security model, including extensive safeguards such as custom-designed electronic access cards, alarms, vehicle access barriers, perimeter fencing, metal detectors, and biometrics. RealFevr employees do not have physical access to these data centers, servers, network equipment, or storage.</p>

          <h4>Logical Access Controls</h4>
          <p>RealFevr is the assigned administrator of its infrastructure on GCP, and only designated authorized RealFevr operations team members have access to configure the infrastructure on an as-needed basis.</p>

          <h4>Penetration Testing</h4>
          <p>We engage an independent, third-party agency to perform black box penetration testing on an annual basis. Information about security vulnerabilities that are successfully exploited through penetration testing is then used to set mitigation and remediation priorities.</p>

          <h3>Third Party Links</h3>
          <p>RealFevr’s Site and the other elements of the Services may contain links to other sites. The owners of the linked sites are solely responsible for their privacy practices and content. RealFevr is not responsible and does not endorse or control the content and privacy practices of third-party websites. Anytime you access a third-party website, you will be subject to the terms of their applicable privacy policies and should review them carefully.</p>

          <h3>Accessing and Keeping your Personal Information Accurate</h3>
          <p>
            We take reasonable and practical steps to ensure that your personal information is accurate with regards to the purpose for which it is used. It is your responsibility to provide accurate personal information about yourself. If any information that has been provided is no longer correct, it is your responsibility to contact us to update this information. Corrections can be made through your Account or by contacting us at
            { ' ' }
            <a href="mailto:support@realfevr.com">support@realfevr.com</a>
            .
          </p>
          <p>
            Subject to certain exceptions under applicable law, you may request access to the personal information that we hold about you in order to view, verify and correct such personal information by contacting us at
            { ' ' }
            <a href="mailto:support@realfevr.com">support@realfevr.com</a>
            . When handing such requests, we will verify the identity of the requesting party through the same or similar methods that we use to verify a user’s email address upon Account creation to ensure that they are the person legally entitled to make the request. It is our policy to not charge for such requests, however, in the case that the requests become repetitive or unduly onerous, or there is request for copies of documents, a charge may apply. We will let you know at the time of the request if there will be a charge. Normally, such requests will be responded to within 30 days.
          </p>

          <h3>Changes to this Policy</h3>
          <p>RealFevr reserves the right to amend this Policy from time to time. The most up to date version will be available on our Site with the most recent version’s date listed clearly at the top of the page. Changes are effective at the time we post them on the Site. While Realfevr may make reasonable attempts to notify active Account and Service holders when this Policy is updated, we nonetheless recommend that you consult this Policy each time you visit our Site or use any of the Services to be assured that you are familiar with the most current version of this Policy.</p>

          <h3>Questions, Concerns and Complaints</h3>
          <p>
            If you have questions, concerns or complaints regarding this Policy, the handling of your personal information or RealFevr’s privacy or data protection practices, including but not limited to the use of any service providers outside of Portugal, please contact
            { ' ' }
            <a href="mailto:support@realfevr.com">support@realfevr.com</a>
            . We investigate all complaints and will generally respond within 30 days of receipt of your complaint. If we find a complaint to be justified, we will take all appropriate measures, including, as necessary, amending our policies and practices.
          </p>

          <p>Please note that when you send us a message, you will be providing us with personal information, including your email address, name, and any other information provided in the message. In some cases, additional personal information may be needed in order to answer your questions, concerns or requests. Such information will be handled in accordance with this Policy.</p>

        </div>
      </div>
    </PageContainer>
  </>
);

export default Terms;
