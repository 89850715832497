import { createGlobalState } from 'react-hooks-global-state';

import api from 'lib/api';

const initialState = { config: null };
const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState(initialState);

// We should hardly refetch anything, but let's allow other components to do it if they need to
export const updateConfig = async () => {
  const config = await api.getConfig();
  setGlobalState('config', config);
};

export const getConfig = () => getGlobalState('config');

// First fetch of config
updateConfig();

const useConfig = () => {
  const [ config ] = useGlobalState('config');

  return config || {};
};

export default useConfig;
