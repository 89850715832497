import { arrayOf, shape, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import PageContainer from 'components/Page/Container';

import './Tabs.scss';

const Tabs = ({ tabs }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className="tabs-nav">
      <PageContainer>
        <div className="row">
          <div className="col">
            <nav>
              <ul>
                { tabs.map(({ label, path }) => (
                  <li
                    key={ path }
                    className={ classnames({
                      'active-tab': path === location.pathname,
                    }) }
                  >
                    <button
                      onClick={ () => history.push(path) }
                      type="button"
                    >
                      { label }
                    </button>
                  </li>
                )) }
              </ul>
            </nav>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

Tabs.propTypes = {
  tabs: arrayOf(shape({
    label: string,
    path: string,
  })).isRequired,
};

export default Tabs;
