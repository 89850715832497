import { useEffect, useState } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

import api from 'lib/api';

const { setGlobalState, useGlobalState } = createGlobalState({ lastCollectible: null });

export const getCollectible = async collectibleId => {
  const result = await api.getCollectible(collectibleId);
  setGlobalState('lastCollectible', result);
  return result;
};

const useCollectible = collectibleId => {
  const [ collectible, setCollectible ] = useState(null);
  const [ lastCollectible ] = useGlobalState('lastCollectible');

  useEffect(() => {
    if (lastCollectible?.uid === Number(collectibleId)) {
      setCollectible(lastCollectible);
    }
  }, [ collectibleId, lastCollectible ]);

  useEffect(() => {
    getCollectible(collectibleId);
  }, [ collectibleId ]);

  return { collectible };
};

export default useCollectible;
