import { Helmet, HelmetProvider } from 'react-helmet-async';

import FormContact from 'components/Form/Contact';
import PageTitle from 'components/Page/Title';
import PageContainer from 'components/Page/Container';

import iconBubble from './assets/contacts-icon-bubble.svg';
import iconPin from './assets/contacts-icon-pinpoint.svg';

import './style.scss';

const Contacts = () => (
  <HelmetProvider>
    <Helmet>
      <title>RealFevr - Contacts</title>
      <meta name="description" content="Send us a message as we love to hear your feedback and improvement suggestions." />
    </Helmet>
    <PageTitle>Contacts</PageTitle>
    <PageContainer>
      <div className="row justify-content-start">
        <div className="col-md-4">
          <img
            src={ iconPin }
            alt="Headquarters Location"
            className="contacts--icon contacts--icon-location"
          />
          <h3 className="mid-title green">
            Headquarters
            <br />
            Location
          </h3>
          <div className="contacts--adress">
            <strong>REALFEVR</strong>
            <br />
            Alameda Armindo de Carvalho, 5A
            <br />
            2730-183 Barcarena
            <br />
            Portugal
          </div>
        </div>
        <div className="col-md-8">
          <img
            src={ iconBubble }
            alt="Headquarters Location"
            className="contacts--icon contacts--icon-bubble"
          />
          <h3 className="mid-title green">
            Questions?
            <br />
            Send us a message!
          </h3>
          <p>
            We love to hear your feedback as we are always working to improve and to offer the best
            possible experience.
          </p>
          <div className="contacts--form-contaniner">
            <FormContact />
          </div>
        </div>
      </div>
    </PageContainer>
  </HelmetProvider>
);

export default Contacts;
