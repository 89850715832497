import { number } from 'prop-types';

const getColor = packId => {
  if (packId < 56402) {
    return 'rgb(73, 185, 129)'; // common
  }

  if (packId < 71404) {
    return 'rgb(137, 180, 237)'; // rare
  }

  return 'rgb(225, 61, 140)'; // super rare
};

const CollectibleFromPack = ({ packId }) => (
  <span style={ { color: getColor(packId) } }>
    { `Pack #${packId}` }
  </span>
);

CollectibleFromPack.propTypes = {
  packId: number.isRequired,
};

export default CollectibleFromPack;
