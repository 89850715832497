import {
  bool, number, shape, string,
} from 'prop-types';
import { Link } from 'react-router-dom';
import date from 'utils/date';

import badges from 'components/Collectible/assets/badges';
import images from 'components/Collectible/assets/images';

import ButtonViewTransaction from 'components/Button/ViewTransaction';
import Image from 'components/Image';
import Price from 'components/Price';

import localizedDate from 'utils/localizedDate';

import iconTransaction from './assets/ico-transaction.svg';
import './Row.scss';

const CollectibleRow = ({
  attributes: {
    image,
    rarity,
    serial_number: serialNumber,
    series_size: seriesSize,
    tx_hash: txHash,
    uid,
    value: price,
    value_in_dollars: dollarPrice,
    created_at: createdAt,
  },
  owned,
  traits,
}) => {
  const imageSrc = image || images[rarity.toLowerCase()];

  return (
    <div className="row top-sales-listing--row">
      <div className="col col-md-2 top-sales-listing--item">
        <div className="top-sales-listing--img">
          <Image
            alt="collectible-img"
            src={ imageSrc }
          />
        </div>
      </div>
      <div className="col col-md-3 top-sales-listing--item">
        <ul className="collectible--details">
          <li className="cd-h1">
            <Link
              label={ traits?.Name }
              to={ `/collectibles/${uid}` }
            >
              { traits?.Name }
            </Link>
          </li>
          <li className="cd-h2">
            <strong>{ traits.Action }</strong>
            { ` · ${localizedDate(traits.Date)}` }
          </li>
        </ul>
      </div>
      <div className="col col-md-2 top-sales-listing--item">
        { !owned && (
          <ul className="collectible--details">
            <li className="cd-price--fevr">
              <Price bnb={ price } output="bnb" />
            </li>
            <li className="cd-price--exchange">
              <Price dollar={ dollarPrice } />
            </li>
          </ul>
        ) }
      </div>
      <div className="col col-md-2 top-sales-listing--item">
        <ul className="collectible--details">
          <li className="cd-badge">
            <Image
              alt={ rarity }
              src={ badges[rarity.toLowerCase()] }
            />
          </li>
        </ul>
      </div>
      <div className="col col-md-1 top-sales-listing--item">
        <ul className="collectible--details">
          <li className="cd-h2">
            <strong>{ `${serialNumber}/${seriesSize}` }</strong>
          </li>
        </ul>
      </div>
      <div className="col col-md-1 top-sales-listing--item">
        <ButtonViewTransaction
          className="btn-transaction"
          size="xs"
          theme="none"
          transactionHash={ txHash.split('-')[0] }
        >
          <Image
            alt="transaction"
            src={ iconTransaction }
          />
        </ButtonViewTransaction>
      </div>
      <div className="col col-md-1 top-sales-listing--item size-14">
        { date.short(createdAt) }
      </div>
    </div>
  );
};

CollectibleRow.propTypes = {
  attributes: shape({
    createdAt: string,
    image: string,
    rarity: string,
    serial_number: number,
    series_size: number,
    tx_hash: string,
    uid: number,
    value: number,
  }).isRequired,
  owned: bool,
  traits: shape({
    Action: string,
    Date: string,
    Name: string,
  }).isRequired,
};

CollectibleRow.defaultProps = {
  owned: false,
};

export default CollectibleRow;
