import { useEffect, useState } from 'react';
import { node } from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import classnames from 'classnames';

import ModalAwaiting from 'components/Modal/Awaiting';
import ModalCancelSellCollectible from 'components/Modal/CancelSellCollectible';
import ModalCollectible from 'components/Modal/Collectible';
import ModalConfirmPurchase from 'components/Modal/ConfirmPurchase';
import ModalConnectWallet from 'components/Modal/ConnectWallet';
import ModalError from 'components/Modal/Error';
import ModalFollowMetamask from 'components/Modal/FollowMetamask';
import ModalGetInvolved from 'components/Modal/GetInvolved';
import ModalTransactionSuccessful from 'components/Modal/TransactionSuccessful';
import ModalMint from 'components/Modal/Mint';
import ModalPack from 'components/Modal/Pack';
import ModalSellCollectible from 'components/Modal/SellCollectible';

import useConfig from 'hooks/useConfig';
import useModal from 'hooks/useModal';
import usePackVideo from 'hooks/usePackVideo';
import useQueryParams, { getQueryParams } from 'hooks/useQueryParams';

import { maintenanceMessage } from 'config';

const getPageSuffix = (pathname, features) => {
  const path = pathname.match(/^\/([a-zA-Z/_-]+)/)?.[1];
  if (!path) {
    return 'home';
  }

  switch (path) {
    case 'faq':
      return 'faqs';

    case 'what-is-the-marketplace':
      return 'witrm';

    case 'contact':
      return 'contacts';

    case 'marketplace':
    case 'marketplace/top-sales':
      if (features && !features.marketplace_enabled) {
        return 'marketplace pg-witrm';
      }

      return 'marketplace';

    case 'my-collection':
    case 'my-collection/listed':
      return 'mycollection';

    default:
      if (path.startsWith('collectibles/')) {
        return 'collectible';
      }

      if (path.startsWith('packs/')) {
        return 'pack';
      }

      return path;
  }
};

const AppWrapper = ({ children }) => {
  const [ appClassName, setAppClassName ] = useState('');
  const { pathname } = useLocation();
  const history = useHistory();
  const { queryParams: { page } } = useQueryParams();
  const { close: closeCollectible } = useModal('collectible');
  const { close: closePack } = useModal('pack');
  const { close: closePackVideo } = usePackVideo();
  const { features } = useConfig();
  const suffix = getPageSuffix(pathname, features);

  useEffect(() => {
    setAppClassName(`pg-${suffix}`);

    closeCollectible();
    closePack();
    closePackVideo();

    // Always close the mobile menu on route switch
    document.body.classList.remove('mobile-nav-is-active');
  }, [ closeCollectible, closePack, closePackVideo, pathname, suffix ]);

  useEffect(() => history?.listen(location => {
    const newQueryParams = getQueryParams(location);

    if (location.pathname !== pathname) {
      window.scrollTo(0, 0);
      return;
    }

    if (
      (newQueryParams.page && newQueryParams.page !== '1' && !page)
      || (newQueryParams.page && page && newQueryParams.page !== page)
    ) {
      const scrollHere = document.getElementById('scroll-here');
      if (scrollHere) {
        window.scrollTo(0, scrollHere.offsetTop - 128);
        return;
      }

      window.scrollTo(0, 0);
    }
  }), [ history, page, pathname ]);

  return (
    <>
      <ModalAwaiting />
      <ModalCancelSellCollectible />
      <ModalCollectible />
      <ModalConfirmPurchase />
      <ModalConnectWallet />
      <ModalError />
      <ModalFollowMetamask />
      <ModalGetInvolved />
      <ModalMint />
      <ModalPack />
      <ModalTransactionSuccessful />
      <ModalSellCollectible />

      <div className={ classnames('App', appClassName, {
        maintenance: maintenanceMessage !== '',
      }) }
      >
        { children }
      </div>
    </>
  );
};

AppWrapper.propTypes = {
  children: node,
};

AppWrapper.defaultProps = {
  children: null,
};

export default AppWrapper;
