import { socialLinks } from 'config';

import useGTag from 'hooks/useGTag';

const NavigationSocialMedia = () => {
  const { conversion } = useGTag();

  return (
    <nav className="social-media-nav">
      <ul>
        { socialLinks.map(l => (
          <li key={ l.label }>
            <a
              className={ `social-item social-${l.logo}` }
              href={ l.link }
              onClick={ () => conversion(`social_${l.logo}`) }
              rel="noreferrer"
              target="_blank"
              title={ l.label }
            >
              { l.label }
            </a>
          </li>
        )) }
      </ul>
    </nav>
  );
};

export default NavigationSocialMedia;
