import CollectibleDetails from 'components/Collectible/Details/Details';
import Modal from 'components/Modal/Modal';
import ReturnLink from 'components/ReturnLink/ReturnLink';

import useModal from 'hooks/useModal';

const ModalCollectible = () => {
  const { show } = useModal('collectible');

  const { collectibleId, data } = show || {};

  return (
    <Modal
      className="modal-window"
      name="collectible"
    >
      { data && <CollectibleDetails { ...data } /> }

      <ReturnLink
        label="Go to collectible page ›"
        to={ `/collectibles/${collectibleId}` }
      />
    </Modal>
  );
};

export default ModalCollectible;
