import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'utils/queryString';

const setParam = (params, key, value) => {
  switch (key) {
    case 'order':
      params.sort = value;
      break;

    case 'page':
      params.page = value;
      break;

    case 'per_page':
      params.perPage = value;
      break;

    case 'filters':
      params.filters = value;
      break;

    default:
      params.filters[key] = value;
      break;
  }
};

export const getQueryParams = ({ pathname, search }) => {
  const params = {
    filters: {
      listed: pathname === '/my-collection/listed' ? 'true' : null,
    },
  };

  const searchParams = new URLSearchParams(search);
  searchParams.forEach((value, key) => setParam(params, key, value));

  return params;
};

const useQueryParams = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const queryParams = useMemo(() => getQueryParams({ pathname, search }), [ pathname, search ]);

  const set = useCallback(values => {
    const newParams = { ...queryParams };
    Object.entries(values).forEach(([ key, value ]) => {
      setParam(newParams, key, value);
    });

    const paramsStr = queryString(newParams, [ 'listed' ]);
    if (paramsStr !== search) {
      if (paramsStr) {
        history.push(`${pathname}?${paramsStr}`);
      }
      else {
        history.push(pathname);
      }
    }
  }, [ history, pathname, queryParams, search ]);

  return { queryParams, set };
};

export default useQueryParams;
