import { useState } from 'react';
import {
  bool, number, shape, string,
} from 'prop-types';
import parser from 'html-react-parser';
import classnames from 'classnames';

import badges from 'components/Collectible/assets/badges';
import CollectibleAction from 'components/Collectible/Action';
import CollectibleFromPack from 'components/Collectible/FromPack/FromPack';
import Image from 'components/Image';
import PageContainer from 'components/Page/Container';
import Price from 'components/Price';
import ShareButton from 'components/ShareButton/ShareButton';
import Video from 'components/Video/Video';

import hyphencase from 'utils/hyphencase';
import { v1Error } from 'config';

import licensorLogo from './assets/partner-logo-sportmultimedia.png';
import './Details.scss';

const CollectibleDetails = ({
  description,
  gif,
  gif_xl: gifXl,
  image,
  listed,
  locked_on_v1: locked,
  marketplace_version: version,
  minted,
  name,
  owned,
  pack_id: packId,
  price,
  rarity,
  traits,
  uid,
  video_url: videoUrl,
}) => {
  const [ selected, setSelected ] = useState('videoUrl');

  const slug = hyphencase(rarity);
  const isV1Locked = locked && (version === 'v1');

  return (
    <PageContainer className="collectible-holder">
      <div className="row">
        <div className="col col-md-6 collectible-holder--img">
          <div className="collectible-holder--media-wrap">
            <ul className="collectible--media-gallery">
              { !!videoUrl && (
                <Video
                  key={ videoUrl }
                  as="li"
                  autoPlay={ selected === 'videoUrl' }
                  className="collectible--video"
                  containerClassName={ classnames('collectible--media-gallery-item', {
                    'active-media-item': selected === 'videoUrl',
                  }) }
                  loop
                  src={ videoUrl }
                />
              ) }
              { !!gifXl && (
                <Video
                  as="li"
                  autoPlay={ selected === 'gif' }
                  className="collectible--video collectible--video-animation"
                  containerClassName={ classnames('collectible--media-gallery-item', {
                    'active-media-item': selected === 'gif',
                  }) }
                  loop
                  noAudio
                  src={ gifXl }
                />
              ) }
              <li
                className={ classnames('collectible--media-gallery-item', 'collectible--media-size-definer', {
                  'active-media-item': selected === 'image',
                }) }
              >
                <Image
                  className="collectible--img"
                  src={ image }
                />
              </li>
            </ul>

            <nav className="collectible--media-gallery-nav">
              <ul>
                <li
                  className={ classnames('collectible--media-gallery-nav-item', {
                    'active-nav-item': selected === 'videoUrl',
                  }) }
                >
                  <button
                    onClick={ () => setSelected('videoUrl') }
                    type="button"
                  >
                    <Image
                      className="collectible--thumbnail-img"
                      src={ image }
                    />
                  </button>
                </li>
                <li
                  className={ classnames('collectible--media-gallery-nav-item', {
                    'active-nav-item': selected === 'gif',
                  }) }
                >
                  <button
                    onClick={ () => setSelected('gif') }
                    type="button"
                  >
                    <Image
                      className="collectible--thumbnail-img"
                      src={ image }
                    />
                  </button>
                </li>
                <li
                  className={ classnames('collectible--media-gallery-nav-item', {
                    'active-nav-item': selected === 'image',
                  }) }
                >
                  <button
                    onClick={ () => setSelected('image') }
                    type="button"
                  >
                    <Image
                      className="collectible--thumbnail-img"
                      src={ image }
                    />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col col-md-6 collectible-holder--details">
          <ul className="collectible--details">
            <li className="cd-badge">
              <Image
                alt="badge"
                src={ badges[slug] }
              />
            </li>
            <li className="cd-h1">{ name }</li>
            <li className="cd-h2">
              <strong>{ traits.Action }</strong>
              { ` · Season ${traits.Season}` }
            </li>
            <li className="cd-h3">{ traits['Serial number'] }</li>
            <li className="cd-h4">
              <CollectibleFromPack packId={ packId } />
            </li>
            { description && (
              <li className="cd-description">
                { parser(description) }
              </li>
            ) }
            <li className="cd-specs">
              <ol>
                { traits.Position && (
                  <li>
                    <strong>Position:</strong>
                    { ' ' }
                    { traits.Position }
                  </li>
                ) }
                { traits.Nationality && (
                  <li>
                    <strong>Nationality:</strong>
                    { ' ' }
                    { traits.Nationality }
                  </li>
                ) }
                { traits.Competition && (
                  <li>
                    <strong>Competition:</strong>
                    { ' ' }
                    { traits.Competition }
                  </li>
                ) }
                { traits.Club && (
                  <li>
                    <strong>Club:</strong>
                    { ' ' }
                    { traits.Club }
                  </li>
                ) }
                <li>
                  <strong>Date:</strong>
                  { ' ' }
                  { traits.Date }
                </li>
              </ol>
            </li>

            { listed && (
              <>
                <li className="cd-price--fevr">
                  <Price bnb={ price } output="bnb" />
                </li>
                <li className="cd-price--exchange">
                  <Price bnb={ price } output="dollar" />
                </li>
              </>
            ) }

            <li className="cd-royalty">
              <small>Rights Holder Royalty: 2%</small>
              <br />
              <small>Service Fee: 2%</small>
            </li>

            <li className="cd-cta">
              <CollectibleAction
                gif={ gif }
                image={ image }
                isV1={ !!(version === 'v1') }
                isV1Locked={ isV1Locked }
                listed={ listed }
                minted={ minted }
                owned={ owned }
                price={ price }
                title={ name }
                uid={ uid }
              />
              <ShareButton />
            </li>
            { isV1Locked && owned
              && (
              <li className="cd-warning">
                { v1Error }
              </li>
              ) }
            <li className="cd-license">
              <strong className="lbl">License:</strong>
              <div className="licensor">
                <Image
                  alt="Sportmultimedia Collectibles"
                  className="licensor-logo"
                  src={ licensorLogo }
                />
                { /*
                <span className="licensor-name">Collectibles</span>
                */ }
              </div>
            </li>
          </ul>
        </div>
      </div>
    </PageContainer>
  );
};

CollectibleDetails.propTypes = {
  description: string.isRequired,
  gif: string,
  gif_xl: string,
  video_url: string,
  image: string.isRequired,
  listed: bool,
  locked_on_v1: bool,
  marketplace_version: string,
  minted: bool,
  name: string.isRequired,
  owned: bool,
  pack_id: number.isRequired,
  price: number.isRequired,
  rarity: string.isRequired,
  traits: shape({
    Name: string,
  }).isRequired,
  uid: number.isRequired,
};

CollectibleDetails.defaultProps = {
  gif: '',
  gif_xl: '',
  video_url: '',
  listed: false,
  minted: false,
  owned: false,
  locked_on_v1: false,
  marketplace_version: null,

};

export default CollectibleDetails;
