import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import chunk from 'lodash.chunk';

import CollectibleListItem from 'components/Collectible/ListItem/ListItem';
import FilterSelect from 'components/Filter/Select';
import FilterSort from 'components/Filter/Sort';
import FilterTextValueContainer from 'components/Filter/TextValueContainer';
import Metadata from 'components/Metadata';
import PageContainer from 'components/Page/Container';
import PackListItem from 'components/Pack/ListItem/ListItem';
import PageTitle from 'components/Page/Title';
import Pagination from 'components/Pagination/Pagination';
import ScrollHere from 'components/ScrollHere/ScrollHere';
import Tabs from 'components/Tabs/Tabs';
import TitleLines from 'components/TitleLines/TitleLines';

import { perPageOptionsMyCollection } from 'config';

import useConfig from 'hooks/useConfig';
import useQueryParams from 'hooks/useQueryParams';
import useUserCollectibles from 'hooks/useUserCollectibles';
import useUserPacks, { updateUserPacks } from 'hooks/useUserPacks';

import './style.scss';

const MyCollection = () => {
  const { features, filters } = useConfig();
  const collectibles = useUserCollectibles();
  const packs = useUserPacks();

  const { pathname } = useLocation();
  const listed = pathname === '/my-collection/listed' ? 'true' : null;

  const { queryParams: { filters: activeFilters }, set } = useQueryParams();

  const rarities = filters?.rarity?.map(el => ({ label: el, value: el }));

  useEffect(() => {
    if (listed !== activeFilters.listed) {
      set({ listed });
    }
  }, [ activeFilters.listed, listed, set ]);

  useEffect(() => {
    if (features) {
      updateUserPacks();
    }
  }, [ features ]);

  return (
    <>
      <Metadata
        description="Proudly showcase your NFTs and flex those rare football moments to other fans."
        title="RealFevr - My Collection"
      />
      <PageContainer>
        <PageTitle>My Collection</PageTitle>
        { !!packs?.length && (
          <div className="container--mycollection-packs">
            <div className="row">
              <div className="col">
                <TitleLines size="smaller">Packs</TitleLines>
              </div>
            </div>
          </div>
        ) }

        { !!packs?.length && chunk(packs || [], 4).map((row, idx) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={ `row-${idx}` }
            className="row row--align-items-center"
          >
            { row.map(item => (
              <div className="col col-md-3" key={ `item-${item.pack_type}` }>
                <PackListItem { ...item } />
              </div>
            )) }
          </div>
        )) }

        <div className="container container--mycollection-collectibles">
          { features && !features.packs_opened_enabled && (
            <div className="row row-header">
              <div className="col">
                <TitleLines
                  comingSoon={ !collectibles?.meta?.total_count }
                  size={ collectibles?.meta?.total_count ? 'smaller' : '' }
                >
                  Collectibles
                </TitleLines>
              </div>
            </div>
          ) }

          { features?.packs_opened_enabled && (
            <div className="row row-header">
              <div className="col col-align-left">
                <FilterSort page={ listed ? 'marketplace' : 'collection' } />
              </div>
              <div className="col">
                <TitleLines size="smaller">Collectibles</TitleLines>
              </div>
              <div className="col">
                <div className="form-element-container form-element-container--align-right">
                  <label htmlFor="rarity">Filter by rarity</label>
                  <FilterSelect
                    className="form-element-container--align-right"
                    closeMenuOnSelect={ false }
                    components={ {
                      ValueContainer: FilterTextValueContainer,
                    } }
                    countVal="rarities"
                    hideSelectedOptions={ false }
                    id="selectbox_rarity"
                    isMulti
                    name="rarity"
                    onChange={ option => set({
                      page: 1,
                      rarity: option.map(e => e.value).join(','),
                    }) }
                    options={ rarities }
                    placeholder="All rarities"
                    value={ rarities?.filter(el => activeFilters.rarity?.split(',').some(rarity => el.value === rarity)) ?? '' }
                  />
                </div>
              </div>
              <ScrollHere />
            </div>
          ) }

          { features?.marketplace_enabled && (
            <Tabs
              tabs={ [
                { label: 'Owned', path: '/my-collection' },
                { label: 'For Sale', path: '/my-collection/listed' },
              ] }
            />
          ) }

          { chunk(collectibles?.data || [], 4).map((row, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="row" key={ `row-${idx}` }>
              { row.map(item => (
                <div className="col col-md-3" key={ item.id }>
                  <CollectibleListItem { ...item } />
                </div>
              )) }
            </div>
          )) }

          { features?.packs_opened_enabled && (
            <div className="marketplace-bottom-filters">
              <Pagination
                collectibles
                meta={ collectibles?.meta }
                defaultOption={ 12 }
                options={ perPageOptionsMyCollection }
              />
            </div>
          ) }
        </div>
      </PageContainer>
    </>
  );
};

export default MyCollection;
