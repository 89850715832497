import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

import { add as addError } from 'hooks/useErrors';
import { openModal } from 'hooks/useModal';
import useConfig, { getConfig } from 'hooks/useConfig';

import bepro from 'lib/bepro';

export const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState({
  bepro: [ 'buy', 'buyPacks' ].reduce((acc, method) => ({
    ...acc,
    [method]: async (...args) => {
      const balance = await bepro[method === 'buy' ? 'getBNBBalance' : 'getBalance']();
      if (!balance) {
        return addError(method === 'buy' ? 'no bnb' : 'no fevr', true);
      }

      switch (method) {
        case 'buy': {
          const [ { price } ] = args;
          if (price > balance) {
            return addError(method === 'buy' ? 'insufficient bnb' : 'insufficient fevr', true);
          }
          break;
        }

        case 'buyPacks': {
          const [ { quantity, type } ] = args;
          const { fevr_in_dollar: ratio, packs_info: packsInfo } = getConfig();
          const pack = packsInfo?.packs.find(({ pack_type: pType }) => pType === type);
          const price = pack.price * quantity / ratio;
          if (price > balance) {
            return addError('insufficient fevr', true);
          }
          break;
        }

        default:
          break;
      }

      return bepro[method](...args);
    },
  }), { ...bepro }),
});

export const isConnected = () => {
  const { connected, networkActive, networkWanted } = getGlobalState('bepro');
  return connected && networkActive === networkWanted;
};

export const updateConnection = async () => {
  try {
    const config = getConfig();
    const networkWanted = config?.network;

    const [ accounts, networkActive ] = await Promise.all([
      bepro.getAccounts(),
      bepro.getNetwork(),
    ]);
    const connected = !!accounts?.length;

    const state = getGlobalState('bepro');
    if (
      state.connected !== connected
      || state.networkActive !== networkActive
      || state.networkWanted !== networkWanted
    ) {
      setGlobalState('bepro', {
        ...state,
        connected,
        networkActive,
        networkWanted,
      });
    }
  }
  catch (ex) {
    // If this throws we're not connected, it's safe to ignore.
    // eslint-disable-next-line no-console
    console.error(ex);
  }
};

export const wrapConnectWallet = callback => async () => {
  await updateConnection();

  if (!isConnected()) {
    return openModal('connect-wallet', {
      onClose: () => {
        if (isConnected()) {
          callback();
        }
      },
    });
  }

  return callback();
};

export const wrapConfirmPurchase = (callback, params) => () => openModal('confirm-purchase', {
  ...params,
  onConfirm: callback,
});

// Metamask extension injects the browser object on page initialization if it exists
(async () => {
  const injected = await bepro.injected();

  const state = {
    ...getGlobalState('bepro'),
    injected,
    network: null,
  };

  // connected === undefined is used for checking whether bepro has tried to communicated with metamask yet
  if (!injected) {
    state.connected = false;
  }

  setGlobalState('bepro', state);

  if (injected) {
    updateConnection();

    bepro.on('accountsChanged', updateConnection);

    bepro.on('disconnect', error => {
      // eslint-disable-next-line no-console
      console.error({ error });

      setGlobalState('bepro', {
        ...getGlobalState('bepro'),
        connected: false,
        injected: false,
        network: null,
      });
    });
  }
})();

const useBepro = () => {
  const [ state ] = useGlobalState('bepro');
  const {
    rf_marketplace_sm_address: marketplaceV1Address,
    rf_marketplaceV2_sm_address: marketplaceAddress,
    rf_opener_sm_address: openerAddress,
    rf_token_sm_address: tokenAddress,
    web3_link: web3Connection,
  } = useConfig();

  useEffect(() => {
    // We can initialize multiple times on many components, our bepro lib knows it should only open
    // one connection.
    bepro.initialize({
      marketplaceAddress, marketplaceV1Address, openerAddress, tokenAddress, web3Connection,
    });
  }, [ marketplaceAddress, marketplaceV1Address, openerAddress, tokenAddress, web3Connection ]);

  return state;
};

export default useBepro;
