import { useState, useEffect } from 'react';
import { arrayOf, func, number } from 'prop-types';
import { Range } from 'rc-slider';

import 'rc-slider/assets/index.css';
import './Range.scss';

import formatNumber from 'utils/formatNumber';

const FilterRange = ({
  max, min, onAfterChange, value,
}) => {
  const [ sliderValue, setSliderValue ] = useState([ min, max ]);

  useEffect(() => {
    setSliderValue(value);
  },
  [ value ]);

  return (
    <div className="filter--range">
      <Range
        allowCross={ false }
        value={ sliderValue }
        max={ max }
        min={ min }
        onAfterChange={ onAfterChange }
        onChange={ setSliderValue }
      />
      <div className="filter--range-labels">
        <div className="filter--range-label filter--range-min">
          { formatNumber(sliderValue[0]) }
          <small>BNB</small>
        </div>
        <div className="filter--range-label filter--range-max">
          { formatNumber(sliderValue[1]) }
          <small>BNB</small>
        </div>
      </div>
    </div>
  );
};

FilterRange.propTypes = {
  max: number,
  min: number,
  onAfterChange: func,
  value: arrayOf(number),
};

FilterRange.defaultProps = {
  max: 1,
  min: 0,
  onAfterChange: () => {},
  value: [ 0, 1 ],
};

export default FilterRange;
