import { useEffect, useState } from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

import useConfig, { updateConfig } from 'hooks/useConfig';

import { countdownTimer } from 'config';

import './Countdown.scss';

const Countdown = ({ className }) => {
  const { target, title } = countdownTimer || {};
  const { features } = useConfig();

  const [ remaining, setRemaining ] = useState();
  const [ triggeredUpdate, setTriggeredUpdate ] = useState();

  useEffect(() => {
    const update = () => {
      if (target) {
        setRemaining(Math.max(new Date(target) - new Date(), 0));
      }
    };

    update();
    const intervalId = setInterval(update, 1000);

    return () => clearInterval(intervalId);
  }, [ target ]);

  useEffect(() => {
    if (features && !features.package_sales_enabled && remaining === 0) {
      if (!triggeredUpdate) {
        setTriggeredUpdate(true);
        updateConfig();
      }

      const intervalId = setInterval(updateConfig, 1500);

      return () => clearInterval(intervalId);
    }

    return () => {};
  }, [ features, remaining, triggeredUpdate ]);

  if (remaining === undefined || !features || features.package_sales_enabled) {
    return null;
  }

  const days = Math.floor(remaining / 1000 / 60 / 60 / 24);
  const hours = Math.floor((remaining / 1000 / 60 / 60) - (days * 24));
  const minutes = Math.floor((remaining / 1000 / 60) - (days * 24 * 60) - (hours * 60));
  const seconds = Math.floor((remaining / 1000) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60));

  const daysStr = `${days < 10 ? '0' : ''}${days}`;
  const hoursStr = `${hours < 10 ? '0' : ''}${hours}`;
  const minutesStr = `${minutes < 10 ? '0' : ''}${minutes}`;
  const secondsStr = `${seconds < 10 ? '0' : ''}${seconds}`;

  return (
    <div className={ classnames('release-countdown', className) }>
      <h3 className="mid-title green">{ title }</h3>
      <ul>
        <li>
          <div className="release-countdown-nr release-countdown--days">{ daysStr }</div>
          <small>Days</small>
        </li>
        <li>
          <div className="release-countdown-nr release-countdown--hours">{ hoursStr }</div>
          <small>Hours</small>
        </li>
        <li>
          <div className="release-countdown-nr release-countdown--minutes">{ minutesStr }</div>
          <small>Minutes</small>
        </li>
        <li>
          <div className="release-countdown-nr release-countdown--seconds">{ secondsStr }</div>
          <small className="small-seconds">Seconds</small>
        </li>
      </ul>
    </div>
  );
};

Countdown.propTypes = {
  className: string,
};

Countdown.defaultProps = {
  className: '',
};

export default Countdown;
