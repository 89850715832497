import Button from 'components/Button/Button';
import ButtonBuyTokens from 'components/Button/BuyTokens';
import Image from 'components/Image';
import Metadata from 'components/Metadata';
import PageContainer from 'components/Page/Container';
import PageTitle from 'components/Page/Title';
import TitleLines from 'components/TitleLines/TitleLines';

import {
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  fevrDistributionPeriodicity,
  fevrDistributionTable,
  fevrRoadmap,
  fevrUtility,
  fevrEcosystem,
  fevrHero,
  fevrToken,
} from 'pages/fevr/assets';

import useConfig from 'hooks/useConfig';

import './style.scss';

const Fevr = () => {
  const { white_papper_url: whitePaper } = useConfig();

  return (
    <>
      <Metadata
        description="FEVR token is your football token and the main fuel of the RealFevr ecosystem."
        title="RealFevr - FEVR TOKEN"
      />
      <PageTitle>FEVR Token</PageTitle>

      <section className="fevr--section-1">
        <PageContainer>
          <div className="row">
            <div className="col-md-5">
              <Image
                alt="$FEVR Token"
                className="fevr--tokens-hero"
                src={ fevrHero }
              />
            </div>
            <div className="col-md-7">
              <div className="fevr--hero-content">
                <h2 className="fevr--hero-title">
                  Introducing $FEVR:
                  { ' ' }
                  <span className="green">the RealFevr fuel</span>
                </h2>
                <div className="fevr--hero-desc">
                  <p>
                    RealFevr is composed of two moving parts, the fantasy leagues and the
                    collectibles marketplace, that are united through $FEVR - the token that
                    unlocks all incentives within our ecosystem.
                  </p>
                  <p>
                    { `The main goal of the FEVR token is to open the door to web3,
                    where you, the user, are the central piece of the network and the main beneficiary of RealFevr's
                    products. In sum, $FEVR is a gateway to RealFevr's packs of football everlasting moments,
                    clubs launchpad, subscription pools, token leagues, and every other feature we release in the future.` }
                  </p>
                  <p>
                    { `By using the Fevr token, fans can collect,
                     borrow and lend digital collectibles fully backed by Intellectual Property.` }
                  </p>
                  <p>
                    To learn more about $FEVR and the token ecosystem, please read the litepaper.
                  </p>
                  <p>
                    <strong>Contract Address:</strong>
                    { ' ' }
                    <span style={ { wordBreak: 'break-all' } }>
                      0x82030cdbd9e4b7c5bb0b811a61da6360d69449cc
                    </span>
                  </p>

                  <br />

                  <ButtonBuyTokens
                    size="l"
                    sizeMin={ 200 }
                    theme="green-gradient"
                  />
                </div>

                <ul className="container-cta-btns">
                  <li>
                    <Button
                      external
                      size="m"
                      theme="white-gradient"
                      to={ whitePaper }
                    >
                      Litepaper
                    </Button>
                  </li>
                  <li>
                    <Button
                      external
                      size="m"
                      theme="white-gradient"
                      to="https://t.me/realfevrofficial"
                    >
                      Official chat
                    </Button>
                  </li>
                  <li>
                    <Button
                      external
                      size="m"
                      theme="white-gradient"
                      to="https://t.me/realfevr_news"
                    >
                      Announcements
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </PageContainer>
      </section>
      <section className="fevr--section-2">
        <PageContainer>
          <div className="row">
            <div className="col">
              <TitleLines>
                $FEVR ecosystem
              </TitleLines>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Image
                alt="$FEVR token ecosystem"
                className="fevr--img-full fevr--img-ecosystem"
                src={ fevrEcosystem }
              />
            </div>
          </div>
          <div className="row row--fevr-infographic">
            <div className="col-md-2">
              <h3>
                FEVR Ecosystem infographic
              </h3>
            </div>
            <div className="col-md-10">
              <p>
                On the left of the FEVR Ecosystem infographic,
                we have the Fantasy Leagues and on the right,
                the Collectibles Marketplace.
                To access packs and video collectibles, fans need to acquire FEVR tokens.
              </p>
              <p>
                It’s important to note that the fans are always the owners of the assets, both the
                tokens and the collectibles, even they lend them.
              </p>
            </div>
          </div>
        </PageContainer>
      </section>
      <section className="fevr--section-3">
        <PageContainer>
          <div className="row">
            <div className="col">
              <TitleLines>
                Business model
              </TitleLines>
              <p className="fevr--business-model-desc">
                RealFevr’s business model is composed of various moving parts.
                Fans can not only play free fantasy leagues and token leagues,
                but also purchase Video Collectibles packs that will award them
                unique IP-protected digital football NFTs.
                To acquire and trade these forever lasting football moments fans
                need to use RealFevr’s token, $FEVR.
              </p>
            </div>
          </div>
        </PageContainer>
        <PageContainer className="fevr--business-model-icons">
          <div className="row row--align-items-center">
            <div className="col-md-4 fevr--business-model-item">
              <Image
                alt="First pack revenue share"
                className="fevr--business-model-icon"
                src={ icon1 }
              />
              <h3 className="mid-title green">
                First pack
                <br />
                { ' ' }
                revenue share
              </h3>
              <p>
                After possessing FEVR tokens in their Binance Smart Chain address,
                fans can acquire digital collectibles packs.
                Below is the fee distribution per account.
              </p>
              <p>
                The fee distribution per account will be the follow: 50% for Right Holders,
                40% Locked for a unspecified amount of time and 10% for RealFevr.
              </p>
            </div>
            <div className="col-md-4 fevr--business-model-item">
              <Image
                alt="Fees &amp; royalties"
                className="fevr--business-model-icon"
                src={ icon2 }
              />
              <h3 className="mid-title green">
                Fees &amp; royalties
              </h3>
              <p>
                All RealFevr Digital Collectibles marketplace transactions
                will incur a maximum 5% fee. Loyalty programs will be implemented
                to reward fans who buy and sell on a recurring basis.
                Additionally, RealFevr incorporates a perpetual royalty
                to all right-holders, which is negotiated case by case and
                is fixed to all transactions after the first sale.
                This way, we protect and reward the official
                intellectual property (IP) of right-holders.
              </p>
            </div>
            <div className="col-md-4 fevr--business-model-item">
              <Image
                alt="Subscriptions"
                className="fevr--business-model-icon"
                src={ icon3 }
              />
              <h3 className="mid-title green">
                Subscriptions
              </h3>
              <p>
                Fans who wish to receive packs on a recurring basis,
                may lock FEVR tokens in a specified smart contract that
                will award them a number of packs per drop.
                Hence, instead of waiting in queue at each drop,
                or to avoid missing packs everytime there is a new drop,
                fans can adopt the subscription model.
                The price for each subscription depends on the number of spots available.
              </p>
            </div>

            <div className="col-md-4 fevr--business-model-item">
              <Image
                alt="Borrow and lending"
                className="fevr--business-model-icon"
                src={ icon4 }
              />
              <h3 className="mid-title green">
                Borrow and lending
              </h3>
              <p>
                Instead of just selling the digital asset in the marketplace,
                fans can borrow and lend video collectibles from and to other fans.
              </p>
              <p>
                When a fan lends a video collectible, he will get extra FEVR tokens.
                When a fan borrows a video collectible, he will pay FEVR tokens to the other fan.
                However, because the asset is returned to the original owner,
                the amounts charged will be much more affordable.
              </p>
            </div>
            <div className="col-md-4 fevr--business-model-item">
              <Image
                alt="Clubs launchpad"
                className="fevr--business-model-icon"
                src={ icon5 }
              />
              <h3 className="mid-title green">
                Clubs launchpad
              </h3>
              <p>
                Any club can join the RealFevr ecosystem and deploy their own collectibles
                that will immediately trade in the FEVR marketplace and
                get the exposure of all the marketplace users.
              </p>
              <p>
                There is no cost associated with this service as we believe in
                decentralization and empowering organizations,
                especially at such a dire time, when clubs are facing a shortage of
                revenue related to the pandemic restrictions.
              </p>
            </div>
            <div className="col-md-4 fevr--business-model-item">
              <Image
                alt="In-game video collectibles"
                className="fevr--business-model-icon"
                src={ icon6 }
              />
              <h3 className="mid-title green">
                In-game video collectibles
              </h3>
              <p>
                Within the RealFevr ecosystem, collectibles will be used in-game,
                to get bonus points, power ups and boost for the players in each fans’
                fantasy teams.
                This is, collectibles will award extra points to fantasy league teams.
              </p>
              <p>
                This way, RealFevr effectively becomes the first widely adopted fantasy
                league platform to use unique digital collectibles as an economic
                incentive within the game, to reward players and fans for participating.
              </p>
            </div>

            <div className="col-md-4 fevr--business-model-item">
              <Image
                alt="Fantasy Leagues"
                className="fevr--business-model-icon"
                src={ icon7 }
              />
              <h3 className="mid-title green">
                Fantasy Leagues
              </h3>
              <p>
                There are two types of leagues: free leagues and token leagues.
                In free leagues, players can pay a subscription to help them achieve better results.
                In token leagues fans will be able to wager FEVR tokens.
                Therefore, RealFevr’s business model covers the more traditional
                subscription-based and advertising channels revenue model,
                as well as the nouveau cryptocurrency revenue channel by charging a wagering fee.
              </p>
            </div>
          </div>
        </PageContainer>
      </section>
      <section className="fevr--section-4">
        <PageContainer>
          <div className="row">
            <div className="col">
              <TitleLines>
                Tokenomics
              </TitleLines>
              <p className="fevr--tokenomics-desc">
                The FEVR token was launched in Binance Smart Chain,
                through an IDO in Polkastarter.
                Furthermore, below you can find all the tokenomics and its utility.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col col-align-center">
              <h3 className="mid-title green">Distribution Table</h3>
              <div className="fevr--token-supply">
                <Image
                  alt="$FEVR token"
                  src={ fevrToken }
                />
                { ' ' }
                <div className="fevr--token-supply-txt">
                  Token supply:
                  { ' ' }
                  <strong>16 000 000 000</strong>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Image
                alt="$FEVR token distribution table"
                className="fevr--distribution-table"
                src={ fevrDistributionTable }
              />
            </div>
          </div>
        </PageContainer>
        <PageContainer className="fevr--distribution-periodicity">
          <div className="row">
            <div className="col col-align-center">
              <h3 className="mid-title green">
                Distribution Periodicity
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Image
                alt="$FEVR token distribution periodicity"
                className="fevr--img-full"
                src={ fevrDistributionPeriodicity }
              />
            </div>
          </div>
        </PageContainer>
        <PageContainer className="fevr--utility">
          <div className="row">
            <div className="col col-align-center">
              <h3 className="mid-title green">
                $FEVR Utility
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p>
                FEVR token is the main fuel of the RealFevr ecosystem.
                The goal of the token is to be used as the currency of reference
                in the collectibles marketplace.
                Essentially, any operation that takes place within the RealFevr
                ecosystem will occur with the FEVR token as the base currency.
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Additionally, RealFevr will incorporate other token mechanisms
                such as monthly package subscriptions, borrowing and lending of
                digital collectibles, among other features to increase and maximize
                the utility of the FEVR token.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Image
                alt="$FEVR token utility"
                className="fevr--img-full"
                src={ fevrUtility }
              />
            </div>
          </div>
        </PageContainer>
      </section>
      <section className="fevr--section-5">
        <PageContainer>
          <div className="row">
            <div className="col">
              <TitleLines>
                Roadmap
              </TitleLines>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Image
                alt="$FEVR token roadmap"
                className="fevr--img-full"
                src={ fevrRoadmap }
              />
            </div>
          </div>
        </PageContainer>
      </section>
    </>
  );
};

export default Fevr;
