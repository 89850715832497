import Button from 'components/Button/Button';

import useGTag from 'hooks/useGTag';

const ButtonSeePacks = () => {
  const { conversion } = useGTag();

  return (
    <Button
      className="btn--shadow"
      onClick={ () => conversion('see_packs') }
      size="l"
      theme="purple-gradient"
      to="/marketplace"
    >
      Start Your Collection
    </Button>
  );
};

export default ButtonSeePacks;
