import history from '../history';

const awId = process.env.REACT_APP_ADWORDS_ID;
const tagId = process.env.REACT_APP_GTAG_ID;

const conversionCodes = {
  buy_fevr: 'o5WoCLWEx90CEJWpn8MD',
  connect_wallet: '_o9BCJ2ExN0CEJWpn8MD',
  play_fantasy: 'GDWuCKes790CEJWpn8MD',
  newsletter: 'xOGDCKmt6d0CEJWpn8MD',
  register_interest: 'MxBXCM2_7t0CEJWpn8MD',
  see_packs: 'br2aCOfJ6N0CEJWpn8MD',
  social_telegram: 'kGgKCLad790CEJWpn8MD',
  social_twitter: 'WylHCL7k790CEJWpn8MD',
};

const sendCodes = {
  connect_wallet: {
    eventCategory: 'Connect_Wallet ', eventAction: 'click', eventLabel: 'Connect_Wallet', eventValue: 0,
  },
  metamask: {
    eventCategory: 'Metamask', eventAction: 'click', eventLabel: 'Go_To_Metamask', eventValue: 0,
  },
  see_packs: {
    eventCategory: 'Start_Your_Collection', eventAction: 'click', eventLabel: 'Start_Collection', eventValue: 0,
  },
  play_fantasy: {
    eventCategory: 'Play_Fantasy', eventAction: 'click', eventLabel: 'Play_Fantasy', eventValue: 0,
  },
  buy_fevr: {
    eventCategory: 'Buy_$FEVR', eventAction: 'click', eventLabel: 'Play_$FEVR_Token', eventValue: 0,
  },
  register_interest: {
    eventCategory: 'Register_Interest', eventAction: 'click', eventLabel: 'Register_Interest', eventValue: 0,
  },
  newsletter: {
    eventCategory: 'Email_Subscription', eventAction: 'click', eventLabel: 'Email_Subscription', eventValue: 0,
  },
  social_telegram: {
    eventCategory: 'Follow_Our_Telegram', eventAction: 'click', eventLabel: 'Telegram', eventValue: 0,
  },
};

history.listen(({ pathname }) => {
  const { gtag } = window;
  if (gtag && tagId) {
    tagId.split(',').forEach(id => {
      gtag('config', id, { page_path: pathname });
    });
  }
});

const gtagEvents = (gtag, type) => {
  const {
    eventAction, eventCategory, eventLabel, eventValue,
  } = sendCodes[type];
  gtag('event', eventAction, {
    event_category: eventCategory,
    event_label: eventLabel,
    value: eventValue,
  });
};

const conversion = type => {
  const { gtag } = window;
  if (gtag && awId && conversionCodes[type]) {
    gtag('event', type);
    gtag('event', 'conversion', {
      send_to: `${awId}/${conversionCodes[type]}`,
    });
  }
  if (gtag && sendCodes[type]) {
    gtagEvents(gtag, type);
  }
};

const track = (type, ...args) => {
  const { gtag } = window;
  if (gtag) {
    gtag('event', type, ...args);
  }
};

const useGTag = () => ({
  awId,
  tagId,

  conversion,
  track,
});

export default useGTag;
