import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

import useModal from 'hooks/useModal';

const ModalMint = () => {
  const mint = useModal('mint-collectible');
  const follow = useModal('follow-metamask');

  const { onConfirm } = mint.show || {};

  return (
    <Modal name="mint-collectible">
      <div className="popup--title">Mint Collectible</div>
      <div className="popup--desc">
        Are you sure you want to mint this collectible?
        <br />
        A fee will be applied.
      </div>

      <ul className="popup--actions">
        <li>
          <Button
            className="btn-primary"
            onClick={ async () => {
              mint.close();
              follow.open({ title: 'Mint Collectible' });
              await onConfirm();
              follow.close();
            } }
            size="m"
            theme="green-gradient"
          >
            Confirm
          </Button>
        </li>
        <li>
          <Button
            className="btn-secondary"
            onClick={ mint.close }
            size="m"
          >
            Cancel
          </Button>
        </li>
      </ul>
    </Modal>
  );
};

export default ModalMint;
