import basicImg from './packs/pack-basic.png';
import soldOut from './sold-out-badge.svg';

import rareImg from './packs/pack-rare.png';
import superRareImg from './packs/pack-super-rare.png';

import basicSvg from './packs-shapes/basic.svg';
import rareSvg from './packs-shapes/rare.svg';
import superRareSvg from './packs-shapes/super-rare.svg';

import basicBdg from './badges/basic.svg';
import rareBdg from './badges/rare.svg';
import superRareBdg from './badges/super-rare.svg';

const imagePacks = {
  basic: {
    img: basicImg,
    svg: basicSvg,
    badge: basicBdg,
  },
  rare: {
    img: rareImg,
    svg: rareSvg,
    badge: rareBdg,
  },
  'super-rare': {
    img: superRareImg,
    svg: superRareSvg,
    badge: superRareBdg,
  },
  soldout: soldOut,
};

export default imagePacks;
