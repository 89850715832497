import { useEffect, useMemo } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

import api from 'lib/api';

import useQueryParams, { getQueryParams } from 'hooks/useQueryParams';

const initialState = { userCollectibles: null };
const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState(initialState);

export const updateUserCollectibles = async params => {
  const state = getGlobalState('userCollectibles');

  setGlobalState('userCollectibles', {
    loading: true,
    meta: state?.meta || null,
  });

  const query = params || getQueryParams(window.location);
  const userCollectibles = await api.getUserCollectibles(query);

  setGlobalState('userCollectibles', {
    ...userCollectibles,

    data: userCollectibles?.data?.map(collectible => ({
      ...collectible,
      owned: true,
    })),
  });
};

const useUserCollectibles = () => {
  const [ userCollectibles ] = useGlobalState('userCollectibles');
  const { queryParams } = useQueryParams();

  useEffect(() => {
    updateUserCollectibles(queryParams);
  }, [ queryParams ]);

  return useMemo(() => userCollectibles || {
    meta: {
      'current-page': 1,
      'next-page': 0,
      'prev-page': 1,
      'total-pages': 0,
      'total-count': 0,
    },
  }, [ userCollectibles ]);
};

export default useUserCollectibles;
