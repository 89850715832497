import { useMemo, useRef } from 'react';

import addPassiveEventListener from 'utils/addPassiveEventListener';

const updateTopOffset = (node, offset) => {
  if (!node) {
    return;
  }

  if (!offset) {
    node.style.top = '';
    node.style.bottom = '';
    node.style.position = '';
    return;
  }

  node.style.position = 'sticky';

  const { clientHeight } = node;

  // hardcoded to simplify
  const headerHeight = 32;

  if (!offset.endsWith('%')) {
    node.style.top = offset;
    return;
  }

  const absolute = Number(offset.replace('%', ''));
  const value = `calc(${offset} - ${clientHeight * absolute / 100}px + ${headerHeight}px)`;

  if (absolute <= 50) {
    node.style.top = value;
  }
  else {
    node.style.bottom = value;
  }
};

const useStickyVideo = sticky => {
  const ref = useRef();

  const offset = useMemo(() => {
    if (!sticky && sticky !== 0) {
      return null;
    }

    if (sticky === true) {
      return '50%';
    }

    if (typeof sticky === 'number') {
      return `${sticky}%`;
    }

    return sticky;
  }, [ sticky ]);

  if (!offset) {
    return null;
  }

  return node => {
    if (ref.current) {
      ref.current.remove();
      ref.current = null;
    }

    if (!node) {
      return;
    }

    ref.current = { node };

    const updateTop = () => updateTopOffset(node, offset);
    updateTop();

    const removeEventListener = addPassiveEventListener(window, 'resize', updateTop);

    ref.current.remove = () => {
      removeEventListener();
    };
  };
};

export default useStickyVideo;
