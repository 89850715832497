import { string } from 'prop-types';
import ReactTooltip from 'react-tooltip';

import './Info.scss';

const IconInfo = ({ text }) => (
  <>
    <span
      className="ico-info"
      data-tip={ text }
    />
    { !!text && (
      <ReactTooltip
        className="tooltip-info"
        effect="solid"
        place="bottom"
        type="dark"
      />
    ) }
  </>
);

IconInfo.propTypes = {
  text: string,
};

IconInfo.defaultProps = {
  text: '',
};

export default IconInfo;
