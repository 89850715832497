import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  bool, elementType, number, oneOfType, string,
} from 'prop-types';
import classnames from 'classnames';

import useIpfs from 'hooks/useIpfs';
import useStickyVideo from 'hooks/useStickyVideo';

import './Video.scss';

const Video = ({
  as: Component,
  autoPlay,
  containerClassName,
  noAudio,
  src,
  sticky,
  type,
  unMute,
  ...props
}) => {
  const isYouTube = useMemo(() => src?.includes('youtube.com') || src?.includes('youtu.be'), [ src ]);

  const ipfs = useIpfs();

  const [ playing, setPlaying ] = useState(autoPlay);
  const [ muted, setMuted ] = useState(autoPlay && !unMute);

  const videoRef = useRef();
  const controls = !autoPlay && playing;

  const onClick = () => {
    const { current } = videoRef;

    if (!isYouTube && current && !controls) {
      if (playing) {
        current.pause();
      }
      else {
        current.play();
      }
    }
  };

  useEffect(() => {
    const { current } = videoRef;

    if (!isYouTube && current) {
      if (autoPlay) {
        current.currentTime = 0;
        current.play();
      }
      else {
        current.pause();
      }
    }
  }, [ isYouTube, autoPlay ]);

  useEffect(() => {
    if (isYouTube) {
      return () => {};
    }

    const { current } = videoRef;

    const handler = e => {
      if (e.type === 'play' && !playing) {
        setPlaying(true);
      }
      else if (e.type === 'pause' && playing) {
        setPlaying(false);
      }
    };

    current.addEventListener('play', handler);
    current.addEventListener('pause', handler);

    return () => {
      current.removeEventListener('play', handler);
      current.removeEventListener('pause', handler);
    };
  }, [ isYouTube, playing ]);

  const containerRef = useStickyVideo(sticky);

  return !!src && (
    <Component
      ref={ containerRef }
      className={ classnames('video-sound-control--container', containerClassName, {
        'video--iframe-container': isYouTube,
      }) }
    >
      { !isYouTube && !noAudio && autoPlay && playing && (
        <button
          className={ classnames('video-sound-control--btn', {
            'video-sound-control--btn-off': muted,
          }) }
          label={ muted ? 'Unmmute' : 'Mute' }
          onClick={ () => setMuted(!muted) }
          type="button"
        />
      ) }

      { !isYouTube && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          autoPlay={ autoPlay }
          controls={ controls }
          muted={ muted }
          onClick={ onClick }
          playsInline
          preload="none"
          ref={ videoRef }
          { ...props }
        >
          <source
            src={ ipfs(src) }
            type={ type }
          />
          Your browser does not support the video tag.
        </video>
      ) }

      { isYouTube && (
        <iframe
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
          src={ src }
          title="YouTube video player"
          { ...props }
        />
      ) }
    </Component>
  );
};

Video.propTypes = {
  as: elementType,
  autoPlay: bool,
  containerClassName: string,
  noAudio: bool,
  src: string.isRequired,
  sticky: oneOfType([ bool, number, string ]),
  type: string,
  unMute: bool,
};

Video.defaultProps = {
  as: 'div',
  autoPlay: false,
  containerClassName: '',
  noAudio: false,
  sticky: null,
  type: 'video/mp4',
  unMute: false,
};

export default Video;
