import 'rc-slider/assets/index.css';

import FilterCheckbox from 'components/Filter/Checkbox';
import FilterRange from 'components/Filter/Range/Range';
import FilterSelect from 'components/Filter/Select';
import FilterTextValueContainer from 'components/Filter/TextValueContainer';
import PageContainer from 'components/Page/Container';

import useConfig from 'hooks/useConfig';
import useQueryParams from 'hooks/useQueryParams';

import './Filters.scss';

const Filters = () => {
  const { filters } = useConfig();
  const { queryParams: { filters: activeFilters }, set } = useQueryParams();

  const clubs = filters?.clubs?.map(club => ({ label: club, value: club }));
  const nationalities = filters?.nationality?.map(nat => ({ label: nat, value: nat }));
  const players = filters?.players?.map(player => ({ label: player, value: player }));
  const positions = filters?.position?.map(pos => ({ label: pos, value: pos }));
  const rarities = filters?.rarity;
  const types = filters?.type_of_play?.map(type => ({ label: type, value: type }));

  const hasFilters = Object.keys(filters || {}).length > 0;

  const activeRarity = activeFilters.rarity?.split(',');

  const setFilter = filter => {
    set({
      ...filter,
      page: 1,
    });
  };

  return hasFilters && (
    <PageContainer className="marketplace-filters--lvl2">
      <div className="row row-title">
        <div className="col">
          <h5>Filters</h5>
        </div>
      </div>
      <div className="row">
        { !!players?.length && (
          <div className="col">
            <div className="form-element-container">
              <label htmlFor="selectbox_players">
                Players
              </label>
              <FilterSelect
                components={ {
                  ValueContainer: FilterTextValueContainer,
                } }
                closeMenuOnSelect={ false }
                hideSelectedOptions={ false }
                id="selectbox_players"
                isMulti
                name="players"
                placeholder="All players"
                options={ players }
                onChange={ option => setFilter({ player_name: option.map(e => e.value).join(',') }) }
                value={ players.filter(el => activeFilters.player_name?.split(',').some(player => el.value === player)) || '' }
              />
            </div>
          </div>
        ) }

        { !!positions?.length && (
          <div className="col">
            <div className="form-element-container">
              <label htmlFor="selectbox_positions">
                Positions
              </label>
              <FilterSelect
                id="selectbox_positions"
                name="positions"
                onChange={ option => setFilter({ position: option.value }) }
                options={ [ { label: 'All positions', value: '' }, ...(positions || []) ] }
                value={ positions.find(el => el.value === activeFilters.position) ?? { label: 'All positions', value: '' } }
              />
            </div>
          </div>
        ) }

        { !!clubs.length && (
          <div className="col">
            <div className="form-element-container">
              <label htmlFor="selectbox_clubs">
                Clubs
              </label>
              <FilterSelect
                id="selectbox_clubs"
                name="clubs"
                onChange={ option => setFilter({ club: option.value }) }
                options={ [ { label: 'All clubs', value: '' }, ...(clubs || []) ] }
                value={ clubs.find(el => el.value === activeFilters.club) ?? { label: 'All clubs', value: '' } }
              />
            </div>
          </div>
        ) }

        { !!nationalities.length && (
          <div className="col">
            <div className="form-element-container">
              <label htmlFor="selectbox_clubs">
                Nationalities
              </label>
              <FilterSelect
                id="selectbox_nationalities"
                name="nationalities"
                onChange={ option => setFilter({ nationality: option.value }) }
                options={ [ { label: 'All nationalities', value: '' }, ...(nationalities || []) ] }
                value={ nationalities.find(el => el.value === activeFilters.nationality) ?? { label: 'All nationalities', value: '' } }
              />
            </div>
          </div>
        ) }

        { !!types.length && (
          <div className="col">
            <div className="form-element-container">
              <label htmlFor="selectbox_typesofplay">
                Types of play
              </label>
              <FilterSelect
                id="selectbox_typesofplay"
                name="typesofplay"
                onChange={ option => setFilter({ type_of_play: option.value }) }
                value={ types.find(el => el.value === activeFilters.type_of_play) ?? { label: 'All types', value: '' } }
                options={ [ { label: 'All types', value: '' }, ...(types || []) ] }
              />
            </div>
          </div>
        ) }
      </div>
      <div className="row row-filters-lvl2-2">
        { !!filters?.price_range && (
          <div className="col-3">
            <div className="form-element-container form-element-container--pricerange">
              <label htmlFor="price-range">
                Price range
              </label>
              <FilterRange
                max={ Number(filters.price_range[1]) }
                min={ Number(filters.price_range[0]) }
                value={ activeFilters.price_range?.split('-').map(el => Number(el)) || [ Number(filters.price_range[0]), Number(filters.price_range[1]) ] }
                onAfterChange={ value => setFilter({ price_range: value.join('-') }) }
              />
            </div>
          </div>
        ) }

        { !!rarities?.length && (
          <div className="col-9">
            <div className="form-element-container form-element-container--checkboxes">
              { /* eslint-disable jsx-a11y/label-has-associated-control */ }
              <label>Rarity</label>
              <div>
                { rarities.map(rarity => (
                  <FilterCheckbox
                    key={ `rarity_${rarity}` }
                    active={ activeRarity }
                    onChange={ newFilter => setFilter({ rarity: newFilter.join(',') }) }
                    name={ `rarity_${rarity}` }
                    value={ rarity }
                    className={ `rarity-checkbox rarity-checkbox--${rarity.toLowerCase()}` }
                  />
                )) }
              </div>
            </div>
          </div>
        ) }
      </div>
    </PageContainer>
  );
};

export default Filters;
