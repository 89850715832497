import Image from 'components/Image';
import Metadata from 'components/Metadata';
import PageContainer from 'components/Page/Container';
import PageTitle from 'components/Page/Title';
import TeamMember from 'components/TeamMember';
import TitleLines from 'components/TitleLines/TitleLines';

import { advisors, team } from 'config';

import aboutImage from './assets/about-img.jpg';
import './style.scss';

const About = () => (
  <>
    <Metadata
      description="Learn more about RealFevr, its history, the current team and board of advisors."
      title="RealFevr - About Us"
    />
    <PageTitle>About Us</PageTitle>
    <section className="about--section-intro">
      <PageContainer>
        <div className="row">
          <div className="col">
            <TitleLines size="smaller">RealFevr</TitleLines>
          </div>
        </div>

        <div className="row">
          <div className="col-md-5">
            <Image
              alt="REALFEVR"
              className="about--hero"
              src={ aboutImage }
            />
            <blockquote>
              RealFevr’s NFTs backed by intellectual property will
              remain attractive and with real intrinsic value.
            </blockquote>
          </div>
          <div className="col-md-7">
            <div className="about--intro-content">
              <p>
                RealFevr is a fantasy football app with more than 2.5M downloads, 1.2M already
                registered users and 10 national leagues, 3 international club competitions and all
                the major European team competitions, including the Top-5: Premier League, La Liga,
                Serie A, Bundesliga and Ligue 1.
              </p>
              <blockquote>RealFevr believes in giving back power to users.</blockquote>
              <p>
                While the broader technology and game applications ecosystem has been developing at
                a great pace, we noticed that most of the benefits and rewards are ripped by game
                manufacturers.
              </p>
              <p>
                Despite being an acceptable working model, RealFevr believes in giving back power
                to users.
              </p>
              <p>
                In our case, we empower users with a cryptocurrency and video collectibles that can
                be traded with other peers or used within the wider RealFevr in-game environment.
              </p>
              <p>
                2021 NFTs frenzy mode is going crazy. However we do believe this &quot;bubble&quot;
                will pop-out and only the NFTs backed by intellectual property will remain
                attractive and with real intrinsic value.
              </p>
              <p>
                In RealFevr each athlete becomes an artist and their actions - Goals, Saves,
                Assists, and so on - become pure art in movement.
              </p>
              <p>
                With that said, RealFevr aims at completely disrupting the NFTs ecosystem by
                combining breakthrough tech, blockchain&apos;s decentralization, with the
                uniqueness of video collectibles that empower gamers, providing utility to each
                moment and gamifying it through fantasy token-leagues always backed by official
                intellectual property.
              </p>
            </div>
          </div>
        </div>
      </PageContainer>
    </section>

    <section className="about--section-team">
      <PageContainer>
        <div className="row">
          <div className="col">
            <TitleLines size="smaller">Team</TitleLines>
          </div>
        </div>
      </PageContainer>

      <PageContainer className="about--team">
        <div className="row row--align-items-center">
          { team.map(member => (
            <TeamMember
              key={ member.name }
              avatar={ member.avatar }
              col={ 3 }
              name={ member.name }
              position={ member.position }
              profile={ member.profile }
            />
          )) }
        </div>
      </PageContainer>
    </section>

    <section className="about--section-advisors">
      <PageContainer>
        <div className="row">
          <div className="col">
            <TitleLines color="purple" size="smaller">Advisors</TitleLines>
          </div>
        </div>
      </PageContainer>

      <PageContainer className="about--team">
        <div className="row row--align-items-center">
          { advisors.map(member => (
            <TeamMember
              key={ member.name }
              avatar={ member.avatar }
              col={ 4 }
              name={ member.name }
              position={ member.position }
              profile={ member.profile }
            />
          )) }
        </div>
      </PageContainer>
    </section>
  </>
);

export default About;
