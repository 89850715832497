import bepro from 'lib/bepro';

import fetch from 'utils/fetch';
import getTraits from 'utils/getTraits';
import queryString from 'utils/queryString';

const getResults = endpoint => async params => {
  const paramsStr = queryString(params);
  const result = await fetch(`${endpoint}${paramsStr ? `?${paramsStr}` : ''}`);

  if (result) {
    if (result.data) {
      result.data = result.data.map(item => ({
        ...item,
        traits: getTraits(item.attributes),
      }));
    }
    result.params = params;
  }

  return result;
};

const getPackIds = async ({ quantity, type }) => {
  const response = await fetch(`packs/sample?type=${type}&quantity=${quantity}`);
  return response?.data?.map(({ attributes }) => attributes.uid);
};

const api = {
  getCollectible: async uid => {
    const result = await fetch(`meta/${uid}`);
    const address = await bepro?.getAddress();

    if (result) {
      result.owned = address && address === result.wallet_addr;
      result.traits = getTraits(result);
    }

    return result;
  },

  getConfig: () => fetch('config'),

  getMarketplace: getResults('meta'),

  getPackIds,

  getTopSales: getResults('sales'),

  getUserCollectibles: async params => {
    const address = await bepro?.getAddress();

    if (!params.perPage) {
      params.perPage = 12;
    }

    return getResults(`meta/address/${address}`)(params);
  },

  getUserPacks: async () => {
    const address = await bepro?.getAddress();
    return fetch(`packs/${address}`);
  },

  postContact: data => {
    const params = Object.entries(data)
      .map(([ key, val ]) => `${key}=${decodeURIComponent(Array.isArray(val) ? val.join(',') : val)}`);

    return fetch(`contact?${params.join('&')}`, {
      method: 'POST',
    });
  },
};

export default api;
