import Form from 'components/Form/Form';
import FormSubmit from 'components/Form/Submit';
import FormValidate from 'components/Form/Validate';

import useReCaptcha from 'hooks/useReCaptcha';

import api from 'lib/api';

const subjects = [
  { label: 'Support', value: 'support' },
  { label: '$FEVR', value: 'fevr' },
  { label: 'Premium', value: 'premium' },
  { label: 'Business', value: 'business' },
  { label: 'Careers', value: 'careers' },
  { label: 'Legal', value: 'legal' },
  { label: 'Other', value: 'other' },
];

const FormContact = () => {
  const { execute, ref } = useReCaptcha('recaptcha-container');

  const onSubmit = async values => {
    const token = await execute('submitContact');
    return api.postContact({
      ...values,
      'g-recaptcha-response-data[submitContact]': token,
    });
  };

  return (
    <Form
      className="contacts--form"
      name="contact"
      onSubmit={ onSubmit }
      successMessage="Thank you! Your message has been successfully sent. We will contact you as soon as possible."
    >
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-6">
            <FormValidate
              id="name"
              label="Name"
              placeholder="Enter your first and last name"
              required
            />
          </div>
          <div className="col-md-6">
            <FormValidate
              id="email"
              label="Email"
              placeholder="Enter your email address"
              required
              type="email"
              validity="Email address is invalid."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FormValidate
              id="phone"
              label="Phone"
              placeholder="Enter your phone number"
              required
              type="tel"
            />
          </div>
          <div className="col-md-6">
            <FormValidate
              id="company"
              label="Company"
              placeholder="Enter your company name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <FormValidate
              id="subject"
              label="Subject / Department"
              options={ subjects }
              placeholder="Please select a subject / department"
              required
              type="select"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <FormValidate
              as="textarea"
              id="message"
              label="Message"
              placeholder="Enter your message"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FormSubmit
              className="btn--hover-bg-green"
              label="Send Message"
              size="s"
              sizeMin={ 150 }
              theme="white"
            />
          </div>

          <div className="col-lg-6">
            <div id="recaptcha-container" ref={ ref } />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default FormContact;
