import { func, number } from 'prop-types';
import Pag from 'react-bootstrap/Pagination';

const PaginationItem = ({ onClick, page, ...props }) => (
  <Pag.Item
    onClick={ () => onClick(page) }
    { ...props }
  >
    { page }
  </Pag.Item>
);

PaginationItem.propTypes = {
  onClick: func.isRequired,
  page: number.isRequired,
};

export default PaginationItem;
