import { Helmet } from 'react-helmet-async';

import useConsent from 'hooks/useConsent';
import useHotjar from 'hooks/useHotjar';

const Hotjar = () => {
  const [ consent ] = useConsent('marketing');
  const { hotjarId } = useHotjar();

  return !!hotjarId && consent && (
    <Helmet>
      <script>
        { `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotjarId},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');` }
      </script>
    </Helmet>
  );
};

export default Hotjar;
