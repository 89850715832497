import Modal from 'components/Modal/Modal';
import PackDetails from 'components/Pack/Details/Details';

import useConfig from 'hooks/useConfig';
import useModal from 'hooks/useModal';

import hyphencase from 'utils/hyphencase';

const ModalPack = () => {
  const { close, show } = useModal('pack');
  const { packs_info: packsInfo } = useConfig();

  const pack = show && packsInfo?.packs.find(el => hyphencase(el.pack_type) === show.packType);
  if (show && !pack) {
    close();
  }

  return (
    <Modal
      className="modal-window"
      name="pack"
    >
      { pack && <PackDetails { ...pack } /> }
    </Modal>
  );
};

export default ModalPack;
