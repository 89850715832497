import { Route, Router, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import AppWrapper from 'containers/AppWrapper';
import CookiesBanner from 'components/CookiesBanner/CookiesBanner';
import Header from 'containers/Header/Header';
import Footer from 'containers/Footer/Footer';
import FPixel from 'components/FPixel';
import GTag from 'components/GTag';
import Hotjar from 'components/Hotjar';
import Metadata from 'components/Metadata';
import PackVideo from 'components/PackVideo/PackVideo';
import RequiresWallet from 'components/RequiresWallet';

import useBepro from 'hooks/useBepro';
import useConfig from 'hooks/useConfig';
import usePackVideo from 'hooks/usePackVideo';
import { getStorage } from 'hooks/useStorage';

import About from 'pages/about-us';
import Fevr from 'pages/fevr';
import Contacts from 'pages/contacts';
import Faqs from 'pages/faq';
import CollectiblesSingle from 'pages/collectibles/[uid]';
import FourOhFour from 'pages/404';
import Home from 'pages/home';
import HowToCollect from 'pages/how-to-collect';
import Maintenance from 'pages/maintenance';
import Marketplace from 'pages/marketplace';
import MarketplaceTopSales from 'pages/marketplace/top-sales';
import MyCollection from 'pages/my-collection';
import Packs from 'pages/packs';
import PacksSingle from 'pages/packs/[type]';
import Privacy from 'pages/privacy-policy';
import Terms from 'pages/terms-conditions';
import WITRM from 'pages/what-is-the-marketplace';

import { maintenance, maintenanceToken } from './config';
import history from './history';

const App = () => {
  const { connected } = useBepro();
  const { features } = useConfig();
  const packVideo = usePackVideo();

  // Maintenance Token
  const storage = getStorage('maintenanceToken', false);
  const queryParams = new URLSearchParams(window.location.search);
  const queryKey = queryParams.get('token');
  if (queryKey) {
    storage.set('maintenanceToken', queryKey);
  }

  const token = window && storage.get('maintenanceToken');
  const skipMaintenance = maintenanceToken === token;

  return (
    <Router history={ history }>
      { maintenance && !skipMaintenance && (
        <Maintenance />
      ) }

      { (!maintenance || skipMaintenance) && (
        <HelmetProvider>
          <CookiesBanner />
          <FPixel />
          <GTag />
          <Hotjar />
          <AppWrapper>
            <Metadata
              description="Collect NFTs with the most iconic video moments of your favourite football players."
              title="RealFevr - Everlasting NFT Moments"
            />
            <Header />
            <main id="main-content">
              <PackVideo />

              { !packVideo.show && (
                <Switch>
                  <Route exact path="/" component={ Home } />
                  <Route path="/packs/:packType" component={ PacksSingle } />
                  <Route path="/packs" component={ Packs } />
                  { features?.marketplace_enabled && features?.top_sales_enabled && (
                    <Route path="/marketplace/top-sales" component={ MarketplaceTopSales } />
                  ) }
                  <Route path="/marketplace" component={ Marketplace } />
                  <Route path="/collectibles/:collectibleId" component={ CollectiblesSingle } />
                  <Route path="/what-is-the-marketplace" component={ WITRM } />
                  <Route path="/how-to-collect" component={ HowToCollect } />
                  <Route path="/faq" component={ Faqs } />
                  <Route path="/about-us" component={ About } />
                  <Route path="/fevr" component={ Fevr } />
                  <Route path="/contacts" component={ Contacts } />
                  <Route path="/privacy-policy" component={ Privacy } />
                  <Route path="/terms-conditions" component={ Terms } />
                  { features && typeof connected !== 'undefined' && (
                    <Route path="/my-collection" component={ (!features.packs_opened_enabled || connected) ? MyCollection : RequiresWallet } />
                  ) }
                  { features && typeof connected !== 'undefined' && (
                    <Route component={ FourOhFour } status={ 404 } />
                  ) }
                </Switch>
              ) }
            </main>
            <Footer />
          </AppWrapper>
        </HelmetProvider>
      ) }
    </Router>
  );
};

export default App;
