import { getStorage } from 'hooks/useStorage';

const { REACT_APP_API_ENDPOINT, REACT_APP_CORS_ANYWHERE } = process.env;

const makeToken = async () => {
  const date = new Date().toISOString().split('T')[0];

  const encoder = new TextEncoder();
  const data = encoder.encode(date);
  const buffer = await window.crypto.subtle.digest('SHA-256', data);
  const array = Array.from(new Uint8Array(buffer));
  const hash = array.map(b => b.toString(16).padStart(2, '0')).join('');

  return hash;
};

// Early Access
const storage = getStorage('early-access', false);
const queryParams = new URLSearchParams(window.location.search);
const queryKey = queryParams.get('early_access_token');
if (queryKey) {
  storage.set('accessToken', queryKey);
}

const fetch = async (path, params) => {
  const url = REACT_APP_CORS_ANYWHERE
    ? `${REACT_APP_CORS_ANYWHERE}${decodeURIComponent(`${REACT_APP_API_ENDPOINT}${path}`)}`
    : `${REACT_APP_API_ENDPOINT}${path}`;

  const headers = {
    'Content-Type': 'application/json',
    'X-RealFevr-Token': await makeToken(),
    ...params?.headers,
  };

  if (REACT_APP_CORS_ANYWHERE) {
    headers.Origin = window.location.origin;
  }

  const accessToken = storage.get('accessToken');
  if (accessToken) {
    headers['X-RealFevr-Early-Access-Token'] = accessToken;
  }

  const response = await window.fetch(url, {
    ...params,
    headers,
    referrerPolicy: 'no-referrer',
  });

  if (!params?.method) {
    return response.ok && response.json();
  }

  return response;
};

export default fetch;
