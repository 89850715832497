import { useCallback } from 'react';

import useConfig from 'hooks/useConfig';

const useIpfs = () => {
  const { ipfs_url: ipfsUrl } = useConfig();

  return useCallback(url => url?.replace(/^ipfs:\/\/ipfs\//, ipfsUrl), [ ipfsUrl ]);
};

export default useIpfs;
