import {
  createContext, useCallback, useRef, useState,
} from 'react';
import { func, node, string } from 'prop-types';

import './Form.scss';

export const FormContext = createContext({});

const Form = ({
  children,
  name,
  onSubmit,
  successMessage,
  ...props
}) => {
  const [ failed, setFailed ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  const [ successful, setSuccessful ] = useState(false);

  const events = useRef(new Map());

  const on = useCallback((event, handler) => {
    if (!events.current.has(event)) {
      events.current.set(event, new Set());
    }

    const handlers = events.current.get(event);
    handlers.add(handler);
  }, []);

  const off = useCallback((event, handler) => {
    if (events.current.has(event)) {
      const handlers = events.current.get(event);
      handlers.delete(handler);
    }
  }, []);

  const submit = useCallback(e => {
    e.preventDefault();

    (async () => {
      const submitHandlers = events.current.get('submit') || new Set();
      const fields = [ ...submitHandlers ].map(handler => handler());

      if (!fields.every(field => field && 'value' in field)) {
        return;
      }

      const values = fields.reduce((acc, { id, value }) => ({
        ...acc,
        [id]: value || '',
      }), {});

      setFailed(false);
      setSubmitting(true);
      setSuccessful(false);

      try {
        const result = await onSubmit(values);

        if (!result?.ok) {
          try {
            const json = await result?.json();
            if (json && Object.keys(json).length) {
              const errorHandlers = events.current.get('error') || new Set();
              [ ...errorHandlers ].forEach(handler => handler(json));
            }
            else {
              throw new Error('Form submission failed with no explicit errors');
            }
          }
          catch (ex) {
            // eslint-disable-next-line no-console
            console.error(ex);
            setFailed(true);
          }
        }
        else {
          setSuccessful(true);
        }
      }
      catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }

      setSubmitting(false);
    })();
  }, [ onSubmit ]);

  const value = {
    failed,
    off,
    on,
    setFailed,
    setSubmitting,
    setSuccessful,
    submit,
    submitting,
    successful,
  };

  return (
    <FormContext.Provider
      key={ name }
      value={ value }
    >
      { failed && (
        <div className="form--feedback">
          Something went wrong, please try again or reach out to us via email if the problem
          persists.
        </div>
      ) }

      { successful && (
        <div className="form--feedback">
          { successMessage }
        </div>
      ) }

      <form
        { ...props }
        noValidate
        onSubmit={ submit }
      >
        { children }
      </form>
    </FormContext.Provider>
  );
};

Form.propTypes = {
  children: node,
  name: string.isRequired,
  onSubmit: func.isRequired,
  successMessage: string.isRequired,
};

Form.defaultProps = {
  children: null,
};

export default Form;
