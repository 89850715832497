import { useState, createRef } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

const ShareButton = () => {
  const [ show, setShow ] = useState(false);
  const dropdownMenu = createRef(null);

  const closeMenu = event => {
    const input = dropdownMenu?.current;
    if (input && !input.contains(event.target)) {
      setShow(false);
      document.removeEventListener('click', closeMenu);
    }
  };

  const showMenu = event => {
    event.preventDefault();
    setShow(true);
    document.addEventListener('click', closeMenu);
  };

  return (
    <>
      <button
        className="btn btn--size-l btn--has-icon btn-share"
        type="button"
        onClick={ showMenu }
      >
        Share
      </button>
      { show && (
        <ul
          className="nav-item-submenu"
          ref={ dropdownMenu }
        >
          <li>
            <FacebookShareButton url={ window.location.href }>
              <FacebookIcon size={ 38 } />
            </FacebookShareButton>
          </li>
          <li>
            <TwitterShareButton url={ window.location.href }>
              <TwitterIcon size={ 38 } />
            </TwitterShareButton>
          </li>
          <li>
            <WhatsappShareButton url={ window.location.href }>
              <WhatsappIcon size={ 38 } />
            </WhatsappShareButton>
          </li>
          <li>
            <TelegramShareButton url={ window.location.href }>
              <TelegramIcon size={ 38 } />
            </TelegramShareButton>
          </li>
        </ul>
      ) }
    </>
  );
};

export default ShareButton;
