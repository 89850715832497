import { useRef, useState } from 'react';
import jsonp from 'jsonp';
import { Link } from 'react-router-dom';
import parser from 'html-react-parser';

import Button from 'components/Button/Button';
import TitleLines from 'components/TitleLines/TitleLines';

import useGTag from 'hooks/useGTag';

import './Newsletter.scss';

const getAjaxUrl = (url, params) => `${url.replace('/post?', '/post-json?')}&${params.join('&')}`;

const Newsletter = () => {
  const endpoint = 'https://realfevr.us9.list-manage.com/subscribe/post?u=e95b4c09d54b0ed9982f3e25d&id=43370dfcd9';

  const { conversion } = useGTag();

  const controlRef = useRef();
  const emailRef = useRef();

  const [ success, setSuccess ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ submitting, setSubmitting ] = useState(null);

  const onSubmit = e => {
    e.preventDefault();

    if (submitting || !emailRef.current?.checkValidity()) {
      setError('Please provide a valid email address.');
      return;
    }

    setSubmitting(true);
    setError(false);
    setSuccess(false);

    try {
      const params = [ controlRef, emailRef ]
        .filter(ref => ref.current)
        .map(ref => `${ref.current.name}=${encodeURIComponent(ref.current.value)}`);

      conversion('newsletter');

      jsonp(getAjaxUrl(endpoint, params), { param: 'c' }, (err, data) => {
        setSubmitting(false);
        if (err) {
          setError(err);
        }
        else if (data.result !== 'success') {
          setError(data.msg);
        }
        else {
          setSuccess(data.msg);
        }
      });
    }
    catch (ex) {
      setSubmitting(false);
      setError('Something went wrong, please try again or reach out to us via email if the problem persists.');

      // eslint-disable-next-line no-console
      console.error(ex);
    }
  };

  return (
    <section className="section--newsletter">
      <div className="container">
        <div className="row">
          <div className="col">
            <TitleLines color="purple" size="smaller">Stay up to date</TitleLines>
            <p className="intro-p">Subscribe to our mailing list to stay updated with the latest drops and news.</p>
            <div id="mc_embed_signup">
              <form
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                noValidate
                onSubmit={ onSubmit }
              >
                <div id="mc_embed_signup_scroll">
                  <div className="mc-field-group">
                    <div className="form--elem">
                      <input
                        className="required email"
                        disabled={ submitting }
                        id="mce-EMAIL"
                        name="EMAIL"
                        onChange={ () => setError(null) }
                        placeholder="Enter your email address"
                        ref={ emailRef }
                        required
                        type="email"
                      />
                    </div>
                    <small className="mc_form_terms smaller">
                      Your email address is only used to register in our $FEVR, NFTs and
                      Marketplace newsletter. Learn more about the management of your data and your
                      rights in our
                      { ' ' }
                      <Link to="/privacy-policy/">Privacy Policy</Link>
                      { ' ' }
                      and about our relationship with you in our
                      { ' ' }
                      <Link to="/terms-conditions/">Terms and Conditions</Link>
                    </small>
                  </div>
                  <div id="mce-responses" className="clear">
                    { submitting && (
                      <div className="response">Sending...</div>
                    ) }
                    { !!error && (
                      <div className="response" id="mce-error-response">{ parser(error) }</div>
                    ) }
                    { !!success && (
                      <div className="response" id="mce-success-response">{ parser(success) }</div>
                    ) }
                  </div>
                  { /* real people should not fill this in and expect good things - do not remove
                    this or risk form bot signups */ }
                  <div style={ { position: 'absolute', left: -5000 } } aria-hidden="true">
                    <input
                      disabled={ submitting }
                      name="b_e95b4c09d54b0ed9982f3e25d_43370dfcd9"
                      ref={ controlRef }
                      tabIndex="-1"
                      type="text"
                    />
                  </div>
                  <div className="clear submit-btn-container">
                    <Button
                      disabled={ submitting }
                      id="mc-embedded-subscribe"
                      name="subscribe"
                      onClick={ onSubmit }
                      size="m"
                      theme="purple-gradient"
                      value="Subscribe"
                    >
                      Subscribe
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
