import { useMemo, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import chunk from 'lodash.chunk';
import { debounce } from 'throttle-debounce';

import CollectibleListItem from 'components/Collectible/ListItem/ListItem';
import Filters from 'containers/Filters/Filters';
import FilterSort from 'components/Filter/Sort';
import FilterToggle from 'components/Filter/Toggle';
import Metadata from 'components/Metadata';
import PageTitle from 'components/Page/Title';
import PageContainer from 'components/Page/Container';
import Pagination from 'components/Pagination/Pagination';
import Tabs from 'components/Tabs/Tabs';
import TitleLines from 'components/TitleLines/TitleLines';

import { perPageOptionsMarketplace } from 'config';

import useConfig from 'hooks/useConfig';
import useMarketplace from 'hooks/useMarketplace';
import useQueryParams from 'hooks/useQueryParams';
import WITRM from 'pages/what-is-the-marketplace';

import './style.scss';

const Marketplace = () => {
  const { features, filters } = useConfig();
  const { data, meta } = useMarketplace();
  const { set } = useQueryParams();

  const [ text, setText ] = useState('');
  const searchText = useMemo(() => debounce(500, false, value => {
    set({
      page: 1,
      search: value.length > 2 ? value : '',
    });
  }), [ set ]);

  const hasFilters = Object.keys(filters || {}).length > 0;

  return (
    <>
      <Metadata
        description="Buy and sell the most iconic and everlasting football moments in history."
        title="RealFevr - Marketplace"
      />

      { features?.marketplace_enabled && (
        <PageTitle>
          Marketplace
          { meta?.total_count > 0 ? (
            <small>
              showing
              { ' ' }
              { meta?.total_count }
              { ' ' }
              items
            </small>
          ) : '' }
        </PageTitle>
      ) }

      { features?.top_sales_enabled && (
        <Tabs
          tabs={ [
            { label: 'For Sale', path: '/marketplace' },
            { label: 'Top Sales', path: '/marketplace/top-sales' },
          ] }
        />
      ) }

      { features && !features.marketplace_enabled && (
        <div className="row row-header">
          <div className="col">
            <TitleLines comingSoon>
              Marketplace
            </TitleLines>
          </div>
        </div>
      ) }

      { features && !features.marketplace_enabled && (
        <WITRM />
      ) }

      { features?.marketplace_enabled && (
        <>
          <Accordion>
            <div className="marketplace-filters">
              <PageContainer className="marketplace-filters--lvl1">
                <div className="row">
                  <div className="col">
                    <FilterSort page="marketplace" />
                  </div>
                  <div className="col col-align-center">
                    <div className="form-element-container">
                      <input
                        className="search-txt"
                        name="search"
                        onChange={ event => {
                          setText(event.target.value);
                          searchText(event.target.value);
                        } }
                        placeholder="Search by player name"
                        type="text"
                        value={ text }
                      />
                    </div>
                  </div>

                  { hasFilters && (
                    <div className="col col-align-right">
                      <button
                        className="filters-clear-btn"
                        onClick={ () => set({
                          filters: {},
                          page: 1,
                        }) }
                        type="button"
                      >
                        Clear
                      </button>
                      <FilterToggle eventKey="marketplace_filters" />
                    </div>
                  ) }
                </div>
              </PageContainer>
              <Accordion.Collapse eventKey="marketplace_filters">
                <Filters />
              </Accordion.Collapse>
            </div>
          </Accordion>

          <PageContainer className="marketplace-grid-container">
            { chunk(data || [], 4).map((row, idx) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={ `row-${idx}` }
                className="row row--align-items-center"
              >
                { row.map(item => (
                  <div className="col col-md-3" key={ item.id }>
                    <CollectibleListItem { ...item } />
                  </div>
                )) }
              </div>
            )) }
          </PageContainer>

          <div className="marketplace-bottom-filters">
            <PageContainer>
              <Pagination
                meta={ meta }
                defaultOption={ 24 }
                options={ perPageOptionsMarketplace }
              />
            </PageContainer>
          </div>
        </>
      ) }
    </>
  );
};

export default Marketplace;
