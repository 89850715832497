import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  arrayOf, bool, func, node, shape, string,
} from 'prop-types';
import classnames from 'classnames';

import FilterSelect from 'components/Filter/Select';
import { FormContext } from 'components/Form/Form';

const FormValidate = ({
  as: Comp,
  children,
  id,
  label,
  onValidate,
  options,
  required,
  type,
  validity,
  ...props
}) => {
  const ref = useRef();

  const [ error, setError ] = useState();
  const [ value, setValue ] = useState('');

  const { off, on, successful } = useContext(FormContext);

  useEffect(() => {
    const onError = errors => {
      if (errors[id]?.length) {
        setError(errors[id].join(', '));
      }
    };

    const onSubmit = () => {
      if (required && !value) {
        setError('This field is mandatory.');
        return null;
      }

      if (validity && !ref.current?.checkValidity()) {
        setError(validity || 'Invalid value.');
        return null;
      }

      return { id, value };
    };

    on('error', onError);
    on('submit', onSubmit);

    return () => {
      off('error', onError);
      off('submit', onSubmit);
    };
  }, [ id, off, on, required, validity, value ]);

  useEffect(() => {
    if (successful) {
      setValue('');
    }
  }, [ successful ]);

  return (
    <div
      className={ classnames('form--elem', {
        'form--elem-error': error,
      }) }
    >
      <label htmlFor={ id }>
        { !!label && (
          <div className="form--lbl">
            { label }
            { required && <span className="form--req">*</span> }
          </div>
        ) }

        { type !== 'select' && (
          <Comp
            { ...props }
            key={ id }
            id={ id }
            name={ id }
            onChange={ e => {
              setError();
              setValue(e.target.value);
            } }
            ref={ ref }
            required={ required }
            type={ type }
            value={ value }
          />
        ) }

        { type === 'select' && (
          <FilterSelect
            { ...props }
            key={ id }
            id={ id }
            name={ id }
            onChange={ e => {
              setError();
              setValue(e.value);
            } }
            options={ options }
            value={ options.find(el => el.value === value) || '' }
          />
        ) }

        { !!error && (
          <div className="form-elem--error-desc">{ error }</div>
        ) }
      </label>
    </div>
  );
};

FormValidate.propTypes = {
  as: string,
  children: node,
  id: string.isRequired,
  label: string,
  onValidate: func,
  options: arrayOf(shape({
    label: string,
    value: string,
  })),
  required: bool,
  type: string,
  validity: string,
};

FormValidate.defaultProps = {
  as: 'input',
  children: null,
  label: '',
  onValidate: () => {},
  options: null,
  required: false,
  type: 'text',
  validity: '',
};

export default FormValidate;
