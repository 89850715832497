import { shape, string } from 'prop-types';
import { useHistory } from 'react-router-dom';

import CollectibleDetails from 'components/Collectible/Details/Details';
import Metadata from 'components/Metadata';
import PageContainer from 'components/Page/Container';
import PageTitle from 'components/Page/Title';
import ReturnLink from 'components/ReturnLink/ReturnLink';

import useCollectible from 'hooks/useCollectible';

import './[uid].scss';

const CollectiblesSingle = ({ match, location }) => {
  const { collectibleId } = match?.params || {};
  const { collectible } = useCollectible(collectibleId);

  const { goBack } = useHistory();

  let backText = '‹ Return';
  if (location?.state?.prevPath?.startsWith('/marketplace')) {
    backText += ' to marketplace';
  }
  else if (location?.state?.prevPath?.startsWith('/my-collection')) {
    backText += ' to my collection';
  }

  return !!collectible && (
    <>
      <Metadata
        description="Each video collectible contains everlasting football moments you can own."
        title="RealFevr - Collectible"
      />
      <PageContainer>
        <PageTitle>Collectible</PageTitle>
      </PageContainer>

      <CollectibleDetails { ...collectible } />

      <ReturnLink
        label={ backText }
        onClick={ goBack }
      />
    </>
  );
};

CollectiblesSingle.propTypes = {
  location: shape({
    state: shape({
      prevPath: string,
    }),
  }),
  match: shape({
    params: shape({
      collectibleId: string,
    }),
  }),
};

CollectiblesSingle.defaultProps = {
  location: null,
  match: null,
};

export default CollectiblesSingle;
