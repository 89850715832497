import { isPromisePending } from 'promise-status-async';

import { add as addError } from 'hooks/useErrors';

import api from 'lib/api';

import resolveProperty from 'utils/resolveProperty';

const offloaded = resolveProperty(window, '_realfevr_beprojs');
const resolve = {};

const bepro = window.ethereum && new Promise(res => {
  resolve.bepro = res;
});

const params = new Promise(res => {
  resolve.params = res;
});

const getAddress = async () => {
  const app = await bepro;
  return app?.getAddress();
};

// This fully connects the site to MetaMask. Can trigger UI for account confirmation, so call
// only on user action when needed.
const login = async () => {
  const app = await bepro;
  if (app) {
    window.ethereum.enable();
  }
  return app;
};

const getOpenerContract = async () => {
  const app = await login();
  const { openerAddress: contractAddress, tokenAddress } = await params;
  // eslint-disable-next-line new-cap
  const opener = app && new app.getOpenRealFvrContract({ tokenAddress, contractAddress });
  await opener?.__assert();
  return opener;
};

const approveContract = async contractAddress => {
  const app = await login();

  const opener = await getOpenerContract();
  const amount = await opener?.getERC20Contract().totalSupply();
  const address = await app?.getAddress();

  const isApproved = await opener?.getERC20Contract().isApproved({
    address,
    amount,
    spenderAddress: contractAddress,
  });
  if (!isApproved) {
    try {
      await opener?.getERC20Contract().approve({
        address: contractAddress,
        amount,
      });
    }
    catch (ex) {
      // eslint-disable-next-line no-console
      console.error(ex);
      switch (ex.code) {
        case 4001:
          addError('not approved', true);
          break;
        default:
          addError('failed to approve');
          break;
      }
      return null;
    }
  }
  return opener;
};

const buyPacks = async ({ quantity, type }) => {
  const packIds = await api.getPackIds({ quantity, type });
  if (packIds === undefined) {
    addError('no packs available', true);
    return null;
  }

  const { openerAddress } = await params;
  const opener = await approveContract(openerAddress);
  if (!opener) {
    return null;
  }

  try {
    const result = await opener?.buyPacks({ packIds });
    if (!result) {
      addError('failed to buy packs');
      return null;
    }

    return {
      packIds,
      ...result,
    };
  }
  catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
    switch (ex.code) {
      case 4001:
        addError('purchase cancelled', true);
        break;
      default:
        addError('failed to buy pack');
        break;
    }

    return null;
  }
};

const openPacks = async packIds => {
  const opener = await getOpenerContract();
  try {
    const result = await opener?.openPacks({ packIds });
    if (!result) {
      addError('failed to open packs');
    }
    return result;
  }
  catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
    switch (ex.code) {
      case 4001:
        addError('open pack cancelled', true);
        break;
      default:
        addError('failed to open packs');
        break;
    }

    return null;
  }
};

const mint = async tokenId => {
  const opener = await getOpenerContract();
  try {
    const result = await opener?.mint({ tokenId });
    if (!result) {
      addError('failed to mint nft');
    }
    return result;
  }
  catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
    switch (ex.code) {
      case 4001:
        addError('mint nft cancelled', true);
        break;
      default:
        addError('failed to mint nft');
        break;
    }

    return null;
  }
};

const getMarketplaceContract = async () => {
  const app = await login();
  const { marketplaceAddress: contractAddress, marketplaceV1Address, tokenAddress } = await params;
  // eslint-disable-next-line new-cap
  const marketplace = app && new app.getMarketplaceRealFvrContract({
    tokenAddress, contractAddress,
  });
  await marketplace?.__assert();
  // eslint-disable-next-line new-cap
  const marketplaceV1 = app && new app.getMarketplaceRealFvrContract({
    tokenAddress, contractAddress: marketplaceV1Address,
  });
  await marketplaceV1?.__assert();
  return {
    marketplace,
    marketplaceV1,
  };
};

const approveMarketplace = async () => {
  const app = await login();
  const { marketplaceAddress, openerAddress } = await params;

  // eslint-disable-next-line new-cap
  const erc721Contract = new app.getERC721Contract({ contractAddress: openerAddress });
  await erc721Contract.__assert();
  const address = await app?.getAddress();

  try {
    if (!await erc721Contract.isApprovedForAll({
      from: address,
      to: marketplaceAddress,
    })) {
      await erc721Contract.setApprovalForAll({ to: marketplaceAddress });
    }
  }
  catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
    switch (ex.code) {
      case 4001:
        addError('not approved marketplace', true);
        break;
      default:
        addError('failed to approve marketplace');
        break;
    }
    return null;
  }

  return erc721Contract;
};

const sell = async ({ price, tokenId }) => {
  if (!await approveMarketplace()) {
    return null;
  }

  const { marketplace } = await getMarketplaceContract();

  try {
    const result = await marketplace?.putERC721OnSale({ price, tokenId });
    if (!result) {
      addError('failed to sell nft');
    }
    return result;
  }
  catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
    switch (ex.code) {
      case 4001:
        addError('sell nft cancelled', true);
        break;
      default:
        addError('failed to sell nft');
        break;
    }

    return null;
  }
};

const cancelSale = async ({ tokenId, isV1 }) => {
  const { marketplace, marketplaceV1 } = await getMarketplaceContract();
  const finalMarketplace = isV1 ? marketplaceV1 : marketplace;

  try {
    const result = await finalMarketplace?.removeERC721FromSale({ tokenId });
    if (!result) {
      addError('failed to cancel sale');
    }
    return result;
  }
  catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
    switch (ex.code) {
      case 4001:
        addError('cancel sale cancelled', true);
        break;
      default:
        addError('failed to cancel sale');
        break;
    }

    return null;
  }
};

const buy = async ({ price, tokenId, isV1 }) => {
  // const { marketplaceAddress } = await params;
  // if (!await approveContract(marketplaceAddress)) {
  //   return null;
  // }

  const { marketplace, marketplaceV1 } = await getMarketplaceContract();
  const finalMarketplace = isV1 ? marketplaceV1 : marketplace;
  if (!finalMarketplace) {
    return null;
  }

  try {
    const result = await finalMarketplace?.buyERC721({
      tokenId,
      value: Number(price),
    });
    if (!result) {
      addError('failed to buy nft');
    }
    return result;
  }
  catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
    switch (ex.code) {
      case 4001:
        addError('buy nft cancelled', true);
        break;
      default:
        addError('failed to buy nft');
        break;
    }

    return null;
  }
};

export default {
  approveMarketplace,
  buy,
  buyPacks,
  cancelSale,
  getAddress,
  getMarketplaceContract,
  getOpenerContract,
  login,
  mint,
  openPacks,
  sell,

  getAccounts: async () => {
    const app = await bepro;
    return app?.web3?.eth.getAccounts();
  },

  getBalance: async () => {
    const address = await getAddress();
    const opener = await getOpenerContract();
    const amount = await opener?.getERC20Contract().getTokenAmount(address);
    return amount ? parseFloat(amount) : 0;
  },

  getBNBBalance: async () => {
    const app = await bepro;
    const amount = await app?.getETHBalance();
    return amount ? parseFloat(amount) : 0;
  },

  getNetwork: async () => {
    const app = await bepro;
    return app?.getETHNetwork();
  },

  initialize: async ({
    marketplaceAddress, marketplaceV1Address, openerAddress, tokenAddress, web3Connection,
  }) => {
    if (!web3Connection) {
      return;
    }

    // Params that are fetched from config, to be used to initalize contracts and connections.
    resolve.params({
      marketplaceAddress, marketplaceV1Address, openerAddress, tokenAddress, web3Connection,
    });

    const { Application, Web3 } = await offloaded;

    if (!bepro || !await isPromisePending(bepro)) {
      return;
    }

    const app = new Application({
      opt: { web3Connection },
    });

    // First half of login, lets us check for existing auth without triggering MetaMask UI.
    if (app) {
      window.web3 = new Web3(window.ethereum);
      app.web3 = window.web3;
    }

    resolve.bepro(app);
  },

  injected: async () => !!await bepro,

  on: (event, handler) => {
    window.ethereum?.on(event, handler);
  },
};
