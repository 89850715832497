/* eslint-disable max-len, react/no-unescaped-entities */

import Metadata from 'components/Metadata';
import PageContainer from 'components/Page/Container';
import PageTitle from 'components/Page/Title';

import './style.scss';

const Terms = () => (
  <>
    <Metadata
      description="Terms and Conditions of use."
      title="RealFevr - Terms and Conditions"
    />
    <PageTitle>Terms and Conditions</PageTitle>
    <PageContainer className="container--legal-body">
      <div className="row">
        <div className="col">
          <p>
            Real Fevr Marketplace is a platform that provides users with the opportunity to purchase, collect, and showcase digital blockchain collectables containing exclusive content from the Partners (
            <strong>the "Partners"</strong>
            ) and its former players moments (
            <strong>collectively, the "App"</strong>
            ).
            <strong>Fantasy Revolution S.A. ("we" or "us")</strong>
            { ' ' }
            is making the App available to you. Before you use the App, however, you will need to agree to these Terms of Use and any terms and conditions incorporated herein by reference (
            <strong>collectively, these "Terms"</strong>
            ).
          </p>

          <p>PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP. THESE TERMS GOVERN YOUR USE OF THE APP UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY WILLING TO MAKE THE APP AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. THEREFORE, BY USING THE APP OR ANY PART OF IT, OR BY CLICKING "I ACCEPT" BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY'S BEHALF, IN WHICH CASE "YOU" WILL MEAN THAT ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE TERMS, THEN WE ARE UNWILLING TO MAKE THE APP AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS OR USE THE APP.</p>

          <p>
            THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION (SEE SECTION 15).  PLEASE REVIEW THE ARBITRATION PROVISION CAREFULLY SINCE IT AFFECTS YOUR RIGHTS.  BY USING THE APP OR ANY PART OF IT, OR BY CLICKING "I ACCEPT" BELOW OR INDICATING YOUR ACCEPTANCE IN AN ADJOINING BOX, YOU UNDERSTAND AND AGREE TO BE BOUND BY THE ARBITRATION PROVISION.
            ANY PURCHASE OR SALE YOU MAKE, ACCEPT OR FACILITATE OUTSIDE OF THIS APP (AS DEFINED BELOW) OF A MOMENT (AS DEFINED BELOW) WILL BE ENTIRELY AT YOUR RISK. WE DO NOT CONTROL OR ENDORSE PURCHASES OR SALES OF MOMENTS OUTSIDE OF THIS APP. WE EXPRESSLY DENY ANY OBLIGATION TO INDEMNIFY YOU OR HOLD YOU HARMLESS FOR ANY LOSSES YOU MAY INCUR BY TRANSACTING, OR FACILITATING TRANSACTIONS, IN MOMENTS OUTSIDE OF THIS APP.
          </p>

          <p>
            This document contains essential information regarding your rights and obligations, as well as conditions, limitations and exclusions that might apply to you. Please read it carefully.
            Any changes to these Terms will be in effect as of the "Last Updated Date" referred to at the top of this page. Therefore, you should review these Terms before using the App or purchasing any product or using any available services through this App.
          </p>

          <p>
            Your continued use of this App after the "Last Updated Date" will constitute your acceptance of and agreement to such changes.
            By using this App, you affirm that you are of legal age to enter into these Terms, and you accept and are bound by these Terms. Further, you declare that if you are using this App on behalf of an organization or company, you have the legal authority to bind any such organization or company to these Terms.
          </p>
          <p>You may not use this App if you:</p>
          <ol>
            <li>do not agree to these Terms;</li>
            <li>are not of the age of majority in your jurisdiction of residence; or</li>
            <li>are prohibited from accessing or using this App or any of this App's contents, products or services by applicable law.</li>
          </ol>

          <h3>1. USE OF THE APP; ACCOUNT SET-UP AND SECURITY</h3>
          <ol>
            <li>
              <h4>Account and Wallet Set-Up</h4>
              { ' ' }
              <p>To most efficiently use the App, you should first install a web browser (such as the Google Chrome web browser).  You will also need to use Metamask or another supported electronic wallet, which will enable you to purchase and store collectables that you collect or purchase via the App.  Each collectable is a Non-Fungible Token (an "NFT") on the Binance Smart Chain blockchain network (the "BSC").</p>
            </li>
            <li>
              <h4>Account Registration</h4>
              { ' ' }
              <p>You must provide accurate and complete registration information when you create an account for the App. By creating an account, you agree to provide accurate, current and complete account information about yourself and to maintain and promptly update your account information as necessary.  We reserve the right to reclaim usernames without liability to you.</p>
            </li>
            <li>
              <h4>Account Security</h4>
              { ' ' }
              <p>
                You are responsible for the security of your account for the App and your electronic wallets. If you become aware of any unauthorized use of your password or your account with us, you agree to notify us immediately at
                { ' ' }
                <a href="mailto:info@realfevr.com">info@realfevr.com</a>
              </p>
            </li>
            <li>
              <h4>Account Transactions</h4>
              { ' ' }
              <p>You can use your electronic wallet to purchase, store, and engage in transactions using your credit card or via one or more cryptocurrencies that we may elect to accept from time to time. Transactions that take place on the App are managed and confirmed via the BSC.  You understand that your BSC public address will be made publicly visible whenever you engage in a transaction on the App.</p>
            </li>
          </ol>

          <h3>2. PURCHASING AND EARNING YOUR MOMENTS</h3>
          <ol>
            <li>
              <h4>Acquiring Moments</h4>
              { ' ' }
              <p>The App allows you to purchase, earn, collect and showcase moments (each, a "Moment") from the Partners. Each Moment is an NFT on the BSC.</p>
            </li>
            <li>
              <h4>Purchasing Moments</h4>
              { ' ' }
              <p>You can purchase Moments in two ways: (a) by buying packs of Moments from us on the App (each, a "Pack"); or (b) by buying Moments from other users in the App's marketplace (the "Marketplace"). There are different types of Packs available for purchase on the App, and we reserve the right to modify the types, prices and numbers of Packs available at our discretion. Depending on the type of Pack you buy, you will collect Moments of varying levels of scarcity.  Before you buy a Pack, we will let you know the types of Moments (but not the exact Moments) contained in that Pack. If you buy an individual Moment from another user in the Marketplace, you will know the exact Moment that you are purchasing. We strongly encourage you not to purchase Moments other than in Packs or on the Marketplace.  If you decide to purchase Moments in any other way, you understand that such purchases will be entirely at your sole risk.</p>
            </li>
            <li>
              <h4>Earning Moments</h4>
              { ' ' }
              <p>You can earn Moments for free by participating in certain challenges or marketing campaigns on the App or completing specific in-App tasks that we may make generally available from time to time.</p>
            </li>
            <li>
              <h4>Characteristics of Moments</h4>
              { ' ' }
              <p>Moments are videos of one or more football players.  Each Moment has a defined set of attributes – including scarcity – which help determine the value of the Moment.</p>
            </li>
            <li>
              <h4>Subjectivity of Moments</h4>
              { ' ' }
              <p>The value of each Moment is inherently subjective; in the same way, the value of other collectables is intrinsically subjective. Each Moment has no inherent or intrinsic value. Some collectors might prefer a Moment featuring a certain player, while another might prefer an equivalent Moment featuring a different player.  Each player can have more than one Moment associated with them, and those Moments will each have different characteristics.</p>
            </li>
            <li>
              <h4>Inventory Moments</h4>
              { ' ' }
              <p>The "Inventory" feature of the App allows to you organize your Moments into collections and show them to your friends.</p>
            </li>
          </ol>

          <h3>3. PAYMENT, GAS FEES, AND TAXES</h3>
          <ol>
            <li>Financial Transactions on App. Any payments or financial transactions you engage in via the App will be conducted solely through the BSC. We have no control over these payments or transactions, nor do we have the ability to reverse any payments or transactions. We have no liability to you or any third party for any claims or damages that may arise due to any payments or transactions you engage in via the App or any other payment or transactions you conduct via the BSC. We do not provide refunds for any purchases that you might make on or through the App – whether for Moments, Packs, or anything else.</li>
            <li>Gas Fees. Every transaction on the BSC requires the payment of a transaction fee (each, a "Gas Fee"). The Gas Fees fund the network of computers that run the decentralized BSC. As a result, you will need to pay a Gas Fee for each transaction you instigate via the App. Except as otherwise expressly outlined in these Terms, you will be solely responsible for paying any Gas Fee for any transaction that you instigate via the App.</li>
            <li>Responsibility for Taxes. You will be solely responsible for paying any sales, use, value-added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority (collectively, the "Taxes") associated with your use of the App. Except for income taxes levied on us, you: (a) will pay or reimburse us for all taxes and assessments of any jurisdiction, including value-added taxes and taxes as required by international tax treaties, customs or other import or export taxes, and amounts levied in lieu thereof based on charges set, services performed or payments made hereunder, as are now or hereafter may be imposed under the authority of any national, state, local or any other taxing jurisdiction; and (b) will not be entitled to deduct the amount of any such taxes, duties or assessments from payments (including Gas Fees) made to us according to these Terms.</li>
          </ol>

          <h3>4. OWNERSHIP, LICENSE, AND OWNERSHIP RESTRICTIONS</h3>
          <p>YOUR OWNERSHIP OF MOMENTS WILL ONLY BE RECOGNIZED BY US IF YOU HAVE PURCHASED OR OTHERWISE RIGHTFULLY ACQUIRED SUCH MOMENTS FROM A LEGITIMATE SOURCE AND NOT THROUGH ANY OF THE CATEGORY B PROHIBITED ACTIVITIES (AS DEFINED BELOW).</p>

          <p>For the purposes of this Section 4, the following capitalized terms will have the following meanings:</p>

          <p>
            <strong>"Art"</strong>
            { ' ' }
            means any art, design, and drawings (in any form or media, including, without limitation, video or photographs) that may be associated with a Moment that you Own.
          </p>
          <p>
            <strong>"Own"</strong>
            { ' ' }
            means, concerning a Moment, a Moment that you have purchased or otherwise rightfully acquired from a legitimate source (and not through any of the Category B Prohibited Activities (as defined below)), where proof of such purchase is recorded on the BSC.
          </p>
          <p>
            <strong>"Purchased Moment"</strong>
            { ' ' }
            means a Moment that you Own.
          </p>
          <p>
            <strong>"Third Party IP"</strong>
            { ' ' }
            means any third-party patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognized in any country or jurisdiction in the world.
          </p>

          <ol>
            <li>
              <h4>Ownership of Moment</h4>
              { ' ' }
              <p>Because each Moment is an NFT on the BSC,  when you purchase a Moment under these Terms (and not through any of the Category B Prohibited Activities), the NFT will be exclusively owned by you. This means that you have the right to swap your Moment, sell it, or give it away. Ownership of the Moment is mediated entirely by the BSC. Except as otherwise permitted by these Terms in cases where we determine that the Moment has not been rightfully acquired from a legitimate source (including, without limitation, through any of the Category B Prohibited Activities), at no point will we seize, freeze, or otherwise modify the ownership of any Moment.</p>
            </li>
            <li>
              <h4>We Own the App</h4>
              { ' ' }
              <p>You acknowledge and agree that we (or, as applicable, our licensors) owns all legal right, title and interest in and to all other elements of the App and all intellectual property rights therein (including, without limitation, all Art, designs, systems, methods, information, computer code, software, services, "look and feel", organization, a compilation of the content, code, data, and all other elements of the App (collectively, the "App Materials")).  You acknowledge that the App Materials are protected by copyright, trade dress, patent, trademark laws, international conventions, other relevant intellectual property and proprietary rights, and applicable laws. All App Materials are the copyrighted property of us or our licensors, and all trademarks, service marks, and trade names associated with the App or otherwise contained in the App Materials are proprietary to us or our licensors.</p>
            </li>
            <li>
              <h4>No User License or Ownership of App Materials</h4>
              { ' ' }
              <p>Except as expressly set forth herein, your use of the App does not grant you ownership of any other rights concerning any content, code, data, or other App Materials that you may access on or through the App. We reserve all rights in and to the App Materials that are not expressly granted to you in these Terms.</p>
            </li>
            <li>
              <h4>Further User Ownership Acknowledgements</h4>
              { ' ' }
              <p>For the sake of clarity, you understand and agree: (a) that your purchase of a Moment, whether via the App or otherwise, does not give you any rights or licenses in or to the App Materials (including, without limitation, our copyright in and to the associated Art) other than those expressly contained in these Terms; (b) that you do not have the right, except as otherwise outlined in these Terms, to reproduce, distribute, or otherwise commercialize any elements of the App Materials (including, without limitation, any Art) without our prior written consent in each case, which consent we may withhold in our sole and absolute discretion; and (c) that you will not apply for, register, or otherwise use or attempt to use any of our trademarks or service marks, or any confusingly similar marks, anywhere in the world without our prior written consent in each case, which consent we may withhold at our sole and absolute discretion.</p>
            </li>
            <li>
              <h4>User License to Art</h4>
              { ' ' }
              <p>Subject to your continued compliance with these Terms, we grant you a worldwide, non-exclusive, non-transferable, royalty-free license to use, and display the Art for your purchased Moments, solely for the following purposes: (a) for your own personal, non-commercial use; (b) as part of a marketplace that permits the purchase and sale of your purchased Moments, provided that the marketplace cryptographically verifies each Moment owner's rights to display the Art for their purchased Moment to ensure that only the actual owner can display the Art; or (c) as part of a third-party website or application that permits the inclusion, involvement, or participation of your Purchased Moment, provided that the website/application cryptographically verifies each Moment's owner's rights to display the Art for their purchased Moment to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the purchased Moment leaves the website/application.</p>
            </li>
            <li>
              <h4>Restrictions on Ownership</h4>
              { ' ' }
              <p>You agree that you may not, nor permit any third party to do or attempt to do any of the foregoing without our (or, as applicable, our licensors') express prior written consent in each case: (a) modify the Art for your purchased Moment in any way, including, without limitation, the shapes, designs, drawings, attributes, or color schemes; (b) use the Art for your purchased Moment to advertise, market, or sell any third party product or service; (c) use the Art for your purchased Moment in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others; (d) use the Art for your purchased Moment in movies, videos, or any other forms of media, except to the limited extent that such use is expressly permitted in these Terms or solely for your own personal, non-commercial use; (e) sell, distribute for commercial gain (including, without limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the Art for your purchased Moment; (f) attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art for your purchased Moment; or (g) otherwise utilize the Art for your purchased Moment for your or any third party's commercial benefit.</p>
            </li>
            <li>
              <h4>Third Party IP</h4>
              { ' ' }
              <p>Suppose the Art associated with your Purchased Moment contains Third Party IP (e.g., licensed intellectual property from the Partners or a player). In that case, you understand and agree as follows: (a) that you will not have the right to use such Third Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained herein; (b) that, depending on the nature of the license granted from the owner of the Third Party IP, we may need to (and reserve every right to) pass through additional restrictions on your ability to use the Art, and (c) to the extent that we inform you of such additional restrictions in writing, you will be responsible for complying with all such restrictions from the date that you receive the notice, and that failure to do so will be deemed a breach of the license contained in this Section 4.</p>
            </li>
            <li>
              <h4>Other Terms of License</h4>
              { ' ' }
              <p>The license granted in Section 4(v) above applies only to the extent that you continue to Own the applicable purchased Moment. If at any time you sell, swap, donate, give away, transfer, or otherwise dispose of your purchased Moment for any reason, the license granted in Section 4(v) will immediately expire concerning that Moment without the requirement of notice, and you will have no further rights in or to the Art for that Moment. The restrictions in Sections 4(vii) and 4(viii) will survive the expiration or termination of these Terms.</p>
            </li>
            <li>
              <h4>User Feedback</h4>
              { ' ' }
              <p>You may choose to submit comments, bug reports, ideas or other feedback about the App, including without limitation about improving the App (collectively, "Feedback"). By submitting any Feedback, you agree that we are free to use such Feedback at our discretion and without additional compensation to you and disclose such Feedback to third parties (whether on a non-confidential basis or otherwise). You hereby grant us a perpetual, irrevocable, nonexclusive, worldwide license under all rights necessary for us to incorporate and use your Feedback for any purpose.</p>
            </li>
          </ol>

          <h3>5. CONDITIONS OF USE AND PROHIBITED ACTIVITIES</h3>
          <p>YOU AGREE THAT YOU ARE RESPONSIBLE FOR YOUR OWN CONDUCT WHILE ACCESSING OR USING THE APP AND FOR ANY CONSEQUENCES THEREOF. YOU AGREE TO USE THE APP ONLY FOR PURPOSES THAT ARE LEGAL, PROPER, PER THESE TERMS AND ANY APPLICABLE LAWS OR REGULATIONS.</p>

          <h4>User Warranties</h4>
          <p>Without limiting the foregoing, you warrant and agree that your use of the App will not (and will not allow any third party to):</p>

          <ol>
            <li>
              <strong>In any manner, involve:</strong>
              <ol>
                <li>the sending, uploading, distributing or disseminating any unlawful, defamatory, harassing, abusive, fraudulent, obscene, or otherwise objectionable content;</li>
                <li>the distribution of any viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature;</li>
                <li>the uploading, posting, transmitting or otherwise making available through the App any content that infringes the proprietary intellectual property rights of any party;</li>
                <li>using the App to violate the legal rights (such as rights of privacy and publicity) of others;</li>
                <li>engaging in, promoting, or encouraging illegal activity (including, without limitation, money laundering);</li>
                <li>interfering with other user's enjoyment of the App;</li>
                <li>exploiting the App for any unauthorized commercial purpose;</li>
                <li>modifying, adapting, translating, or reverse engineering any portion of the App;</li>
                <li>removing any copyright, trademark or other proprietary rights notices contained in or on the App or any part of it;</li>
                <li>reformatting or framing any portion of the App;</li>
                <li>displaying any content on the App that contains any hate-related or violent content or contains any other material, products or services that violate or encourage conduct that would violate any criminal laws, any other applicable laws, or any third party rights;</li>
                <li>using any spider, site search/retrieval application, or other devices to retrieve or index any portion of the App or the content posted on the App, or to collect information about its users for any unauthorized purpose;</li>
                <li>accessing or using the App to create a product or service that is competitive with any of our products or services;</li>
                <li>abusing, harassing, or threatening another user of the App or any of our authorized representatives, customer service personnel, chat board moderators, or volunteers (including, without limitation, filing support tickets with false information, sending excessive emails or support tickets, obstructing our employees from doing their jobs, refusing to follow the instructions of our employees, or publicly disparaging us by implying favouritism by our employees or otherwise); or</li>
                <li>
                  using any abusive, defamatory, ethnically or racially offensive, harassing, harmful, hateful, obscene, offensive, sexually explicit, threatening or vulgar language when communicating with another user of the App or any of our authorized representatives, customer service personnel, chat board moderators, or volunteers
                  (each, a "Category A Prohibited Activity"); or
                </li>
              </ol>
            </li>
            <li style={ { marginTop: 40 } }>
              <strong>In any manner, involve:</strong>
              <ol>
                <li>creating user accounts by automated means or under false or fraudulent pretences;</li>
                <li>the impersonation of another person (via the use of an email address or otherwise);</li>
                <li>using, employing, operating, or creating a computer program to simulate the human behaviour of a user ("Bots");</li>
                <li>using, employing, or operating Bots or other similar forms of automation to engage in any activity or transaction on the App (including, without limitation, purchases of Packs or Moments on the Marketplace);</li>
                <li>acquiring Moments through inappropriate or illegal means (including, among other things, using a stolen credit card, or a payment mechanism that you do not have the right to use, or purchasing a Moment and then attempting to charge the cost back to your payment method while still maintaining ownership or control of the Moment or selling, gifting or trading the Moment to someone else); or</li>
                <li>the purchasing, selling or facilitating the purchase and sale of any user's account(s) to other users or third parties for cash or cryptocurrency consideration outside of the App; or</li>
                <li>
                  otherwise involve or result in the wrongful seizure or receipt of any Moments or other digital assets
                  (each, a "Category B Prohibited Activity" and, together with Category A Prohibited Activity, the "Prohibited Activities").
                </li>
              </ol>
            </li>
          </ol>

          <br />
          <br />

          <h4>Effect of Your Breaches</h4>
          <p>If you engage in any of the Prohibited Activities, we may, at our sole and absolute discretion, without notice or liability to you, and without limiting any of our other rights or remedies at law or in equity, immediately suspend or terminate your user account or delete your Moments' images and descriptions from the App. If we delete your Moments' images and descriptions from the App, such deletion will not affect your ownership rights in any NFTs that you already Own, but you will not receive a refund of any amounts you paid for those Moments.</p>

          <p>NOTWITHSTANDING THE FOREGOING, HOWEVER, IF WE REASONABLY BELIEVE THAT YOU ARE ENGAGED IN ANY OF THE CATEGORY B PROHIBITED ACTIVITIES, IN ADDITION TO OUR RIGHT TO IMMEDIATELY SUSPEND OR TERMINATE YOUR USER ACCOUNT AND DELETE YOUR MOMENTS' IMAGES AND DESCRIPTIONS FROM THE APP, WE ALSO RESERVE THE RIGHT, AT OUR SOLE AND ABSOLUTE DISCRETION, WITHOUT NOTICE OR LIABILITY TO YOU, TO TAKE ANY OR ALL OF THE FOLLOWING ACTIONS: (A) TO DEEM ANY TRANSACTION THAT TOOK PLACE VIA OR AS THE RESULT OF SUCH ACTIVITIES TO BE VOID AB INITIO, AND (B) TO IMMEDIATELY CONFISCATE ANY MOMENTS (INCLUDING THEIR UNDERLYING NFTS) THAT WERE PURCHASED OR ACQUIRED AS THE RESULT OF SUCH ACTIVITIES.</p>

          <h3>6. TERMINATION</h3>
          <ol>
            <li>
              <h4>You Terminate</h4>
              { ' ' }
              <p>You may terminate these Terms at any time by cancelling your account on the App and discontinuing your access to and use of the App. However, if you cancel your account or otherwise terminate these Terms, you will not receive any refunds for any purchases you might make on or through the App – whether for Moments, Packs, or anything else.</p>
            </li>
            <li>
              <h4>We Terminate</h4>
              { ' ' }
              <p>You agree that we, in our sole discretion and for any or no reason, may terminate these Terms and suspend and terminate your account(s) for the App without the provision of prior notice. Further, you agree that any suspension or termination of your access to the App may be without prior notice and that we will not be liable to you or any third party for any such suspension or termination.</p>
            </li>
            <li>
              <h4>Other Remedies Available</h4>
              { ' ' }
              <p>If we terminate these Terms or suspend or terminate your access to or use of the App due to your breach of these Terms or any suspected fraudulent, abusive, or illegal activity (including, without limitation, if you engage in any of the Prohibited Activities), then termination of these Terms will be in addition to any other remedies we may have at law or in equity.</p>
            </li>
            <li>
              <h4>Referral to Governmental Authority</h4>
              { ' ' }
              <p>Without the provision of prior notice, we have the right to take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority or notifying the harmed party of any illegal or unauthorized use of the App. In addition, without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone using the App.</p>
            </li>
            <li>
              <h4>Effect of Termination</h4>
              { ' ' }
              <p>Upon any termination or expiration of these Terms, whether by you or us, you may no longer have access to information that you have posted on the App or that is related to your account, and you acknowledge that we will have no obligation to maintain any such information in our databases or to forward any such information to you or any third party. However, sections 1 and 3 through 17 will survive the termination or expiration of these Terms for any reason.</p>
            </li>
          </ol>

          <p>YOU WAIVE AND HOLD US AND OUR PARENT, SUBSIDIARIES, AFFILIATES AND OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS HARMLESS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY US AND ANY OF THE FOREGOING PARTIES RELATING TO ANY INVESTIGATIONS BY EITHER US OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.</p>

          <h3>7. DISCLAIMERS</h3>
          <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE APP IS AT YOUR SOLE RISK AND THAT THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE ACCORDING TO APPLICABLE LAW, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE APP AND ANY PART OF IT, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR PARENT, SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT:</p>
          <ol>
            <li>YOUR ACCESS TO OR USE OF THE APP WILL MEET YOUR REQUIREMENTS;</li>
            <li>YOUR ACCESS TO OR USE OF THE APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR;</li>
            <li>USAGE DATA PROVIDED THROUGH THE APP WILL BE ACCURATE;</li>
            <li>THE APP OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE APP ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR</li>
            <li>THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE APP WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.</li>
          </ol>

          <p>YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.</p>

          <p>WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE BSC, OR YOUR ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM:</p>
          <ol>
            <li>USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS;</li>
            <li>SERVER FAILURE OR DATA LOSS;</li>
            <li>CORRUPTED WALLET FILES; OR</li>
            <li>UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING, BUT NOT LIMITED TO, THE USE OF VIRUSES, PHISHING, BRUTE-FORCING OR OTHER MEANS OF ATTACK AGAINST THE APP, THE BSC, OR ANY ELECTRONIC WALLET.</li>
          </ol>

          <p>MOMENTS ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE BSC. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE DECENTRALIZED LEDGER WITHIN THE BSC. WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES CONCERNING SMART CONTRACTS.</p>

          <p>WE ARE NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY OTHER FEATURES OF THE BSC, OR ANY ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE BSC, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.</p>

          <h3>8. LIMITATION OF LIABILITY</h3>
          <p>YOU UNDERSTAND AND AGREE THAT WE, OUR PARENT, SUBSIDIARIES, AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, DIMINUTION OF VALUE OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

          <p>YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE APP, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF THE AMOUNTS YOU HAVE ACTUALLY AND LAWFULLY PAID US UNDER THESE TERMS IN THE TWO (2) MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE.</p>

          <p>YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE APP AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE REPRESENTATIONS AND WARRANTIES,  DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN US AND YOU AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US AND YOU. WE WOULD NOT BE ABLE TO PROVIDE THE APP TO YOU WITHOUT THESE LIMITATIONS.</p>

          <h3>9. ASSUMPTION OF RISK</h3>
          <ol>
            <li>
              <h4>Value and Volatility</h4>
              { ' ' }
              <p>The prices of collectable blockchain assets are highly volatile and subjective, and collectable blockchain assets have no inherent or intrinsic value. Fluctuations in the price of other digital assets could materially and adversely affect the value of your Moments, which may also be subject to significant price volatility. Each Moment has no inherent or intrinsic value. We cannot guarantee that any Moments purchased will retain their original value, as the value of collectables is inherently subjective. Factors occurring outside of the Real Fevr ecosystem may materially impact the value and desirability of any particular Moment.</p>
            </li>
            <li>
              <h4>Tax Calculations</h4>
              { ' ' }
              <p>You are solely responsible for determining what, if any, taxes apply to your Moment-related transactions. We are not responsible for determining the taxes that apply to your transactions on the App.</p>
            </li>
            <li>
              <h4>Use of Blockchain</h4>
              { ' ' }
              <p>The App does not store, send, or receive Moments. This is because Moments exist only under the ownership record maintained on the App's supporting blockchain in the BSC. Therefore, any transfer of Moments occurs within the supporting blockchain in the BSC and not on the App.</p>
            </li>
            <li>
              <h4>Inherent Risks with Internet Currency</h4>
              { ' ' }
              <p>There are risks associated with using an Internet-based currency, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your electronic wallet. You accept and acknowledge that we will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the BSC, however, caused.</p>
            </li>
            <li>
              <h4>Regulatory Uncertainty</h4>
              { ' ' }
              <p>The regulatory regime governing blockchain technologies, cryptocurrencies and tokens is uncertain, and new regulations or policies may materially adversely affect the development of the Real Fevr ecosystem, and therefore the potential utility or value of your Moments.</p>
            </li>
            <li>
              <h4>Software Risks</h4>
              { ' ' }
              <p>Upgrades to the BSC, a hard fork in the BSC, or a change in how transactions are confirmed on the BSC may have unintended, adverse effects on all blockchains using the BSC's NFT standard, including the Real Fevr ecosystem.</p>
            </li>
          </ol>

          <h3>10. INDEMNIFICATION</h3>
          <p>You agree to hold harmless and indemnify us and our parent, subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost and attorneys' fees arising out of or in any way related to</p>
          <ol>
            <li>your breach of these Terms;</li>
            <li>your misuse of the App; or</li>
            <li>your violation of applicable laws, rules or regulations in connection with your access to or use of the App. You agree that we will have control of the defence or settlement of any such claims.</li>
          </ol>

          <h3>11. EXTERNAL SITES</h3>
          <p>The App may include hyperlinks to other websites or resources (collectively, the "External Sites"), which are provided solely as a convenience to our users. We have no control over any External Sites. Accordingly, you acknowledge and agree that we are not responsible for the availability of any External Sites and that we do not endorse any advertising, products or other materials on or made available from or through any External Sites. Furthermore, you acknowledge and agree that we are not liable for any loss or damage which may be incurred as a result of the availability or unavailability of the External Sites or as a result of any reliance placed by you upon the completeness, accuracy or existence of any advertising, products or other materials on, or made available from, any External Sites.</p>

          <h3>12. FORCE MAJEURE</h3>
          <ol>
            <li>
              <h4>Force Majeure Events</h4>
              { ' ' }
              <p>We will not be liable to you, nor be deemed to have defaulted under or breached these Terms, for any failure or delay in fulfilling or performing any of these Terms, when and to the extent such failure or delay is caused by or results from the following force majeure events ("Force Majeure Event(s)" ): (a) acts of God; (b) flood, fire, earthquake, epidemics, pandemics, including the 2019 novel coronavirus pandemic (COVID-19), tsunami, explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (d) government order, law, or action; (e) embargoes or blockades in effect on or after the date of this agreement; (f) strikes, labour stoppages or slowdowns or other industrial disturbances; (g) shortage of adequate or suitable Internet connectivity, telecommunication breakdown or shortage of adequate power or electricity; and (h) other similar events beyond our control.</p>
            </li>

            <li>
              <h4>Performance During Force Majeure Events</h4>
              { ' ' }
              <p>If we suffer a Force Majeure Event, we will use reasonable efforts to promptly notify you of the Force Majeure Event, stating the period of time the occurrence is expected to continue. We will use diligent efforts to end the failure or delay and ensure the effects of such Force Majeure Event are minimized. We will resume the performance of our obligations as soon as reasonably practicable after removing the cause. If our failure or delay remains uncured for a period of forty-five (45) consecutive days following written notice given by us under this Section 12, we may thereafter terminate these Terms upon fifteen (15) days written notice.</p>
            </li>
          </ol>

          <h3>13. CHANGES TO THE APP</h3>
          <p>We are constantly innovating the App to help provide the best possible experience. You acknowledge and agree that the form and nature of the App, and any part of it, may change from time to time without prior notice to you and that we may add new features and change any part of the App at any time without notice.</p>

          <h3>14. CHILDREN</h3>
          <p>The App is not intended for children under 18.  If you are under the age of 18, you may not use the App.  We do not knowingly collect information from or direct any of our content specifically to children under 18.  If we learn or have reason to suspect that you are a user under 18, we will have to close your account. In addition, other countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not use the App.</p>

          <h3>15. PRIVACY POLICY</h3>
          <p>Our Privacy Policy describes how we collect, use, store and disclose your personal information, and is hereby incorporated into these Terms. You agree to the collection, use, storage, and disclosure of your data under our Privacy Policy.</p>

          <h3>16. DISPUTE RESOLUTION; BINDING ARBITRATION</h3>
          <p>YOU AGREE TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION. IN ADDITION, YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY.</p>

          <p>YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING, BUT NOT LIMITED TO, CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.</p>
          <ol>
            <li>
              Binding Arbitration. All disputes arising out of or in connection with this contract, or in respect of any defined legal relationship associated in addition to that or derived therefrom, shall be referred to and finally resolved by arbitration under the Arbitration Centre of the Portuguese Chamber of Commerce and Industry ( “Arbitration Centre”).
              The appointing authority shall be the Arbitration Centre.
              The Arbitration Centre shall administer the case in accordance with its Rules.
              The place of arbitration shall be Lisbon, Portugal.
            </li>
            <li>Arbitration Fees. Each party will cover its fees and costs associated with the arbitration proceedings; however, if the arbitrator finds that you cannot afford to pay the fees and expenses reasonably related to the arbitration proceedings, we will settle them for you.</li>
            <li>Award Enforcement. The arbitrator's award will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. The parties agree that they will not appeal any arbitration decision to any court.</li>
            <li>Our Equitable Remedies. Notwithstanding the foregoing, we may seek injunctive relief in any jurisdiction in any court of competent jurisdiction. You agree that these Terms are specifically enforceable by us through injunctive relief and other equitable remedies without proof of monetary damages.</li>
          </ol>

          <h3>17. GENERAL</h3>
          <ol>
            <li>Entire Agreement.  These Terms and our Privacy Policy constitute the entire legal agreement between you and us and will be deemed to be the final and integrated agreement between you and us, and govern your access to and use of the App, and completely replace any prior or contemporaneous agreements between you and us related to your access to or use of the App, whether oral or written.</li>
            <li>No Third-Party Beneficiaries. These Terms do not and are not intended to confer any rights or remedies upon any person or entity other than you</li>
            <li>Interpretation. The language in these Terms will be interpreted as its fair meaning and not strictly for or against any party.</li>
            <li>Severability. Should any part of these Terms be held invalid, illegal, void or unenforceable, that portion will be deemed severed from these Terms and will not affect the validity or enforceability of the remaining provisions of these Terms.</li>
            <li>No Waivers. Our failure or delay in exercising or enforcing any right or provision of these Terms will not constitute or be deemed a waiver of the future exercise or enforcement of such right or provision. The waiver of any right or provision of these Terms will be effective only if in writing and signed for and on behalf of us by a duly authorized representative.</li>
            <li>Governing Law. All matters arising out of or relating to these Terms will be governed by and construed in accordance with the laws of Portugal applicable therein without giving effect to any choice or conflict of law provision or rule.</li>
            <li>Notices. We may provide you with any notices (including, without limitation, those regarding changes to these Terms) by email or postings on the App. By providing us with your email address, you consent to use the email address to send you any notices. Notices sent by email will be effective when we send the email, and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.</li>
            <li>Assignment. You may not assign any of your rights or obligations under these Terms, whether by operation of law or otherwise, without our prior written consent. We may assign our rights and obligations under these Terms in our sole discretion to an affiliate or in connection with an acquisition, sale or merger.</li>
          </ol>

          <h3>Right to Cancel</h3>
          <p>European Union residents have the right to cancel online transactions and return their order within 14 days for any reason and without a justification. However, the 14-day cooling-off period does not apply to all purchases. There are some exceptions, including an exemption for online digital content if you have already started downloading it and you agreed that you would lose your right of withdrawal by starting the performance of the contract.</p>
          <p>Even if your statutory right of withdrawal ends, you may still request a refund of certain content pursuant to our refund policy. You can find the details of our refund policy at (refund policy).</p>
          <p>We are obligated to inform you in precise legal terms of the right of withdrawal, which you can find below.</p>

          <h3>Withdrawal instruction</h3>
          <p>Right of Withdrawal You have the right to withdraw from this contract within fourteen days without stating any reason, therefore. The withdrawal period is fourteen days from the date of the conclusion of the contract.</p>
          <p>In order to exercise your right of withdrawal, you must inform us (address below) of your decision to withdraw from this contract by means of an explicit declaration, e.g. a letter sent by post, telephone or email. You can use the attached sample revocation form, which is, however, not mandatory.</p>
          <p>In order to comply with the withdrawal period, it is sufficient for you to send the notification of exercising the right of withdrawal before the expiry of the withdrawal period.</p>

          <h3>Consequences of the withdrawal</h3>
          <p>If you withdraw from this contract, we shall reimburse to you all payments we have received from you, including delivery charges (except for any additional costs arising from your choice of a method of delivery other than the cheapest standard delivery offered by us), immediately and no later than fourteen days from the date on which we receive notice of your withdrawal from this contract. We will use the same means of payment for such refund as you used for the original transaction unless expressly agreed otherwise with you, and in no event will you be charged for such reimbursement.</p>

          <h3>Exception to the right of withdrawal</h3>
          <p>You may not cancel an order for digital content if the delivery has begun with your express consent and acknowledgement that you thereby lose your right of withdrawal.</p>

          <h3>Sample withdrawal form</h3>
          <p>
            To
            <strong>[our address]</strong>
            <br />
            I hereby withdraw from the contract I have entered into:
            { ' ' }
            <strong>[ORDER NUMBER, ARTICLE, etc.]</strong>
            <br />
            Order placed on
            { ' ' }
            <strong>[INSERT DATE]</strong>
            { ' ' }
            / received on
            <strong>[INSERT DATE]</strong>
            <br />
            Consumer name
            <br />
            Consumer's address
            <br />
            Consumer's email address (optional)
            <br />
            Signature
            <br />
            Date
          </p>

        </div>
      </div>
    </PageContainer>
  </>
);

export default Terms;
