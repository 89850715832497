import { useMemo } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

import { getConfig } from 'hooks/useConfig';

import api from 'lib/api';

const initialState = { userPacks: null };
const { setGlobalState, useGlobalState } = createGlobalState(initialState);

// We should hardly refetch anything, but let's allow other components to do it if they need to
export const updateUserPacks = async () => {
  const config = getConfig();
  const packsInfo = config?.packs_info?.packs;
  const packs = await api.getUserPacks();

  const userPacks = packsInfo?.map(packInfo => ({
    ...packInfo,
    owned: packs?.data
      .filter(({ attributes: { opened, type } }) => !opened && type === packInfo.pack_type),
  }))
    .filter(({ owned }) => owned?.length);

  setGlobalState('userPacks', userPacks);

  return userPacks;
};

// We don't fetch initially first, we let the hook's consumer do it at its own leisure

const useUserPacks = () => {
  const [ userPacks ] = useGlobalState('userPacks');

  return useMemo(() => userPacks || [], [ userPacks ]);
};

export default useUserPacks;
