import { number, string } from 'prop-types';
import classnames from 'classnames';

import Image from 'components/Image';

const TeamMember = ({
  avatar, col, name, position, profile,
}) => (
  <div
    className={ classnames('about--team-item', {
      [`col-md-${col}`]: col,
    }) }
  >
    <a
      className="about--team-item-avatar"
      href={ profile }
      rel="noopener noreferrer"
      target="_blank"
    >
      <Image
        alt={ name }
        src={ avatar }
      />
    </a>
    <h3 className="about--team-item-title mid-title green">
      { name }
    </h3>
    <div className="about--team-item-desc">
      { position }
    </div>
  </div>
);

TeamMember.propTypes = {
  avatar: string.isRequired,
  col: number,
  name: string.isRequired,
  position: string.isRequired,
  profile: string.isRequired,
};

TeamMember.defaultProps = {
  col: '',
};

export default TeamMember;
