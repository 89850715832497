import { useEffect } from 'react';

import Button from 'components/Button/Button';
import ButtonViewTransaction from 'components/Button/ViewTransaction';
import Modal from 'components/Modal/Modal';

import useModal from 'hooks/useModal';
import usePackVideo from 'hooks/usePackVideo';

const ModalAwaiting = () => {
  const { close, show } = useModal('awaiting');
  const { show: showSuccessful } = useModal('transaction-successful');
  const packVideo = usePackVideo();

  useEffect(() => {
    if (show?.transactionHash) {
      if (showSuccessful?.find(item => item.transactionHash === show.transactionHash)) {
        close();
      }

      if (packVideo?.show?.transactionHash === show.transactionHash) {
        close();
      }
    }
  }, [ close, packVideo, show, showSuccessful ]);

  return (
    <Modal name="awaiting">
      <div className="popup--title">Waiting for Confirmation</div>
      <div className="popup--desc">
        We are waiting for confirmation from the blockchain. This can take from a few seconds up to
        several minutes.
        <br />
        <br />
        Feel free to keep browsing, we will let you know as soon as the process is complete.
      </div>

      <ul className="popup--actions">
        <li>
          <ButtonViewTransaction
            className="btn-secondary"
            transactionHash={ show?.transactionHash }
          >
            View Transaction
          </ButtonViewTransaction>
        </li>
        <li>
          <Button
            className="btn-primary"
            onClick={ close }
            size="m"
            theme="green-gradient"
          >
            I Understand
          </Button>
        </li>
      </ul>
    </Modal>
  );
};

export default ModalAwaiting;
