import { useState } from 'react';
import { node, string } from 'prop-types';
import classnames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';

const ExpandableItem = ({ children, title, type }) => {
  const [ active, setActive ] = useState(false);

  const eventKey = `${type}-${title}`;

  return (
    <Accordion
      as="li"
      className={ classnames('expandable-items--item', {
        'expandable-items--item-active': active,
      }) }
      onSelect={ newEventKey => setActive(!!newEventKey) }
    >
      <Accordion.Toggle
        as="button"
        className="expandable-items--trigger"
        eventKey={ eventKey }
        type="button"
      >
        { title }
      </Accordion.Toggle>

      <Accordion.Collapse
        className="expandable-items--content"
        eventKey={ eventKey }
      >
        <>
          { children }
        </>
      </Accordion.Collapse>
    </Accordion>
  );
};

ExpandableItem.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  type: string,
};

ExpandableItem.defaultProps = {
  type: 'expandable',
};

export default ExpandableItem;
