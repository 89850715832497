import Button from 'components/Button/Button';
import Image from 'components/Image';
import Modal from 'components/Modal/Modal';
import Price from 'components/Price';

import useModal from 'hooks/useModal';

const ModalCancelSellCollectible = () => {
  const confirm = useModal('cancel-sell-collectible');
  const follow = useModal('follow-metamask');

  const {
    onConfirm, image, price, title,
  } = confirm.show || {};

  return (
    <Modal name="cancel-sell-collectible">
      <div className="popup--title">Cancel Sale</div>
      <div className="popup--desc">
        Are you sure you want cancel this collectible sale?
        <ul className="fevr-price-exchange-display">
          <li className="fevr-price-indication">
            <Price bnb={ price } output="bnb" />
          </li>
          <li className="exchange-rate">
            <Price bnb={ price } output="dollar" />
          </li>
        </ul>
        <Image
          alt={ title }
          className="collectible--img collectible--img-smaller"
          src={ image || '' }
        />
      </div>

      <ul className="popup--actions">
        <li>
          <Button
            className="btn-primary"
            onClick={ async () => {
              confirm.close();
              follow.open({ title: 'Cancel Sale' });
              await onConfirm(price);
              follow.close();
            } }
            size="m"
            theme="green-gradient"
          >
            Yes
          </Button>
        </li>
        <li>
          <Button
            className="btn-secondary"
            onClick={ () => confirm.close() }
            size="m"
          >
            No
          </Button>
        </li>
      </ul>
    </Modal>
  );
};

export default ModalCancelSellCollectible;
