import { useMemo } from 'react';
import { oneOf } from 'prop-types';

import FilterSelect from 'components/Filter/Select';

import useConfig from 'hooks/useConfig';
import useQueryParams from 'hooks/useQueryParams';

const FilterSort = ({ page }) => {
  const { filters } = useConfig();

  const sorts = useMemo(
    () => [
      ...(filters?.sort?.[page] || []),
      ...(filters?.sort?.default || []),
    ]
      .sort(([ , , a ], [ , , b ]) => a - b)
      .map(([ label, value ]) => ({ label, value })),
    [ filters?.sort, page ],
  );

  const { queryParams: { sort }, set } = useQueryParams();

  return (
    <div className="form-element-container">
      <label htmlFor="selectbox_sortby">
        Sort by
      </label>
      <FilterSelect
        id="selectbox_sortby"
        name="sortby"
        onChange={ option => set({ order: option.value }) }
        options={ sorts }
        value={ sorts?.find(el => el.value === sort) ?? { label: 'Newest', value: 'listed_desc' } }
      />
    </div>
  );
};

FilterSort.propTypes = {
  page: oneOf([ 'collection', 'marketplace' ]),
};

FilterSort.defaultProps = {
  page: '',
};

export default FilterSort;
