import ExpandableItem from 'components/Expandable/Item/Item';
import ExpandableItems from 'components/Expandable/Items/Items';
import Metadata from 'components/Metadata';
import PageContainer from 'components/Page/Container';
import PageTitle from 'components/Page/Title';
import TitleLines from 'components/TitleLines/TitleLines';
import Video from 'components/Video/Video';
import { Link } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import heroVideoCoverWithPlay from './assets/hero-video-cover-with-play.jpg';

import './style.scss';

const HowToCollect = () => {
  const { howto } = useConfig();

  return (
    <>
      <Metadata
        description="Learn how to collect the most iconic football moments in history."
        title="RealFevr - How to Collect?"
      />
      <PageTitle>
        How to collect
      </PageTitle>

      <section className="howtocollect--section howtocollect--section-1">
        <PageContainer>
          <div className="row">
            <div className="col">
              <TitleLines comingSoon={ howto && !howto.buy_fevr }>
                HOW TO BUY $FEVR
              </TitleLines>
            </div>
          </div>

          { !!howto?.buy_fevr && (
            <div className="row">
              <div className="col-md-6">
                <Video
                  containerClassName="howtocollect--video howtocollect--video-1"
                  height="100%"
                  poster={ heroVideoCoverWithPlay }
                  src={ howto.buy_fevr }
                  sticky
                  width="100%"
                />
              </div>
              <div className="col-md-6">
                <p>
                  In order to buy the
                  { ' ' }
                  <strong>FEVR token</strong>
                  , you need the
                  { ' ' }
                  <a href="https://metamask.io" target="_blank" rel="noopener noreferrer">MetaMask</a>
                  { ' ' }
                  browser extension that allows you to communicate with the blockchain (web3).
                </p>
                <p>
                  If you already have MetaMask installed, please skip
                  { ' ' }
                  <strong>STEP 1</strong>
                  , and if you are already connected to
                  { ' ' }
                  <a href="https://www.binance.com/" target="_blank" rel="noopener noreferrer">Binance Smart Chain</a>
                  , skip
                  { ' ' }
                  <strong>STEP 2</strong>
                  .
                </p>

                <ExpandableItems>
                  <ExpandableItem title="STEP 1 - Install MetaMask">
                    <p>
                      $FEVR is a BEP20 token that can be used through MetaMask. To acquire and use
                      the token you have to “be your own bank” and manage the coins yourself. That’s
                      what decentralization is all about, right?
                    </p>
                    <ol>
                      <li data-listing-number="1.">First, go to metamask.io and click &quot;Download Now&quot; to install the MetaMask Browser extension.</li>
                      <li data-listing-number="1.">Once MetaMask is installed, create a new wallet. You can create as many wallets (addresses) as you wish.</li>
                      <li data-listing-number="1.">Please don’t forget to write down and keep a physical copy of your seed phrase, because you need this if you ever lose access to your wallet, or if you need to set up MetaMask on alternative devices.</li>
                    </ol>
                  </ExpandableItem>
                  <ExpandableItem title="STEP 2 - Connect to Binance Smart Chain">
                    <p>
                      Once you have the MetaMask browser extension installed, you have to switch
                      from the default network (Ethereum) to Binance Smart Chain. Let’s dig into
                      the phases on how to do it.
                    </p>
                    <ol>
                      <li data-listing-number="2.">Open MetaMask by clicking on the fox icon. You’ll notice a dropdown tab called “Ethereum Network”.</li>
                      <li data-listing-number="2.">Click on the “Main Ethereum Network” at the top and select custom RPC.</li>
                      <li data-listing-number="2.">
                        Add the details below and don’t forget to save!
                        <br />
                        <br />

                        Network Name: Binance Smart Chain
                        <br />
                        New RPC URL: https://bsc-dataseed.binance.org/
                        <br />
                        ChainID: 0x38
                        <br />
                        Symbol: BNB
                        <br />
                        Block Explorer URL: https://www.bscscan.com
                      </li>
                      <li data-listing-number="2.">Congrats! You now have a Binance Smart Chain MetaMask wallet.</li>
                    </ol>
                  </ExpandableItem>
                  <ExpandableItem title="STEP 3 - Get Some BNB">
                    <p>
                      Next, you need to fund your wallet with BNB, which we are going to use later
                      to buy $FEVR. There are many ways to do this, the easiest is on
                      { ' ' }
                      <a href="https://www.binance.com/" target="_blank" rel="noopener noreferrer">binance.com</a>
                      .
                    </p>
                    <ol>
                      <li data-listing-number="3.">Create an account at the Binance exchange.</li>
                      <li data-listing-number="3.">Once you are logged in, you should add funds to your account.</li>
                      <li data-listing-number="3.">Then, you need to convert your funds, either FIAT or Crypto, to BNB.</li>
                      <li data-listing-number="3.">Now with BNB in your wallet, it’s time to withdraw it.</li>
                    </ol>
                  </ExpandableItem>
                  <ExpandableItem title="STEP 4 - Send BNB to MetaMask">
                    <p>
                      After acquiring BNB you should send it to your own MetaMask wallet (address).
                    </p>
                    <ol>
                      <li data-listing-number="4.">First, copy the address from MetaMask. It’s easy, just hover your mouse over the address (you can name it), and click on “Copy”.</li>
                      <li data-listing-number="4.">At binance.com go to the Withdraw page and select the currency you want to withdraw. In this case, BNB.</li>
                      <li data-listing-number="4.">Paste the address you want to send the BNB to and select the BEP-20 network. Make sure you’ve all the right information, so you don’t lose your funds.</li>
                      <li data-listing-number="4.">Lastly, type the amount and click “send”. You should see your MetaMask wallet balance updating in a few minutes. Be patient since the network is sometimes congested.</li>
                    </ol>
                  </ExpandableItem>
                  <ExpandableItem title="STEP 5 - Buy $FEVR">
                    <p>
                      Now that you have BNB on your MetaMask wallet, you are finally able to buy
                      some $FEVR at
                      { ' ' }
                      <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x82030cdbd9e4b7c5bb0b811a61da6360d69449cc" target="_blank" rel="noopener noreferrer">PancakeSwap</a>
                      .
                    </p>
                    <ol>
                      <li data-listing-number="5.">
                        First, go to
                        { ' ' }
                        <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x82030cdbd9e4b7c5bb0b811a61da6360d69449cc" target="_blank" rel="noopener noreferrer">pancakeswap.finance</a>
                        { ' ' }
                        and connect your MetaMask to the website. Make sure you have selected the
                        “Binance Smart Chain” Network. If you’re unsure, please see STEP 2 -
                        Connect to Binance Smart Chain.
                      </li>
                      <li data-listing-number="5.">5.2 Go to “Trade” on the left menu and then click on “Exchange”.</li>
                      <li data-listing-number="5.">Type the total amount of BNB you want to use, select $FEVR as the second token and click on “Swap”.</li>
                      <li data-listing-number="5." style={ { lineBreak: 'anywhere' } }>If you can’t find $FEVR as the second token, you must manually add its contract 0x82030CDBD9e4B7c5bb0b811A61DA6360D69449cc.</li>
                      <li data-listing-number="5.">Once MetaMask pops-up, approve the transaction by clicking on “Confirm”. If MetaMask does not pop-up, open the browser extension and you should see a transaction waiting for your confirmation.</li>
                      <li data-listing-number="5.">
                        If there is an error, please adjust your “Slippage”. In order to do that
                        follow these next steps:
                        <ol>
                          <li data-listing-number="I">
                            Click on “Settings” at the top of the “Exchange” page. The icon looks
                            like
                            <svg className="inline-svg-icon" id="bf16d735-d8ff-4107-b9ae-d0c9f236f199" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 27.16">
                              <path d="M0,3.58a1,1,0,0,1,1-1H11a1,1,0,0,1,0,2H1A1,1,0,0,1,0,3.58Z" style={ { fill: '#fff' } } />
                              <path d="M15.75,3.58a1,1,0,0,1,1-1H21a1,1,0,0,1,0,2H16.75A1,1,0,0,1,15.75,3.58Z" style={ { fill: '#fff' } } />
                              <path d="M0,13.58a1,1,0,0,1,1-1H3.67a1,1,0,0,1,0,2H1A1,1,0,0,1,0,13.58Z" style={ { fill: '#fff' } } />
                              <path d="M7.75,13.58a1,1,0,0,1,1-1H21a1,1,0,0,1,0,2H8.75A1,1,0,0,1,7.75,13.58Z" style={ { fill: '#fff' } } />
                              <path d="M0,23.58a1,1,0,0,1,1-1H8.25a1,1,0,0,1,0,2H1A1,1,0,0,1,0,23.58Z" style={ { fill: '#fff' } } />
                              <path d="M13.08,23.58a1,1,0,0,1,1-1H21a1,1,0,0,1,0,2H14.08A1,1,0,0,1,13.08,23.58Z" style={ { fill: '#fff' } } />
                              <path d="M5.08,16.16V11a1,1,0,0,1,2,0v5.16a1,1,0,0,1-2,0Z" style={ { fill: '#fff' } } />
                              <path d="M13.08,6.16V1a1,1,0,0,1,2,0V6.16a1,1,0,1,1-2,0Z" style={ { fill: '#fff' } } />
                              <path d="M10.08,26.16V21a1,1,0,0,1,2,0v5.16a1,1,0,0,1-2,0Z" style={ { fill: '#fff' } } />
                            </svg>
                            .
                          </li>
                          <li data-listing-number="II">Adjust your Slippage to &gt; 20.0% (above twenty percent).</li>
                          <li data-listing-number="III">Close the window.</li>
                        </ol>
                      </li>
                      <li data-listing-number="5">After the transaction is complete, you’re a proud owner of $FEVR.</li>
                    </ol>
                  </ExpandableItem>
                </ExpandableItems>
              </div>
            </div>
          ) }
        </PageContainer>
      </section>

      <section className="howtocollect--section howtocollect--section-2">
        <PageContainer>
          <div className="row">
            <div className="col">
              <TitleLines comingSoon={ howto && !howto.open_packs }>
                HOW TO BUY PACKS
              </TitleLines>
            </div>
          </div>

          { !!howto?.open_packs && (
            <div className="row">
              <div className="col-md-6">
                <p>
                  After buying some $FEVR you are ready to buy packs on each RealFevr drop and start
                  your own collection of everlasting football moments.
                </p>
                <p>It doesn’t matter whether you are a football fan, a collector or an investor.</p>
                <p>
                  The opportunity to own a timeless collectible is for everyone, and you shouldn’t
                  miss it.
                </p>

                <ExpandableItems>
                  <ExpandableItem title="Follow these STEPS and get ready to #JoinTheFEVR">
                    <ol>
                      <li>
                        First, access RealFevr’s Packs page, to know which packs are available,
                        their characteristics and which collectibles they include.
                      </li>
                      <li>
                        Next, click on “Connect Wallet” on the top right corner of the website. If
                        you’re unsure how to set up your MetaMask, please revert back to STEP 2 on
                        “How To Buy $FEVR”.
                      </li>
                      <li>
                        Once you’re connected, scroll down the page until you see each pack type.
                        Select the one that suits your preferences and click on “Buy”.
                      </li>
                      <li>
                        Select the number of packs you want to buy, check that the price is right,
                        click on “Buy” and confirm the purchase by clicking on “Confirm”. Once
                        MetaMask pops-up, also click on “Confirm”, but pay attention to the fees.
                      </li>
                      <li>
                        After the transaction is confirmed you’ll see a screen confirming that you
                        have a new pack or packs. Then, you can either click to see the transaction
                        on BSCscan, or you can go to your collection in order to open the pack(s).
                      </li>
                    </ol>
                    <br />
                    <p>
                      Your first step to be a football digital collector is taken, there is just a
                      small leap to everlasting eternity, which is to open your pack(s) and see your
                      own and unique NFTs!
                    </p>
                  </ExpandableItem>
                </ExpandableItems>
              </div>

              <div className="col-md-6">
                <Video
                  containerClassName="howtocollect--video howtocollect--video-1"
                  height="100%"
                  poster={ heroVideoCoverWithPlay }
                  src={ howto.open_packs }
                  sticky
                  width="100%"
                />
              </div>
            </div>
          ) }
        </PageContainer>
      </section>

      <section className="howtocollect--section howtocollect--section-3">
        <PageContainer>
          <div className="row">
            <div className="col">
              <TitleLines comingSoon={ howto && !howto.buy_sell }>
                HOW TO BUY
                <br />
                & SELL COLLECTIBLES
              </TitleLines>
            </div>
          </div>

          { !!howto?.buy_sell && (
            <div className="row">
              <div className="col-md-6">
                <Video
                  containerClassName="howtocollect--video howtocollect--video-3"
                  height="100%"
                  poster={ heroVideoCoverWithPlay }
                  src={ howto.buy_sell }
                  sticky
                  width="100%"
                />
              </div>
              <div className="col-md-6">
                <p>
                  Last but not least, here you learn how you can become the football video NFTs GOAT collector.
                </p>
                <p>
                  As you already have $FEVR and collectibles, you can now build up your
                  collection by purchasing moments from other fans.
                </p>
                <p>Let&apos;s now dive into the Marketplace and discuss how to buy and sell NFTs.</p>

                <ExpandableItems>
                  <ExpandableItem title="STEP 1 - Buy Collectibles">
                    <ol>
                      <li data-listing-number="1.">
                        To start, go to RealFevr&apos;s
                        { ' ' }
                        <Link to="/marketplace">Marketplace</Link>
                        { ' ' }
                        page and find out what moments are for sale.
                        You can scroll down until you see a football moment that catches your eye,
                        or you can use the filters to search for your favorite players.
                      </li>
                      <li data-listing-number="1.">When you pick a collectible that you are interested in, click on &quot;Buy&quot;. A box will appear with your moment and you only need to click on &quot;Buy Now&quot;.</li>
                      <li data-listing-number="1.">
                        Complete the purchase by clicking &quot;Confirm&quot; and
                        then you&apos;ll need to confirm two transactions.
                        <ol>
                          <li data-listing-number="I">The first transaction is to approve RealFevr&apos;s Marketplace. Once MetaMask pops up, also click on &quot;Confirm&quot;. We recommend the gas price to be 5 gwei on every transaction with MetaMask.</li>
                          <li data-listing-number="II">The second transaction is to confirm the purchase. After the first transaction is confirmed, a new MetaMask pop-up window appears. Click on &quot;Confirm&quot; as well.</li>
                        </ol>
                      </li>
                      <li data-listing-number="1.">After the transaction is confirmed, you&apos;ll see a pop-up confirming that you have a new moment. Here, you can click to see the transaction on BSCscan, or you can go to your collection in order to see the moment.</li>
                    </ol>
                  </ExpandableItem>

                  <ExpandableItem title="STEP 2 - My Collectibles">
                    <ol>
                      <li data-listing-number="2.">
                        Once you&apos;re happy with the moments purchases, you can go to your collection by clicking on
                        { ' ' }
                        <Link to="/my-collection">My Collection</Link>
                        .
                      </li>
                      <li data-listing-number="2.">In the My Collection area, you can open packs, mint, and watch your favorite football moments.</li>
                      <li data-listing-number="2.">To mint a moment and add it to your MetaMask wallet address, just click on &quot;Mint&quot;. Please click on &quot;Confirm&quot; once the MetaMask transaction pops up and it&apos;s done.</li>
                      <li data-listing-number="2.">
                        To see the NFTs in your MetaMask, click on &quot;Add Token&quot;, paste this address
                        { ' ' }
                        <span style={ { wordBreak: 'break-all' } }>&quot;0x618dcd507d1dcedaed7df0df54728326fd33d22e&quot;</span>
                        { ' ' }
                        on the Contract Address, add &quot;0&quot; on the Token Decimal.
                        After this you will have a representative token called &quot;BEP721&quot;
                        that represents your NFTs.
                      </li>
                      <li data-listing-number="2.">To view the moment and all its details, you must click on the cube itself.</li>
                    </ol>
                  </ExpandableItem>

                  <ExpandableItem title="STEP 3 - Sell Collectibles">
                    <ol>
                      <li data-listing-number="3.">To sell a moment, it has to be minted before. Then, click on &quot;Sell&quot;, set up a price in BNB and click on &quot;Confirm&quot;.</li>
                      <li data-listing-number="3.">You&apos;ll see another confirmation screen with the sale details. Just click on &quot;Yes&quot;.</li>
                      <li data-listing-number="3.">
                        Once again, you&apos;ll need to confirm two transactions.
                        <ol>
                          <li data-listing-number="I">The first transaction is to approve RealFevr&apos;s Marketplace to spend tokens for you. Once MetaMask pops up, also click on &quot;Confirm&quot;, but pay attention to the fees.</li>
                          <li data-listing-number="II">The second transaction is to confirm the purchase. After the first transaction is confirmed, a new MetaMask pop-up appears. Click on &quot;Confirm&quot; one last time.</li>
                        </ol>
                      </li>
                      <li data-listing-number="3.">Once the moment is bought by another fan, you&apos;ll receive the amount minus the Marketplace fees directly in your MetaMask wallet.</li>
                    </ol>
                    <br />
                    <p>
                      After buying $FEVR, acquiring packs, and trading moments in the Marketplace,
                      you are definitely and everlastingly ready to be part of the RealFevr crew!
                      { ' ' }
                      <strong>#JoinTheFEVR</strong>
                    </p>
                    <br />
                  </ExpandableItem>
                </ExpandableItems>
              </div>
            </div>
          ) }
        </PageContainer>
      </section>
    </>
  );
};

export default HowToCollect;
