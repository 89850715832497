import getTraits from 'utils/getTraits';

import CollectibleRow from 'components/Collectible/Row/Row';
import Metadata from 'components/Metadata';
import PageTitle from 'components/Page/Title';
import PageContainer from 'components/Page/Container';
import Pagination from 'components/Pagination/Pagination';
import Tabs from 'components/Tabs/Tabs';
import IconInfo from 'components/Icon/Info/Info';

import { perPageOptionsMarketplace } from 'config';

import useTopSales from 'hooks/useTopSales';

import './top-sales.scss';

const MarketplaceTopSales = () => {
  const { data, included, meta } = useTopSales();

  const finalData = data?.map(dt => {
    const relationID = dt?.relationships?.nft?.data?.id;
    const { attributes } = included.find(item => item.id === relationID);
    const traits = getTraits(attributes);
    return ({
      ...dt,
      attributes: {
        ...dt.attributes,
        ...attributes,
      },
      traits,
    });
  });

  return (
    <>
      <Metadata
        description="Buy and sell the most iconic and everlasting football moments in history."
        title="RealFevr - Marketplace"
      />
      <PageTitle>Marketplace</PageTitle>
      <Tabs
        tabs={ [
          { label: 'For Sale', path: '/marketplace' },
          { label: 'Top Sales', path: '/marketplace/top-sales' },
        ] }
      />

      <PageContainer className="top-sales-listing">
        <div className="row top-sales-listing--header">
          <div className="col top-sales-listing--item col-md-2">
            <span className="top-sales-listing--item-title">Collectible</span>
          </div>
          <div className="col top-sales-listing--item col-md-3">
            <span className="top-sales-listing--item-title">Name</span>
          </div>
          <div className="col top-sales-listing--item col-md-2">
            <span className="top-sales-listing--item-title">Price</span>
          </div>
          <div className="col top-sales-listing--item col-md-2">
            <span className="top-sales-listing--item-title">Rarity</span>
          </div>
          <div className="col top-sales-listing--item col-md-1">
            <span className="top-sales-listing--item-title">Serial</span>
          </div>
          <div className="col top-sales-listing--item col-md-1">
            <span className="top-sales-listing--item-title">TX</span>
            <IconInfo text="TX: Transaction. More details about this collectible transaction." />
          </div>
          <div className="col top-sales-listing--item col-md-1">
            <span className="top-sales-listing--item-title">Date</span>
          </div>
        </div>
        { finalData?.map(item => (
          <CollectibleRow key={ item.id } { ...item } />
        )) }
      </PageContainer>

      <div className="marketplace-bottom-filters">
        <PageContainer>
          <Pagination
            meta={ meta }
            defaultOption={ 24 }
            options={ perPageOptionsMarketplace }
          />
        </PageContainer>
      </div>
    </>
  );
};

export default MarketplaceTopSales;
