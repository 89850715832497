import { useState } from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import useQueryParams from 'hooks/useQueryParams';

const FilterToggle = ({ eventKey }) => {
  const { queryParams: { filters } } = useQueryParams();
  const active = Object.keys(filters).filter(key => key !== 'listed').length;

  const [ open, setOpen ] = useState(false);

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setOpen(!open);
  });

  return (
    <button
      className={ classnames('filters-btn', {
        'filters-btn--opened': open,
      }) }
      key={ `filter-key-${eventKey}` }
      onClick={ decoratedOnClick }
      type="button"
    >
      Filter results
      { active > 0
        ? (
          <small>
            { `(${active})` }
          </small>
        )
        : '' }
      <span className="ico" />
    </button>
  );
};

FilterToggle.propTypes = {
  eventKey: string.isRequired,
};

export default FilterToggle;
