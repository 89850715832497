import { node, string } from 'prop-types';

import Button from 'components/Button/Button';

import useConfig from 'hooks/useConfig';
import useGTag from 'hooks/useGTag';

const ButtonViewTransaction = ({ children, transactionHash, ...props }) => {
  const { bscscan_tx_url: txUrl } = useConfig();
  const { track } = useGTag();

  return !!transactionHash && (
    <Button
      className="btn-primary"
      external
      onClick={ () => track('view_transaction', { transactionHash }) }
      size="m"
      theme="green-gradient"
      title="Transaction details"
      to={ `${txUrl}${transactionHash}` }
      { ...props }
    >
      { children }
    </Button>
  );
};

ButtonViewTransaction.propTypes = {
  children: node,
  transactionHash: string,
};

ButtonViewTransaction.defaultProps = {
  children: 'View Transaction',
  transactionHash: '',
};

export default ButtonViewTransaction;
