import ExpandableItems from 'components/Expandable/Items/Items';
import Metadata from 'components/Metadata';
import PageTitle from 'components/Page/Title';

import { faqs } from 'config';

import './style.scss';

const Faqs = () => (
  <>
    <Metadata
      description="The most frequently asked questions about RealFevr, $FEVR and it’s ecosystem."
      title="RealFevr - Frequent Asked Questions (FAQ)"
    />
    <PageTitle>FAQ</PageTitle>
    <p className="faqs--intro-desc">
      { `On this page you will find all the important information
        to our most frequently asked questions (FAQ).
        Before contacting our Support Team, please see if you can
        find the information you're looking for below.
        If you have any questions, contact our support by sending an email to info@realfevr.com` }
    </p>
    <section className="faqs--section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <ExpandableItems
              items={ faqs.slice(0, Math.ceil(faqs.length / 2)) }
              type="faq"
            />
          </div>
          <div className="col-md-6">
            <ExpandableItems
              items={ faqs.slice(Math.ceil(faqs.length / 2)) }
              type="faq"
            />
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Faqs;
