import { useState } from 'react';

import Button from 'components/Button/Button';
import Image from 'components/Image';
import Modal from 'components/Modal/Modal';
import Price from 'components/Price';

import useModal from 'hooks/useModal';

const ModalSellCollectible = () => {
  const confirm = useModal('sell-collectible');
  const follow = useModal('follow-metamask');

  const [ value, setValue ] = useState();
  const [ confirmed, setConfirmed ] = useState(false);

  const { onConfirm, image, title } = confirm.show || {};

  if (confirmed) {
    return (
      <Modal name="sell-collectible">
        <div className="popup--title">Confirm Sale</div>
        <div className="popup--desc">
          Are you sure you want to put this collectible for sale?
          <ul className="fevr-price-exchange-display">
            <li className="fevr-price-indication">
              <Price bnb={ value } output="bnb" />
            </li>
            <li className="exchange-rate">
              <Price bnb={ value } output="dollar" />
            </li>
          </ul>
          <Image
            alt={ title }
            className="collectible--img collectible--img-smaller"
            src={ image }
          />
        </div>

        <ul className="popup--actions">
          <li>
            <Button
              className="btn-primary"
              onClick={ async () => {
                confirm.close();
                setConfirmed(false);
                setValue();

                follow.open({ title: 'Confirm Sale' });
                await onConfirm(value);
                follow.close();
              } }
              size="m"
              theme="green-gradient"
            >
              Yes
            </Button>
          </li>
          <li>
            <Button
              className="btn-secondary"
              onClick={ () => setConfirmed(false) }
              size="m"
            >
              No
            </Button>
          </li>
        </ul>
      </Modal>
    );
  }

  return (
    <Modal name="sell-collectible">
      <div className="popup--title">Set Sale Price</div>
      <div className="popup--desc">Please, set your sale price for this collectible.</div>

      <div className="form-element-container input-txt--fevr">
        <input
          className="input-txt"
          onChange={ e => {
            const price = e.target.value
              .replace(/,$/, '.')
              .replace(/^\./, '0.');

            if (price.endsWith('.') && price.indexOf('.') !== price.length - 1) {
              return;
            }

            if (!price || /\.([0]{0,1})$/.test(price)) {
              setValue(price);
              return;
            }

            if (Number.isNaN(price) || Number.isNaN(parseFloat(price))) {
              return;
            }

            if (price.includes('-')) {
              return;
            }

            if (price.endsWith('.00')) {
              return;
            }

            if (String(price).startsWith(value) && /\.([0-9]{2})$/.test(value)) {
              return;
            }

            const newValue = Math.min(Math.round(parseFloat(price) * (10 ** 2)) / (10 ** 2), 9999999.99);
            setValue(newValue);
          } }
          placeholder="Set price"
          type="text"
          value={ value }
        />
        <small className="exchange-rate">
          <Price bnb={ value } output="dollar" />
        </small>
      </div>

      <ul className="popup--actions">
        <li>
          <Button
            className="btn-primary"
            onClick={ () => {
              if (value) {
                setConfirmed(true);
              }
            } }
            size="m"
            theme="green-gradient"
          >
            Confirm
          </Button>
        </li>
        <li>
          <Button
            className="btn-secondary"
            onClick={ confirm.close }
            size="m"
          >
            Cancel
          </Button>
        </li>
      </ul>
    </Modal>
  );
};

export default ModalSellCollectible;
