import { shape, string } from 'prop-types';
import { Redirect } from 'react-router-dom';
import parser from 'html-react-parser';

import Button from 'components/Button/Button';
import Image from 'components/Image';
import Metadata from 'components/Metadata';
import PackDetails from 'components/Pack/Details/Details';
import PageContainer from 'components/Page/Container';
import PageTitle from 'components/Page/Title';
import ReturnLink from 'components/ReturnLink/ReturnLink';
import TitleLines from 'components/TitleLines/TitleLines';

import useConfig from 'hooks/useConfig';

import hyphencase from 'utils/hyphencase';

import './[type].scss';

const PacksSingle = ({ match }) => {
  const { packType } = match?.params || {};
  const { packs_info: packsInfo } = useConfig();

  const pack = packsInfo?.packs.find(el => hyphencase(el.pack_type) === packType);
  if (packsInfo && !pack) {
    return <Redirect to="/packs" />;
  }

  return !!pack && (
    <>
      <Metadata
        description="All collectibles come in packs: “Basic”, “Rare” and “Super Rare”."
        title="RealFevr - Packs"
      />
      <PageContainer>
        <PageTitle>Pack details</PageTitle>
      </PageContainer>

      <PackDetails { ...pack } />

      { !!pack.top_title && (
        <PageContainer className="container-inside-pack">
          <div className="row">
            <div className="col">
              <TitleLines size="smaller">
                { pack.top_title }
              </TitleLines>
              { parser(pack.top_html.replace(/<p>/g, '<p class="pack-contents--desc">')) }
            </div>
          </div>
        </PageContainer>
      ) }

      { !!pack.middle_title && (
        <PageContainer className="container-inside-pack">
          <div className="row">
            <div className="col">
              <TitleLines size="smaller">
                { pack.middle_title }
              </TitleLines>
              { parser(pack.middle_html.replace(/<p>/g, '<p class="pack-contents--desc">')) }
            </div>
          </div>
        </PageContainer>
      ) }

      { !!pack.bottom_title && (
        <PageContainer className="container-inside-pack">
          <div className="row">
            <div className="col">
              <TitleLines size="smaller">
                { pack.bottom_title }
              </TitleLines>
              { parser(pack.bottom_html.replace(/<p>/g, '<p class="pack-contents--desc">')) }
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="pack-contents--diagram">
                <Image
                  alt="Pack Contents"
                  src={ pack.bottom_rarity_image }
                />
              </div>

              <div className="pack-contents--more-details">
                <p>Looking for more detailed data?</p>
                <Button
                  className="btn btn--size-xs btn--bg-white-gradient"
                  external
                  size="xs"
                  theme="white-gradient"
                  to={ packsInfo?.distribution_sheet }
                >
                  Probabilities sheet
                </Button>
              </div>
            </div>
          </div>
        </PageContainer>
      ) }

      <ReturnLink
        label="‹ Return to packs"
        to="/packs"
      />
    </>
  );
};

PacksSingle.propTypes = {
  match: shape({
    params: shape({
      packType: string,
    }),
  }),
};

PacksSingle.defaultProps = {
  match: null,
};

export default PacksSingle;
