import { useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import Image from 'components/Image';
import Metadata from 'components/Metadata';
import PageContainer from 'components/Page/Container';
import PageTitle from 'components/Page/Title';
import TitleLines from 'components/TitleLines/TitleLines';

import capitalize from 'utils/capitalize';

import hero1 from './assets/marketplace-info-hero-1.jpg';
import hero2 from './assets/marketplace-info-hero-2.jpg';
import hero3 from './assets/marketplace-info-hero-3.jpg';

import story1 from './assets/stories-icon-1.svg';
import story2 from './assets/stories-icon-2.svg';
import story3 from './assets/stories-icon-3.svg';

import imgCommon from './assets/collectible/collectible-final-common.jpg';
import imgEpic from './assets/collectible/collectible-final-epic.jpg';
import imgLegendary from './assets/collectible/collectible-final-legendary.jpg';
import imgSpecial from './assets/collectible/collectible-final-special.jpg';
import imgUnique from './assets/collectible/collectible-final-unique-bf.jpg';

import btnCommon from './assets/button/rarity-btn-common.svg';
import btnEpic from './assets/button/rarity-btn-epic.svg';
import btnLegendary from './assets/button/rarity-btn-legendary.svg';
import btnSpecial from './assets/button/rarity-btn-special.svg';
import btnUnique from './assets/button/rarity-btn-unique.svg';

import ctaPacks from '../home/assets/cta-packs.jpg';
import ctaCollection from '../home/assets/cta-collection.jpg';

import './style.scss';

const rarities = {
  common: {
    btn: btnCommon,
    img: imgCommon,
  },
  epic: {
    btn: btnEpic,
    img: imgEpic,
  },
  legendary: {
    btn: btnLegendary,
    img: imgLegendary,
  },
  special: {
    btn: btnSpecial,
    img: imgSpecial,
  },
  unique: {
    btn: btnUnique,
    img: imgUnique,
  },
};

const WITRM = () => {
  const [ rarity, setRarity ] = useState('common');

  return (
    <>
      <Metadata
        description="Learn how to buy and sell the most iconic football moments in history."
        title="RealFevr - What Is The RealFevr Marketplace?"
      />
      <PageTitle>
        What is the
        <br />
        { ' ' }
        RealFevr Marketplace?
      </PageTitle>
      <section className="witrm--section witrm--section-1">
        <PageContainer>
          <div className="row">
            <div className="col-md-8">
              <div className="witrm--content">
                <h2 className="witrm--title">
                  <span className="green">Buy and sell</span>
                  <br />
                  the most iconic
                  <br />
                  and
                  { ' ' }
                  <span className="purple">everlasting football moments</span>
                  <br />
                  in history
                </h2>
                <div className="witrm--title-desc">
                  <p>
                    You will be able to trade your favourite football video NFTs.
                  </p>
                  <p>
                    <b>
                      Be part of the next digital revolution by owning
                      a collection of unique digital collectibles.
                    </b>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <Image
                alt="RealFevr"
                className="witrm--main-img"
                src={ hero1 }
              />
            </div>
          </div>
        </PageContainer>
      </section>
      <section className="witrm--section witrm--section-2">
        <PageContainer>
          <div className="row">
            <div className="col-md-6">
              <Image
                alt="RealFevr"
                className="witrm--main-img"
                src={ hero2 }
              />
            </div>
            <div className="col-md-6">
              <div className="witrm--content">
                <h2 className="witrm--title">
                  The Dawn of
                  { ' ' }
                  <span className="green">Football Digital Collectibles</span>
                </h2>
                <div className="witrm--title-desc">
                  <p>
                    RealFevr brought to life what all fans have hoped for:
                    to possess the most pivotal football moments.
                  </p>
                  <p>
                    Have you ever imagined owning the video collectible of Ronaldo’s first official
                    goal? Or Casillas’ last save? What about every single moment you could possibly
                    imagine that defined the history of football?
                  </p>
                  <p>
                    RealFevr’s Marketplace is your gateway to our football digital
                    video collectibles. Proudly showcase your moments and flex those
                    rare moments to other fans. They belong to you!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </PageContainer>
      </section>
      <section className="witrm--section witrm--section-3">
        <PageContainer>
          <div className="row">
            <div className="col-md-6">
              <div className="witrm--content">
                <h2 className="witrm--title">
                  Ready. Set.
                  { ' ' }
                  <span className="green">Go!</span>
                </h2>
                <div className="witrm--title-desc">
                  <p>
                    All collectibles come in packs:
                    { ' ' }
                    <strong style={ { color: 'rgb(73, 185, 129)' } }>BASIC</strong>
                    ,
                    { ' ' }
                    <strong style={ { color: 'rgb(137, 180, 237)' } }>RARE</strong>
                    , and
                    { ' ' }
                    <strong style={ { color: 'rgb(225, 61, 140)' } }>SUPER RARE</strong>
                    .
                  </p>
                  <p>
                    After opening a pack, the moments that collectors want to trade are
                    posted in the Marketplace.
                  </p>
                  <p>
                    Within the Marketplace, fans can select a range of filters to quickly
                    find the specific moment they are looking for, like players’ name,
                    season, club, position, rarity, among many others.
                  </p>
                  <p>
                    Become a top collector as soon as you will be able to borrow and lend them.
                    Have fun and collect as many football moments as possible!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <Image
                alt="RealFevr"
                className="witrm--main-img"
                src={ hero3 }
              />
            </div>
          </div>
        </PageContainer>
      </section>
      <section className="witrm--section witrm--section-4">
        <PageContainer>
          <div className="row">
            <div className="col-md-6">
              <img
                alt="RealFevr"
                className="witrm--main-img witrm--rarity-main-img"
                src={ rarities[rarity].img }
              />
            </div>
            <div className="col-md-6">
              <div className="witrm--content">
                <h2 className="witrm--title">
                  <span className="green">Rarity</span>
                  { ' ' }
                  Is Key
                </h2>
                <div className="witrm--title-desc">
                  <p>
                    As we explain
                    { ' ' }
                    <Link to="/packs">here</Link>
                    , the rarer the pack,
                    the higher the chances you will be gifted with an extremely scarce moment.
                  </p>
                  <p>
                    Our football video NFTs rarity ranges from
                    { ' ' }
                    <strong style={ { color: '#3fb778' } }>COMMON</strong>
                    { ', ' }
                    <strong style={ { color: '#21A0FB' } }>SPECIAL</strong>
                    { ', ' }
                    <strong style={ { color: '#CB42F8' } }>EPIC</strong>
                    { ', ' }
                    <strong style={ { color: '#FAC75E' } }>LEGENDARY</strong>
                    { ' to ' }
                    <strong style={ { color: '#DC3C2D' } }>UNIQUE</strong>
                    { ' ' }
                    and depending on the moment, it may appear in one
                    of the tiers. Extremely rare moments, like Ronaldo’s first official goal,
                    will always be Unique, meaning that only one copy of the digital collectible
                    will ever exist (1 of 1).
                  </p>
                  <ul className="witrm--rarity-nav">
                    { [ 'common', 'special', 'epic', 'legendary', 'unique' ].map(rar => (
                      <li
                        key={ rar }
                        className={ classnames('witrm--rarity-item', {
                          'witrm--rarity-item-active': rarity === rar,
                        }) }
                      >
                        <button
                          onClick={ () => setRarity(rar) }
                          title={ capitalize(rar) }
                          type="button"
                        >
                          <div className="witrm--rarity-img">
                            <Image
                              alt={ capitalize(rar) }
                              src={ rarities[rar].btn }
                            />
                          </div>
                          <small>{ capitalize(rar) }</small>
                        </button>
                      </li>
                    )) }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </PageContainer>
      </section>
      <section className="witrm--section witrm--section-5">
        <PageContainer>
          <div className="row">
            <div className="col col-title col-align-center">
              <h2 className="witrm--title">
                Moments Contain
                <br />
                { ' ' }
                <span className="green">Stories You Own</span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 witrm-icon-item">
              <Image
                alt="Video Collectibles"
                className="witrm-icon-img"
                src={ story1 }
              />
              <h3 className="mid-title green">
                Video
                <br />
                Collectibles
              </h3>
              <p>
                Each football moment contains a unique ID that
                can easily be verified on a public blockchain.
              </p>
            </div>
            <div className="col-md-4 witrm-icon-item">
              <Image
                alt="Player Details"
                className="witrm-icon-img"
                src={ story2 }
              />
              <h3 className="mid-title green">
                Player
                <br />
                Details
              </h3>
              <p>
                Learn why each moment is so treasured by diving into
                the backstory behind each artist - the footballer.
              </p>
            </div>
            <div className="col-md-4 witrm-icon-item">
              <Image
                alt="First pack revenue share"
                className="witrm-icon-img"
                src={ story3 }
              />
              <h3 className="mid-title green">
                Fantasy
                <br />
                Leagues
              </h3>
              <p>
                Not only do these digital collectibles grant you unique football moments,
                but they will also award fans with in-game rewards.
              </p>
            </div>
          </div>
        </PageContainer>
      </section>
      <section className="section--marketplace-cta">
        <PageContainer>
          <div className="row">
            <div className="col col-align-center">
              <TitleLines>Ready to start?</TitleLines>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 marketplace-cta--col marketplace-cta--col-packs">
              <Link to="/packs" className="marketplace-cta--item">
                <div className="marketplace-cta--item-img">
                  <Image
                    alt="Open Packs"
                    src={ ctaPacks }
                  />
                </div>
                <TitleLines>
                  <small>Buy</small>
                  { ' ' }
                  Packs
                </TitleLines>
              </Link>
            </div>
            <div className="col-md-6 marketplace-cta--col marketplace-cta--col-packs">
              <Link to="/marketplace" className="marketplace-cta--item">
                <div className="marketplace-cta--item-img">
                  <Image
                    alt="Start Your Collection"
                    src={ ctaCollection }
                  />
                </div>
                <TitleLines as="h4">
                  <small>Start Your</small>
                  { ' ' }
                  Collection
                </TitleLines>
              </Link>
            </div>
          </div>
        </PageContainer>
      </section>
    </>
  );
};

export default WITRM;
