import { useState } from 'react';
import { string } from 'prop-types';
import Select from 'react-select';
import classnames from 'classnames';

const FilterSelect = ({ className, ...props }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <Select
      className={ classnames('selectbox', className, {
        'selectbox--is-open': isOpen,
      }) }
      classNamePrefix="react-select"
      isSearchable={ false }
      onMenuClose={ () => setIsOpen(false) }
      onMenuOpen={ () => setIsOpen(true) }
      { ...props }
    />
  );
};

FilterSelect.propTypes = {
  className: string,
};

FilterSelect.defaultProps = {
  className: '',
};

export default FilterSelect;
