import Button from 'components/Button/Button';
import Image from 'components/Image';

import useConfig from 'hooks/useConfig';
import useGTag from 'hooks/useGTag';

import football from './assets/ico-football.svg';

const ButtonFantasy = () => {
  const { fantasy_url: fantasyLink } = useConfig();
  const { conversion } = useGTag();

  return (
    <Button
      className="btn--shadow btn-fantasy"
      external
      onClick={ () => conversion('play_fantasy') }
      size="xs"
      theme="blue-white-gradient"
      to={ fantasyLink }
    >
      Play Fantasy
      <Image
        alt="RealFevr"
        src={ football }
      />
    </Button>
  );
};

export default ButtonFantasy;
