import { func, string } from 'prop-types';
import { Link } from 'react-router-dom';

import Button from 'components/Button/Button';
import PageContainer from 'components/Page/Container';

import './ReturnLink.scss';

const ReturnLink = ({ label, onClick, to }) => (
  <PageContainer className="return-link-container">
    <div className="row">
      <div className="col">
        { to
          ? (
            <Link
              className="return-link"
              onClick={ onClick }
              to={ to }
            >
              { label }
            </Link>
          )
          : (
            <Button
              className="return-link"
              onClick={ onClick }
            >
              { label }
            </Button>
          ) }
      </div>
    </div>
  </PageContainer>
);

ReturnLink.propTypes = {
  label: string.isRequired,
  onClick: func,
  to: string,
};

ReturnLink.defaultProps = {
  onClick: null,
  to: '',
};

export default ReturnLink;
