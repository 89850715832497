import parser from 'html-react-parser';

import Countdown from 'components/Countdown/Countdown';
import Image from 'components/Image';
import Metadata from 'components/Metadata';
import PageContainer from 'components/Page/Container';
import PageTitle from 'components/Page/Title';
import PackListItem from 'components/Pack/ListItem/ListItem';
import TitleLines from 'components/TitleLines/TitleLines';

import useConfig from 'hooks/useConfig';

import './style.scss';

const Packs = () => {
  const { packs_info: packsInfo } = useConfig();

  return (
    <>
      <Metadata
        description="All collectibles come in packs: “Basic”, “Rare” and “Super Rare”."
        title="RealFevr - Packs"
      />
      <PageTitle>Packs</PageTitle>

      <section className="packs--hero">
        <PageContainer>
          <div className="row">
            <div className="col">
              <div className="packs-hero">
                <div className="packs-hero--title">
                  <div className="packs-hero--title-lvl1">Introducing</div>
                  <div className="packs-hero--title-lvl2">
                    <span className="green">{ packsInfo?.season }</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageContainer>
      </section>

      <PageContainer>
        <div className="row">
          <div className="col">
            <Countdown className="rc-center" />
          </div>
        </div>
      </PageContainer>

      <PageContainer>
        <div className="row">
          <div className="col">
            <TitleLines>{ packsInfo?.drop }</TitleLines>
          </div>
        </div>

        <div className="row row--align-items-center">
          { packsInfo?.packs?.map(item => (
            <div className="col col-md-3" key={ `item-${item.pack_type}` }>
              <PackListItem { ...item } />
            </div>
          )) }
        </div>
      </PageContainer>

      <section className="packs--desc">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>{ parser(packsInfo?.middle_title || '') }</h2>
              <br />
              { parser(packsInfo?.middle_content || '') }
            </div>
            <div className="col-md-6">
              <Image
                alt="RealFevr"
                className="packs--img-desc-illustration"
                src={ packsInfo?.middle_image }
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Packs;
