import { Link, useLocation } from 'react-router-dom';

import { partners } from 'config';

import Image from 'components/Image';
import Logo from 'components/Logo/Logo';
import NavigationSocialMedia from 'components/Navigation/SocialMedia';
import PageContainer from 'components/Page/Container';

import binance from './assets/binance-logo.svg';
import './Footer.scss';

const Footer = () => {
  const { pathname } = useLocation();

  // Partners are only shown in certain pages, rather than all of them, as it's a large section.
  const showPartners = pathname === '/' || pathname === '/about-us';

  return (
    <footer id="main-footer">
      <PageContainer>
        <div className="row">
          <div className="col">
            { showPartners && (
              <div id="official-partners">
                <h5 className="bottom-area-title">Official partners</h5>
                <PageContainer>
                  <div className="row">
                    <div className="col">
                      <PageContainer className="official-partners-list">
                        <div className="row row--align-items-center">
                          { partners && partners.map(p => (
                            <div
                              key={ p.logo }
                              className="col-partner col-md-3"
                            >
                              { p.link
                                ? (
                                  <a href={ p.link } target="_blank" rel="noreferrer">
                                    <Image
                                      alt={ p.label }
                                      height={ 72 }
                                      src={ p.logo }
                                    />
                                  </a>
                                )
                                : (
                                  <Image
                                    alt={ p.label }
                                    height={ 72 }
                                    src={ p.logo }
                                  />
                                ) }
                            </div>
                          )) }
                        </div>
                      </PageContainer>
                    </div>
                  </div>
                </PageContainer>
              </div>
            ) }
            <div id="powered-by-binance">
              <Image
                alt="Binance Smart Chain"
                className="binance-logo"
                height={ 70 }
                src={ binance }
              />
              RealFevr is powered by Binance Smart Chain
            </div>
          </div>
        </div>
      </PageContainer>
      <div className="footer-footnote">
        <PageContainer>
          <div className="row">
            <div className="col footer-footnote--col-1">
              <NavigationSocialMedia />
            </div>
            <div className="col footer-footnote--col-2">
              <Logo
                className="footer-logo"
                type="white"
              />
            </div>
            <div className="col footer-footnote--col-3">
              <nav className="footer-footnote--nav">
                <ul>
                  <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                  <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                  <li><Link to="/contacts">Contact</Link></li>
                </ul>
              </nav>
              <div className="footer-copyright">{ `© ${new Date().getFullYear()} RealFevr · All Rights Reserved` }</div>
            </div>
          </div>
        </PageContainer>
      </div>
    </footer>
  );
};

export default Footer;
