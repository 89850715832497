import { useRef } from 'react';
import { load } from 'recaptcha-v3';

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

const containerRef = {};

const useReCaptcha = () => {
  const grecaptchaRef = useRef();

  return {
    reCaptchaKey,

    execute: async action => {
      const grecaptcha = await grecaptchaRef.current;
      const token = await grecaptcha?.execute(action);

      return token;
    },

    ref: container => {
      if (!reCaptchaKey) {
        return;
      }

      if (container) {
        grecaptchaRef.current = load(reCaptchaKey, {
          explicitRenderParameters: {
            badge: 'inline',
            container,
            size: 'invisible',
          },
        });

        if (containerRef.current) {
          if (containerRef.current !== container) {
            [ ...containerRef.current.children ].forEach(child => {
              container.appendChild(child);
            });
          }
        }

        containerRef.current = container;
      }
    },
  };
};

export default useReCaptcha;
