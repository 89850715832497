import {
  bool, node, oneOf, string,
} from 'prop-types';
import classnames from 'classnames';

import './TitleLines.scss';

const TitleLines = ({
  as: Comp,
  children,
  className,
  color,
  comingSoon,
  size,
}) => (
  <Comp
    className={ classnames('title-lines', className, {
      [`title-lines-${color}`]: color,
      [`title-lines-${size}`]: size,
      'title-lines-x2': comingSoon,
    }) }
  >
    { children }
    { comingSoon && <span className="purple title-coming-soon">Coming Soon</span> }
  </Comp>
);

TitleLines.propTypes = {
  as: string,
  children: node,
  className: string,
  color: oneOf([ '', 'purple' ]),
  comingSoon: bool,
  size: oneOf([ '', 'smaller' ]),
};

TitleLines.defaultProps = {
  as: 'h3',
  children: null,
  className: '',
  color: '',
  comingSoon: false,
  size: '',
};

export default TitleLines;
