import Button from 'components/Button/Button';
import PageContainer from 'components/Page/Container';
import TitleLines from 'components/TitleLines/TitleLines';

import useGTag from 'hooks/useGTag';

import communityTelegramIcon from './assets/community-telegram-icon.png';

import './Telegram.scss';

const Telegram = () => {
  const { conversion } = useGTag();

  return (
    <section className="section--community">
      <PageContainer>
        <div className="row">
          <div className="col col-align-center">
            <img
              alt="Community"
              className="community--icon"
              src={ communityTelegramIcon }
            />
            <TitleLines size="smaller">Join our Community</TitleLines>
            <p className="intro-p">Follow our telegram channels to connect with our passionate community and know about the latest news</p>
            <Button
              className="btn--shadow"
              external
              onClick={ () => conversion('social_telegram') }
              theme="green-gradient"
              to="https://t.me/realfevrofficial"
            >
              Follow our Telegram
            </Button>
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default Telegram;
